import React, { useEffect, useRef } from 'react'

// Assets
import CloseIcon from '../../assets/addNewTheme/close-icon.svg'

// Components
import MessageInput from './ChatMessageInput'
import { Box } from '@mui/material'
import ChatBallon from './ChatBallon'

// Style
import styles from './styles/chatBubble.module.scss'
import { useTheme, styled } from '@mui/material/styles'

// Redux
import { useAppSelector } from '../../store/hooks'
import { IChat } from '../../store/activity/types'
import { scrollToBottom } from '../../utils/functions'

const Image = styled('img')(() => ({


}))
const Button = styled('button')(() => ({
  position: 'absolute',
  top: '24px',
  right: '16px',
  cursor: 'pointer'
}))

type IChatBubbleProps = {
  handleClose: () => void
}

const ChatBubble: React.FC<IChatBubbleProps> = ({
  handleClose
}) => {

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const theme = useTheme()
  const { chat } = useAppSelector(state => state.activity.item)

  const chatContainerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    scrollToBottom(chatContainerRef)
  }, [chat])

  return (
    <div className={styles.container}>
      <Button onClick={handleClose}>
        <Image src={CloseIcon} />
      </Button>
      <Box ref={chatContainerRef} className={styles.chat_balloons_container}>
        {
          chat.map((message: IChat) => {
            return <ChatBallon key={message.id} isSending={message.role === 'student'} date={message.date} message={message.comment} />
          })
        }
      </Box>
      <MessageInput />
    </div>
  )
}

export default ChatBubble