import React, { useState } from 'react'

// Components
import Draggable from 'react-draggable'
import { Box, Menu, Typography } from '@mui/material'
import ChatIcon from '../../assets/activities/chat-icon.svg'
import ChatBubble from './ChatBubble'

// Style
import { useTheme, styled } from '@mui/material/styles'

const Button = styled('button')(() => ({
  fontWeight: 500,
  fontSize: '18px',
  color: '#fff',
  '> span': {
    color: '#45CFEF'
  }
}))

const ChatContainer = () => {
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>

      <Draggable
        bounds='parent'
      // onStart={eventHandler}
      >

        <Button
          id='chat-button'
          aria-controls={open ? 'chat-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          sx={{
            alignItems: 'center',
            gap: '4px',
            display: 'flex',
            width: 'fit-content',
            cursor: 'grab',
            padding: '16px 32px',
            background: theme.palette.primary.light,
            borderRadius: '56px',
            position: 'absolute',
            zIndex: 999,
            right: 24,
            bottom: '120vh',
            '&:hover': {
              background: theme.palette.primary.main
            }
          }}
        >
          <img src={ChatIcon} />
          <Typography
            sx={{
              fontSize: '18px',
              fontWeight: 500,
              '> span': {
                color: '#45CFEF'
              }
            }}
          >
            Chat
          </Typography>
        </Button>
      </Draggable>
      <Box>

        <Menu
          id='chat-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'chat-button',
          }}
          sx={{
            '.MuiPaper-root': {
                overflowY: 'hidden',
                borderRadius: '20px'
              },
              '.MuiList-root': {
                paddingBlock: 0
              },
            [theme.breakpoints.up(905)]: {
              '.MuiPaper-root': {
                background: 'transparent'
              },
              '.MuiMenu-list': {
                paddingBlock: 0
              }
            }
          }}
          transformOrigin={{
            vertical: 'top', 
            horizontal: 'right',
          }}
        >
          <ChatBubble handleClose={handleClose} />
        </Menu>
      </Box>
    </>
  )
}

export default ChatContainer