import React, { useState } from 'react'

// Components
import { Field } from 'react-final-form'
import { useDispatch } from 'react-redux'
import { Box, Typography, styled } from '@mui/material'
import CustomSearchInput from '../../components/input/CustomSearchInput'
import Notification from '../../components/notification/Notification'
import LogoutButton from '../../components/button/logout/LogoutButton'
import PersonalDataDropDownMenu from '../../components/dropDown/PersonalDataDropDownMenu'
import { PageSubTitle } from '../../components/title/PageSubTitle'
import SelectInput from '../../components/input/SelectInput'
import { RoundedButton } from '../../components/button/RoundedButton'
import NavigationTitleWithBackButton from '../../components/navigation/NavigationTitleWithBackButton'

// Style
import { useTheme } from '@mui/material/styles'
import styles from './styles/addNewThemeDesktop.module.scss'
import ImageUpload from '../imageUpload/ImageUpload'

type IEssayThemesForm = {
  setCurrentTab: any
  values: any
}

const ThemeTitleInput = styled('input')(({ theme }) => ({
  borderRadius: '12px',
  maxWidth: '743px',
  padding: '25px 15px',
  border: '1px solid #BEBEBE',
  outline: 'none',
  '::placeholder': {
    color: theme.palette.text.secondary,
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '120%'
  },
  ':focus': {
    border: 'none',
    outline: `2px solid ${theme.palette.primary.light}`
  }
}))

const EssayThemesForm: React.FC<IEssayThemesForm> = ({
  values,
  setCurrentTab
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const dispatch = useDispatch()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const theme = useTheme()
  const [searchName, setSearchName] = useState<string>('')

  const options = [
    { label: 'ENEM', value: 'ENEM' },
    { label: 'Concurso', value: 'concurso' },
    { label: 'ITA', value: 'ita' },
  ]

  const bancaOptions = [
    { label: 'Selecione...', value: 'placeholder' },
    ...options,
  ]

  const isDisabled = !values?.title || !values?.selectSubject || !values.themeImage

  return (
    <Box className={styles.container}>
      <div className={styles.top_header}>
        <CustomSearchInput
          name='search'
          value={searchName}
          onChange={(e: any) => setSearchName(e.target.value)}
        />
        <div className={styles.notification_container}>
          <Box display='flex' gap='8px'>
            <Notification
              background='#F2F5FF'
            />
            <LogoutButton background='#F2F5FF' />
          </Box>
          <PersonalDataDropDownMenu background='#F2F5FF' />
        </div>
      </div>
      <NavigationTitleWithBackButton
        backTo='/redacao-guiada'
        text='Minhas redações / Adicionar / Novo tema'
      />
      <Box>
        <PageSubTitle
          sx={{
            color: 'var(--palette-text-quaternary)',
            mb: '40px',
          }}
        >
          Novo tema
        </PageSubTitle>
      </Box>
      <div className={styles.input_container}>
        <Field
          name='title'
          render={({ input }) => (
            <ThemeTitleInput {...input} placeholder='Insira um título' />
          )}
        />
        <p className={styles.characters_limit}>
          {values.title ? values.title.length : 0}/250
        </p>
      </div>
      <div className={styles.banca_select}>
        <Typography className={styles.banca}>Escolha a banca avaliadora:</Typography>
        <Field name='selectSubject' component='select'>
          {({ input }) => (
            <SelectInput
              width='fit-content'
              border='none'
              {...input}
              defaultValue='placeholder'
              options={bancaOptions}
            />
          )}
        </Field>
      </div>
      <Field name='themeImage' component='input'>
        {({ input }) => (
          <ImageUpload
            maxWidth='743px'
            imageData={values?.themeImage}
            setImage={(file: any) => {
              input.onChange(file)
            }}
            type='file'
            {...input}
          />
        )}
      </Field>
      <div className={styles.buttons_container}>
        <RoundedButton
          onClick={() => setCurrentTab('2')}
          disabled={isDisabled}
        >
          Continuar
        </RoundedButton>
      </div>
    </Box>
  )
}

export default EssayThemesForm