import { Box, Grid, Typography } from '@mui/material'
import React from 'react'

// Styles 
import LoginPageStyle from '../../containers/nonLogged/styles/LoginPageStyleDesktop.module.scss'

type INonLoggedDesktopContainerProps = {
  url: string
  LogoFull?: string
}
const NonLoggedDesktopContainer: React.FC<INonLoggedDesktopContainerProps> = ({
  LogoFull,
  url
}) => {
  return (
    <div>
      <Grid sx={{ height: '100vh' }} container>
        <Grid item xs={12} md={6} lg={6}>
          <div className={LoginPageStyle.handWriting} />
        </Grid>
        <Grid sx={{ height: '100%' }} item xs={12} md={6} lg={6}>
          <Box
            className={LoginPageStyle.container}
          >
            <img className={LoginPageStyle.logo} src={LogoFull} alt='Logo' />
            <Typography className={LoginPageStyle.title}>Bem-vindo ao Verbo</Typography>
            <Typography
              className={LoginPageStyle.text}
              sx={{
                maxWidth: '445px'
              }}
            >
              Uma abordagem mais completa na correção de redações com análises detalhadas e relatórios estratégicos que permitem a criação de planos de ação personalizados. É correção orientada para garantir o desenvolvimento contínuo da escrita.
            </Typography>
            <a className={LoginPageStyle.loginRedirectLink} href={url}>Começar agora</a>
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}

export default NonLoggedDesktopContainer