import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

// Assets
import SuccessPanelImage from '../../assets/successPanel/panel-success-desktop.png'
import BlackMedal from '../../assets/successPanel/black-medal-icon.svg'

// Components
import CustomSearchInput from '../../components/input/CustomSearchInput'
import { Box, Typography } from '@mui/material'
import LogoutButton from '../../components/button/logout/LogoutButton'
import PersonalDataDropDownMenu from '../../components/dropDown/PersonalDataDropDownMenu'
import Notification from '../../components/notification/Notification'
import { DatePickerField } from '../../components/input/DatePickerInput'
import { PageMainTitle } from '../../components/title/PageMainTitle'
import { PageSubTitle } from '../../components/title/PageSubTitle'
import SelectInput from '../../components/input/SelectInput'

// Styles
import styles from './styles/sucessPanel.module.scss'
import { useTheme } from '@mui/material/styles'

// Utils
import { format, formatDistance } from 'date-fns'
import SuccessPanelTable from '../../components/table/successPanel/SuccessPanelTable'

const SuccessPanelDesktopContainer = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const [searchName, setSearchName] = useState<string>('') 
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [page, setPage] = useState(1)

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setSearchName(value)
  }

  const options = [
    { label: 'ENEM', value: 'ENEM' },
    { label: 'Concurso', value: 'concurso' },
    { label: 'ITA', value: 'ita' }

  ]

  const bancaOptions = [
    { label: 'Selecione...', value: 'placeholder' },
    ...options
  ]

  const classRoomsOptions = [
    { label: 'Todas', value: 'all' },
    { label: '1º Ano B', value: '1 ano b' },
    { label: '2º Ano A', value: '1 ano a' }

  ]

  const turmaOptions = [
    { label: 'Selecione...', value: 'placeholder' },
    ...classRoomsOptions
  ]

  const resetLocation = () => {
    const newLocation = {
      ...location,
      state: null
    }
    navigate(newLocation)
  }

  const handleStartChanges = (startValue: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const startDateFormatted = startValue ? format(startValue, 'yyyy-MM-dd') : ''
    if (endDate && formatDistance(startValue, endDate)) {
      setEndDate(null)

    } else {
      return null
    }
    setPage(1)
    setStartDate(startValue)
    resetLocation()
  }

  const handleEndChanges = (endValue: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const endDateFormatted = endValue ? format(endValue, 'yyyy-MM-dd') : ''
    setEndDate(endValue)
    setPage(1)
    resetLocation()
  }

  return (
    <div className={styles.container}>
      <div
        className={styles.top_header}
      >
        <CustomSearchInput
          name='search'
          value={searchName}
          onChange={handleSearch}
        />
        <div
          className={styles.notification_container}
        >
          <Box display='flex' gap='8px'>
            <Notification
              background='#F2F5FF'
            />
            <LogoutButton
              background='#F2F5FF'
            />
          </Box>
          <PersonalDataDropDownMenu
            background='#F2F5FF'
          />
        </div>
      </div>
      <div className={styles.top_content}>
        <div className={styles.title_content}>
          <PageMainTitle>
            <img src={BlackMedal} />
            Painel do Sucesso
          </PageMainTitle>
          <p>
            Veja o ranking dos melhores alunos, acesse suas redações e use-as como referência para tirar nota 1000.
          </p>
        </div>
        <img src={SuccessPanelImage} alt='Mulher segurando redaçao com notal A' />
      </div>
      <div className={styles.filters_container}>
        <PageSubTitle>Alunos</PageSubTitle>
        <div className={styles.filters}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Typography>Banca:</Typography>
            <SelectInput
              width='fit-content'
              name='selectSubject'
              border='none'
              onChange={() => null}
              defaultValue='placeholder'
              options={bancaOptions}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Typography>Turma:</Typography>
            <SelectInput
              width='fit-content'
              name='selectTurmas'
              border='none'
              onChange={() => null}
              defaultValue='placeholder'
              options={turmaOptions}
            />
          </Box>
          <Box
            sx={{
              display: 'flex'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px'
              }}
            >
              <Box>
                <Typography
                  color={theme.palette.text.primary}
                  variant='subtitle2'
                  component='p'
                >
                  Iniciam entre:
                </Typography>
              </Box>
              <Box>
                <DatePickerField
                  name='start_date'
                  onChange={handleStartChanges}
                  startDate={startDate}
                  endDate={endDate}
                  placeholder='dd/mm/aaaa'
                  selectsStart
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex ',
                alignItems: 'center',
                gap: '8px',
                marginLeft: '8px'
              }}
            >
              <Box>
                <DatePickerField
                  name='end_date'
                  onChange={handleEndChanges}
                  startDate={endDate}
                  endDate={endDate}
                  minDate={startDate}
                  placeholder='dd/mm/aaaa'
                />
              </Box>
            </Box>
          </Box>
        </div>
      </div>
      <SuccessPanelTable />
    </div>
  )
}

export default SuccessPanelDesktopContainer