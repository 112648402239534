import React from 'react'
import DashboardTemplate from '../components/template/DashboardTemplate'

const NotFoundPage = () => {
    return (
        <DashboardTemplate>
            <h1>Not Found!</h1>
        </DashboardTemplate>
    )
}

export default NotFoundPage
