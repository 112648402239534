import React, { useEffect } from 'react'
import { useMediaQuery } from '@mui/system'
import { useTheme } from '@mui/material/styles'
import GradesMobileContainer from '../containers/grades/GradesMobileContainer'
import GradesDesktopContainer from '../containers/grades/GradesDesktopContainer'

const GradesPage = () => {
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  useEffect(() => {
    document.title = "Notas | Verbo"
  }, [])
  return (
    smDown ? <GradesMobileContainer /> : <GradesDesktopContainer />
  )
}

export default GradesPage