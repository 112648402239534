/* eslint-disable @typescript-eslint/no-unused-vars */
import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const PageTitle = styled('h1')(({ theme }) => ({
  alignItems: 'center',
  color: 'var(--palette-text-tertiary)',
  display: 'flex',
  fontFamily: 'Montserrat',
  fontSize: '28px',
  fontWeight: 700,
  gap: '10px',
  lineHeight: '120%',
  [theme.breakpoints.down(1240)]: {
    gap: '8px'
  }
}))