/* eslint-disable camelcase */
import { createReducer } from '@reduxjs/toolkit'
import { StateType } from './types'
import * as actions from './actions'

const initialState: StateType = {
  user: {
    id: null,
    username: '',
    first_name: '',
    last_name: '',
    email: ''
  },
  isLogged: false,
  isFetching: false,
  isError: false,
  errorMessage: ''
}

const authVerboReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.getVerboUserDataRequest, (state: StateType): StateType => ({
      ...state,
      isFetching: true,
      isError: false,
      isLogged: false,
      errorMessage: ''
    }))
    .addCase(actions.getVerboUserDataSuccess, (state: StateType, action: { payload: any }): StateType => {
      return ({
      ...state,
      isFetching: false,
      isLogged: true,
      isError: false,
      errorMessage: '',
      user: action.payload,
    })})
    .addCase(actions.getVerboUserDataRejected, (state: StateType): StateType => ({
      ...state,
      isFetching: false,
      isLogged: false,
      isError: true,
      errorMessage: ''
    }))

})

export default authVerboReducer
