import React from 'react'
import { Box, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
type IGradeWithLabelProps = {
  grade?: number | null
  gradeFontSize: string
  labelFontSize: string
  gradeFontWeight?: number
  labelFontWeight?: number
}

const GradeWithLabel: React.FC<IGradeWithLabelProps> = (
  { grade,
    gradeFontSize,
    labelFontSize,
    labelFontWeight,
    gradeFontWeight
  }
) => {

  const theme = useTheme()

  const getBackgroundColor = (score: number) => {
    let background
    let fontColor
    if (score >= 900) {
      background = '#7EEB89'
    } else if (score >= 700 && score < 900) {
      background = theme.palette.text.primary
      fontColor = '#fff'
    } else if (score >= 400 && score < 700) {
      background = '#F6BB8C'
    } else if (score < 400) {
      background = theme.palette.error.main
    }
    return { background, fontColor }
  }
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        gap: '8px'
      }}
    >
      <Typography
        sx={{
          fontSize: labelFontSize,
          fontWeight: labelFontWeight
        }}
      >
        Nota:
      </Typography>
      <Typography
        sx={{
          background: grade ? getBackgroundColor(grade).background : '#fff',
          color: grade ? getBackgroundColor(grade).fontColor : '#434343',
          fontSize: gradeFontSize,
          fontWeight: gradeFontWeight ?? 500,
          padding: '8px 16px',
          borderRadius: '40px'
        }}
        component='span'>
        {grade ?? '--'}
      </Typography>
    </Box>
  )
}

export default GradeWithLabel