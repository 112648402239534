import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

// Assets
import { ReactComponent as InfoIcon } from '../../../assets/activities/info-icon.svg'
import CalendarIcon from '../../../assets/activities/calendar-icon.svg'
import RedCalendarIcon from '../../../assets/activities/red-calendar-icon.svg'
import CloseCircledIcon from '../../../assets/home/card/close-circled-red-icon.svg'

// Components
import {
  Box,
  Divider,
  Menu,
  Pagination,
  styled,
  Typography,
  useMediaQuery
} from '@mui/material'
import CustomTable, { Columns } from '../customTable/CustomTable';
import TableLoading from '../../loading/TableLoading'

// Redux
import { IActivity } from '../../../store/activity/types'
import { useAppSelector } from '../../../store/hooks'
import { useDispatch } from 'react-redux'
import { getStudentActivityListRequest } from '../../../store/activity/actions'

// Style
import { useTheme } from '@mui/material/styles'
import styles from './activityTable.module.scss'

// Utils
import { scrollToTop, translateStatus } from '../../../utils/functions';
import { format } from 'date-fns'

type IGuidedWritingTableProps = {
  statusSelected?: string
  startDateFilter?: any
  page?: any
  setPage?: any
}

const TableTextCell = styled(Box)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontFamily: 'Montserrat',
  fontSize: '18px',
  fontWeight: 500,
  lineHeight: '120%',
  cursor: 'pointer'
}))
const Button = styled('button')(({ theme }) => ({
  cursor: 'pointer',
  '& svg': {
    '&:hover': {
      stroke: theme.palette.primary.main,
      'circle': {
        stroke: theme.palette.primary.main,
      }
    }
  }
}))

const ActivitiesTable: React.FC<IGuidedWritingTableProps> = (
  {
    statusSelected,
    startDateFilter,
    page,
    setPage
  }
) => {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const theme = useTheme()
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [clickedId, setClickedId] = useState<number | null>(null)
  const open = Boolean(anchorEl)
  const { items, isFetching } = useAppSelector(state => state.activity)

  const handleNavigate = (data: IActivity) => {
    const status = data?.answer.status
    if (status === 'visualized' || status === 'not_visualized') {
      navigate(`/minhas-atividades/visualizar/pendente/${data.id}`, { state: { data: data } })
    } else if (status === 'not_corrected') {
      navigate(`/minhas-atividades/visualizar/aguardando-correcao/${data.id}`, { state: { data: data } })
    } else if (status === 'corrected') {
      navigate(`/minhas-atividades/visualizar/concluida/${data.id}`, { state: { data: data } })
    } else if (status === 'in_correction') {
      navigate(`/minhas-atividades/visualizar/em-correcao/${data.id}`, { state: { data: data } })
    } else if (status === 'overdue_enabled') {
      navigate(`/minhas-atividades/visualizar/prazo-expirado/${data.id}`, { state: { data: data } })
    }
  }

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
    dispatch(getStudentActivityListRequest({
      per_page: 5,
      page: value,
      status: statusSelected,
      ...(startDateFilter ? { end_datetime_after: startDateFilter } : {}),
    }))
    scrollToTop()
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, id: number | null) => {
    setAnchorEl(event.currentTarget)
    setClickedId(id)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setClickedId(null)
  }

  const getBackgroundColor = (score: any) => {
    let background
    if (score >= 900) {
      background = '#7EEB89'
    } else if (score >= 700 && score < 900) {
      background = theme.palette.text.primary
    } else if (score >= 400 && score < 700) {
      background = '#F6BB8C'
    } else if (score < 400) {
      background = theme.palette.error.main
    }
    return background
  }

  const getTextColor = (status: string) => {
    let color
    switch (status) {
      case 'in_correction':
        color = '#F6BB8C'
        return color
      case 'overdue_enabled':
        color = theme.palette.error.main
        return color

      default:
        color = theme.palette.text.primary
        return color
    }
  }

  const columns: Columns[] = lgDown ? [
    {
      key: 'title',
      name: 'Título da atividade',
      width: '45%',
      align: 'left',
      render: (row: IActivity) => {
        return <TableTextCell
          onClick={() => handleNavigate(row)}
        >
          {row.name}
        </TableTextCell>
      }
    },
    {
      key: 'banca',
      name: 'Banca',
      width: '10%',
      align: 'left',
      render: (row: IActivity) => {
        return <TableTextCell
          onClick={() => handleNavigate(row)}
        >
          {row.jury ?? '-'}
        </TableTextCell>
      }
    },

    {
      key: 'status',
      name: 'Status',
      width: '5%',
      align: 'left',
      render: (row: IActivity) => {
        return <TableTextCell
          onClick={() => handleNavigate(row)}
          sx={{
            color: getTextColor(row.answer.status)
          }}
        >
          {translateStatus(row.answer.status)}
        </TableTextCell>
      }
    },
    {
      key: 'score',
      name: 'Nota',
      width: '5%',
      align: 'center',
      render: (row: IActivity) => {
        return <TableTextCell
          onClick={() => handleNavigate(row)}
          sx={{
            background: row.answer.grade ? getBackgroundColor(row.answer.grade) : 'transparent',
            borderRadius: '40px',
            color: row.answer.grade ? '#fff' : theme.palette.text.primary,
            padding: '8px 16px',
            textAlign: 'center'
          }}
        >
          {row.answer.grade ?? '-'}
        </TableTextCell>
      }
    },
    {
      key: 'menu',
      name: '',
      width: '5%',
      align: 'center',
      render: (row: IActivity) => {
        return <Box>
          <Button
            id={`table-menu-button-${row.id}`}
            aria-controls={open && clickedId === row.id ? 'table-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open && clickedId === row.id ? 'true' : undefined}
            onClick={(event) => handleClick(event, row.id)}
            sx={{
              ...(open && clickedId === row.id && {
                '& svg': {
                  background: theme.palette.primary.light,
                  borderRadius: '50%',
                  'path': {
                    stroke: '#fff',
                  },
                  'circle': {
                    stroke: theme.palette.primary.light,
                  }
                }
              })
            }}
          >
            <InfoIcon />
          </Button>
          <Menu
            id='table-menu'
            anchorEl={anchorEl}
            open={open && clickedId === row.id}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': `table-menu-button-${row.id}`,
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            sx={{
              width: '100%',
              '.MuiList-root': {
                width: '230px',
                paddingInline: '16px',
              },
              '.MuiPaper-root': {
                borderRadius: '24px'
              }
            }}
          >
            <Box className={styles.menu_container}>
              <Box className={styles.menu_item_container}>
                <button
                  onClick={handleClose}
                  className={styles.close_icon}
                >
                  <img src={CloseCircledIcon} />
                </button>
                <img src={CalendarIcon} />
                <Typography className={styles.menu_date_text}>
                  Início: &nbsp;<Typography className={styles.date_item}>
                    {row.start_datetime}
                  </Typography>
                </Typography>
              </Box>
              <Divider />
              <Box className={styles.menu_item_container}>
                <img src={RedCalendarIcon} />
                <Typography className={styles.menu_date_text} sx={{ color: theme.palette.error.main }}>
                  Termino: &nbsp;<Typography className={styles.date_item}>
                    {row.end_datetime}
                  </Typography>
                </Typography>
              </Box>
            </Box>
          </Menu>
        </Box>
      }
    },
  ] : [
    {
      key: 'title',
      name: 'Título da atividade',
      width: '45%',
      align: 'left',
      render: (row: IActivity) => {
        return <TableTextCell
          onClick={() => handleNavigate(row)}
        >
          {row.name}
        </TableTextCell>
      }
    },
    {
      key: 'banca',
      name: 'Banca',
      width: '10%',
      align: 'left',
      render: (row: IActivity) => {
        return <TableTextCell
          onClick={() => handleNavigate(row)}
        >
          {row.jury ?? '-'}
        </TableTextCell>
      }
    },
    {
      key: 'start-date',
      name: 'Início',
      width: '15%',
      align: 'left',
      render: (row: IActivity) => {
        const formattedStartDate = format(row.start_datetime, 'dd/MM/yyyy')
        return <TableTextCell
          onClick={() => handleNavigate(row)}
        >
          {formattedStartDate}
        </TableTextCell>
      }
    },
    {
      key: 'end-date',
      name: 'Término',
      width: '15%',
      align: 'left',
      render: (row: IActivity) => {
        const formattedEndDate = format(row.end_datetime, 'dd/MM/yyyy')
        return <TableTextCell
          onClick={() => handleNavigate(row)}
        >
          {formattedEndDate}
        </TableTextCell>
      }
    },
    {
      key: 'status',
      name: 'Status',
      width: '10%',
      align: 'left',
      render: (row: IActivity) => {
        return <TableTextCell
          onClick={() => handleNavigate(row)}
          sx={{
            color: getTextColor(row.answer.status)
          }}
        >
          {translateStatus(row.answer.status)}
        </TableTextCell>
      }
    },
    {
      key: 'score',
      name: 'Nota',
      width: '5%',
      align: 'center',
      render: (row: IActivity) => {
        return <TableTextCell
          onClick={() => handleNavigate(row)}
          sx={{
            background: row.answer.grade ? getBackgroundColor(row.answer.grade) : 'transparent',
            borderRadius: '40px',
            color: row.answer.grade ? '#fff' : theme.palette.text.primary,
            padding: '8px 16px',
            textAlign: 'center'
          }}
        >
          {row.answer.grade ?? '-'}
        </TableTextCell>
      }
    },
  ]

  if (isFetching) return <TableLoading />

  return (
    <Box>
      <CustomTable
        columns={columns}
        data={items.activities}
        customBackground='#E6D8FF'
      />
      <div className={styles.label_container}>
        <div className={styles.rewritten_label} />
        <Typography className={styles.rewritten_text}>
          Reescrita
        </Typography>
      </div>
      <Pagination
        count={items?.pagination?.pages_total}
        size='small'
        color='primary'
        page={page}
        onChange={handleChangePage}
        sx={{
          mt: '8px',
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      />
    </Box>
  )
}

export default ActivitiesTable