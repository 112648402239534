import React from 'react'
import { Box, Typography } from '@mui/material'
import { PageSubTitle } from './PageSubTitle'

type IActivityTitleWithScoreProps = {
  title: string
  score?: number
  maxWidth?: string
}

const ActivityTitleWithScore: React.FC<IActivityTitleWithScoreProps> = ({
  title,
  score,
  maxWidth
}) => {
  return (
    <Box
      display='flex'
      justifyContent='space-between'
      maxWidth={maxWidth ?? '100%'}
    >
      <PageSubTitle>
        {title}
      </PageSubTitle>
      <Box 
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '8px'
      }}
      >
        <Typography
          sx={{
            fontSize: '18px',
            fontWeight: 500,
            lineHeight: '120%'
          }}
        >
          Nota:
        </Typography>
        <Typography
          sx={{
            padding: '8px 16px',
            borderRadius: '40px',
            background: '#BEBEBE',
            fontWeight: 500
          }}
        >
          {score ?? '--'}
        </Typography>
      </Box>
    </Box>
  )
}

export default ActivityTitleWithScore