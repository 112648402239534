/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Collapse, styled, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import TeacherAvatar from '../../../assets/activities/teacher-avatar.png'
import { getBackgroundColor } from '../../../utils/functions'

type IEssayCompetenceCollapseProps = {
  data: any
}

const AccordionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '18px',
  fontWeight: 600,
  lineHeight: '120%'
}))
const ScoreText = styled(Typography)(({ theme }) => ({
  fontSize: '24px',
  fontWeight: 600,
  lineHeight: '120%'
}))
const LabelText = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '120%'
}))
const PercentageChip = styled(Typography)(({ theme }) => ({
  borderRadius: '40px',
  fontWeight: 500,
  lineHeight: '120%',
  padding: '8px 16px',
}))

const EssayCompetenceCollapse: React.FC<IEssayCompetenceCollapseProps> = ({
  data
}) => {

  
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        '.MuiAccordion-root:first-of-type': {
          borderTopLeftRadius: '20px !important',
          borderTopRightRadius: '20px !important',
        },
        '.MuiAccordion-root:last-of-type': {
          borderBottomLeftRadius: '20px !important',
          borderBottomRightRadius: '20px !important',
        },
        '.MuiAccordion-root.Mui-expanded': {
          margin: 0
        },
        '.MuiAccordion-root::before': {
          display: 'none'
        }
      }}
    >
      {
        data?.map((item: any, index: any) => {
          return (
            <Accordion
              key={index}
              sx={{
                padding: '24px',
                background: getBackgroundColor(item?.competence?.order).competenceBackground,
                borderRadius: '20px'
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1-content'
                id='panel1-header'
                sx={{
                  '.MuiAccordionSummary-content': {
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'space-between',
                    border: 'none',
                    margin: 0
                  }
                }}
              >
                <AccordionTitle>Competencia {item?.competence?.order}</AccordionTitle>
                <Box sx={{ display: 'flex' }}>
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      gap: '8px',
                      marginRight: '35px'
                    }}
                  >
                    <LabelText>1º Envio:</LabelText>
                    <LabelText
                      sx={{
                        display: 'flex',
                        gap: '8px'
                      }}
                    >
                      <ScoreText>{item?.competence?.content?.firstSent?.score}</ScoreText> de 200
                    </LabelText>
                    <PercentageChip
                      bgcolor={getBackgroundColor(item?.competence?.order).percentageBackground}
                    >
                      {item?.competence?.content?.firstSent?.percentage}%
                    </PercentageChip>
                  </Box>
                  {
                    item?.competence?.content?.secondSent && <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        gap: '8px',
                        marginRight: '35px'
                      }}
                    >
                      <LabelText>2º Envio:</LabelText>
                      <LabelText
                        sx={{
                          display: 'flex',
                          gap: '8px'
                        }}
                      >
                        <ScoreText>{item?.competence?.content?.secondSent?.score}</ScoreText> de 200
                      </LabelText>
                      <PercentageChip
                        bgcolor={getBackgroundColor(item?.competence?.order).percentageBackground}
                      >
                        {item?.competence?.content?.secondSent?.percentage}%
                      </PercentageChip>
                    </Box>
                  }
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  <LabelText mb='17px'>1º Envio:</LabelText>
                  <Box display='flex'>
                    <Avatar src={TeacherAvatar} sx={{ width: 48, height: 48, marginRight: 1 }} />
                    <Typography>
                      {item?.competence?.content?.firstSent?.comment}
                    </Typography>
                  </Box>
                </Box>
                {
                  item?.competence?.content?.secondSent &&
                  <Box
                    sx={{
                      background: getBackgroundColor(item?.competence?.order).competenceSecondSentBackground,
                      borderRadius: '20px',
                      marginTop: '8px',
                      padding: '25px',
                    }}
                  >
                    <LabelText mb='17px'>2º Envio:</LabelText>
                    <Box display='flex'>
                      <Avatar src={TeacherAvatar} sx={{ width: 48, height: 48, marginRight: 1 }} />
                      <Typography>
                        {item?.competence?.content?.secondSent?.comment}
                      </Typography>
                    </Box>
                  </Box>
                }
              </AccordionDetails>
            </Accordion>
          )
        })
      }
    </Box>
  )
}

export default EssayCompetenceCollapse