import React from 'react'

// Assets
import SuccessPanelImage from '../../assets/successPanel/success-panel-image.png'

// Components
import StudentInfoSuccessPanelCard from '../../components/card/StudentInfoSuccessPanelCard'
import PageCardWithImageAndTooltipMobile from '../../components/card/PageCardWithImageAndTooltipMobile'
import { PageSubTitle } from '../../components/title/PageSubTitle'
import { rows } from '../../components/table/successPanel/SuccessPanelTable'

// Styles
import styles from './styles/successPanelMobile.module.scss'

const SuccessPanelMobileContainer = () => {
  return (
    <div className={styles.container}>
      <PageCardWithImageAndTooltipMobile
        info='Veja o ranking dos melhores alunos, acesse suas redações e use-as como referência para tirar nota 1000.'
        photo={SuccessPanelImage}
      />
      <div className={styles.title_container}>
        <PageSubTitle>Alunos</PageSubTitle>
        {
          rows.map((item, index) => {
            return (
              <StudentInfoSuccessPanelCard
                photo={item.photo}
                key={item.id}
                name={item.name}
                score={item.nota}
                classroom={item.turma}
                index={index}
              />
            )
          })
        }
      </div>
    </div>
  )
}

export default SuccessPanelMobileContainer