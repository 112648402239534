import React, { useEffect } from 'react'
import { useTheme } from '@mui/material/styles'
import { useMediaQuery } from '@mui/system'
import GuidedWritingMobileContainer from '../containers/guidedWriting/GuidedWritingMobileContainer'
import GuidedWritingDesktopContainer from '../containers/guidedWriting/GuidedWritingDesktopContainer'

const GuidedWritingPage = () => {
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  useEffect(() => {
    document.title = "Redação Guiada | Verbo"
  }, [])
  return (
    smDown ? <GuidedWritingMobileContainer /> : <GuidedWritingDesktopContainer />
  )
}

export default GuidedWritingPage