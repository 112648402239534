import React from 'react'
import {
  Box,
  Grid,
} from '@mui/material'
import EssayThemeCard from '../../components/card/EssayThemeCard'
import { useTheme } from '@mui/material/styles'

const temas = [
  {
    id: 1,
    text: 'Como proteger as relações humanas diante dos avanços tecnológicos?',
    banca: 'ENEM',
    redacoes: 320,
    tema: 'internacional'
  },
  {
    id: 2,
    text: 'Possíveis ações de promoção do desenvolvimento econômico',
    banca: 'VUNESP',
    redacoes: 210,
    tema: 'meio ambiente'
  },
  {
    id: 3,
    text: 'Desafios no combate à cultura de estupro no Brasil',
    banca: 'Anos Finais',
    redacoes: 450,
    tema: 'ENEM'
  },
  {
    id: 4,
    text: 'Desafios no tratamento de dependentes químicos',
    banca: 'CESPE',
    redacoes: 370,
    tema: 'outros eixos'
  },
  {
    id: 5,
    text: 'A relevância das cotas sociais no Brasil para a redução das desigualdades',
    banca: 'Entrevista',
    redacoes: 280,
    tema: 'sociedade'
  },
  {
    id: 6,
    text: 'As consequências da romantização de crimes na sociedade brasileira',
    banca: 'Entrevista',
    redacoes: 340,
    tema: 'ciência e saúde'
  },
  {
    id: 7,
    text: 'As consequências da romantização de crimes na sociedade brasileira',
    banca: 'Entrevista',
    redacoes: 430,
    tema: 'saúde'
  },
  {
    id: 8,
    text: 'Alienação parental',
    banca: 'Entrevista',
    redacoes: 120,
    tema: 'tecnologia'
  },
  {
    id: 9,
    text: 'As consequências da romantização de crimes na sociedade brasileira',
    banca: 'Entrevista',
    redacoes: 295,
    tema: 'economia'
  },
  {
    id: 10,
    text: 'As consequências da romantização de crimes na sociedade brasileira',
    banca: 'Entrevista',
    redacoes: 400,
    tema: 'política'
  }
]

const EssayThemeCards = () => {
  const theme = useTheme()
  const getBackgroundColor = (essayTheme: string) => {
    let background
    switch (essayTheme) {
      case 'internacional':
        background = '#F2ECFC'
        return background
      case 'meio ambiente':
        background = '#FFEDDD'
        return background
      case 'ENEM':
        background = '#F2F5FF'
        return background
      case 'outros eixos':
        background = '#DAF0F5'
        return background
      case 'sociedade':
        background = '#F4FBE6'
        return background
      case 'ciência e saúde':
        background = '#FFFAEC'
        return background
      case 'saúde':
        background = '#F9ECC9'
        return background
      case 'tecnologia':
        background = '#FFEAD8'
        return background
      case 'economia':
        background = '#E6D8FF'
        return background
      case 'política':
        background = '#DAF0F5'
        return background
      default:
        background = theme.palette.primary.light
        return background
    }
  }
  return (
    <Box>
      <Grid container spacing={'21px'}>
        {temas.map((tema) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={tema.id}>
            <EssayThemeCard
              text={tema.text}
              banca={tema.banca}
              numberOfEssay={tema.redacoes}
              backgroundColor={getBackgroundColor(tema.tema)}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default EssayThemeCards