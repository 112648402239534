/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { Box, Tooltip, Typography } from '@mui/material'
import InfoIcon from '../../assets/home/card/information-circulated-icon.svg'
import InfoIconHover from '../../assets/home/card/information-icon-filled.svg'

import { styled } from '@mui/material/styles'

type IPageCardWithImageAndTooltipMobile = {
  info: string
  photo: string
}

const Image = styled('img')(() => ({
  position: 'absolute',
  width: '343px',
  zIndex: '1'
}))

const PageCardWithImageAndTooltipMobile: React.FC<IPageCardWithImageAndTooltipMobile> = ({
  info,
  photo
}) => {
  return (
    <Box
      sx={{
        height: '152px',
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          maxWidth: '343px',
          justifyContent: 'center',
          position: 'relative'
        }}
      >

        <Tooltip
          title={info}
        >
          <Typography
            component='span'
            sx={{
              position: 'absolute',
              top: 25,
              right: '8px',
              zIndex: 9,
              height: '24px',
              width: '24px',
              display: 'block',
              backgroundImage: `url(${InfoIcon})`,
              backgroundSize: 'contain',
              transition: 'ease-in-out 0.2s',
              ':hover': {
                backgroundImage: `url(${InfoIconHover})`,
              }
            }}
          />
        </Tooltip>
        <Image src={photo} />
      </Box>
    </Box>
  )
}

export default PageCardWithImageAndTooltipMobile