import React from 'react'
import TopRightCornerShape from '../../assets/home/card/right-top-corner.svg'
import { Box, Tooltip } from '@mui/material'
import styles from './styles/informationTopRight.module.scss'

type IInformationProps = {
  info: string
}

const InformationTopRight: React.FC<IInformationProps> = ({
  info
}) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        right: 0,
        display: 'flex',
        justifyContent: 'flex-end',
        backgroundImage: `url(${TopRightCornerShape})`,
        width: '45px',
        height: '45px'
      }}
    >
      <Tooltip
        title={info}
      >
        <span className={styles.icon} />
      </Tooltip>
    </Box>
  )
}

export default InformationTopRight