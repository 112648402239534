import React from 'react'
import { Box, Typography } from '@mui/material'
import LeftRoundedPinkArrow from '../../assets/addNewTheme/left-rounded-pink-arrow.svg'
import { useTheme, styled } from '@mui/material/styles'

type INavigateButtonWithPathLabelProps = {
  text: string,
  onClick: any
}

const Button = styled('button')(({ theme }) => ({
  alignItems: 'center',
  color: theme.palette.primary.light,
  cursor: 'pointer',
  display: 'flex',
  fontSize: '22px',
  fontWeight: 600,
  fontFamily: 'Montserrat',
  gap: '8px',
  ':hover': {
    textDecoration: 'underline'
  }
}))

const NavigateButtonWithPathLabel: React.FC<INavigateButtonWithPathLabelProps> = ({
  text,
  onClick
}) => {
  const theme = useTheme()
  const parts = text.split('/').map((part: string) => part.trim())
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        gap: '32px',
        marginTop: '55px',
        marginBottom: '58px'
      }}
    >
      <Button
        onClick={() => onClick('1')}
      >
        <img src={LeftRoundedPinkArrow} />
        <Typography
          sx={{
            color: theme.palette.primary.light,
            fontSize: '22px',
            fontWeight: 600,
            lineHeight: '120%'
          }}
        >Voltar</Typography>
      </Button>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          gap: '32px'
        }}
      >
        <span>
          {
            parts.map((part: string, index: number) => (
              <React.Fragment key={index}>
                <span
                  style={{
                    color: index === parts.length - 1 ? theme.palette.text.primary : '#BEBEBE',
                    fontSize: '18px',
                    fontWeight: 500,
                    marginRight: '4px'
                  }}
                >
                  {part}
                </span>
                {
                  index < parts.length - 1 && <span
                    style={{
                      color: '#BEBEBE',
                      marginRight: '4px'
                    }}
                  >/</span>
                }
              </React.Fragment>
            ))
          }
        </span>
      </Box>
    </Box>
  )
}

export default NavigateButtonWithPathLabel