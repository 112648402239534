/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'

// Assets
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import cartoonWoman from '../../assets/home/cartoon-woman-competence-card.png'

// Components
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper'
import { Box, Typography } from '@mui/material'

// Styles
import 'swiper/css'
import { styled } from '@mui/material/styles'

type ICompetencesSliderProps = {
  data: any
}

const BoxStyled = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  borderRadius: '32px',
  display: 'flex',
  gap: '8px',
  justifyContent: 'center',
  position: 'relative',
  background: '#F2F5FF',
  padding: '20px 40px',
  width: '100%',

  '& .main-slider-prev, .main-slider-next': {
    width: 20,
    height: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.text.primary,
    borderRadius: '50%',
    outline: 'none',
    border: 'none',
    cursor: 'pointer',
    position: 'absolute',
    zIndex: 2,
  },

  '& .main-slider-prev': {
    left: '8px'
  },
  '& .main-slider-next': {
    right: '8px'
  },

  '& .swiper-button-disabled': {
    opacity: 0.4,
  }
}))

const CompetencesSlider: React.FC<ICompetencesSliderProps> = ({ data }) => {

  const getColor = (percentage: number) => {
    if (percentage <= 50) {
      return '#FFF6EE'
    } else if (percentage <= 70) {
      return '#FFF6EE'
    } else if (percentage <= 79.5) {
      return '#DAF0F5'
    } else if (percentage <= 80) {
      return '#F4FBE6'
    } else if (percentage <= 100) {
      return '#F2F5FF'
    } else {
      return '#d9d9d9'
    }
  }

  return (
    <BoxStyled>
      <button className='main-slider-prev'>
        <ArrowBackIosNewRoundedIcon sx={{ fontSize: '14px', color: '#fff' }} />
      </button>
      <Swiper
        slidesPerView={'auto'}
        className='slider'
        modules={[Navigation]}
        navigation={{
          prevEl: '.main-slider-prev',
          nextEl: '.main-slider-next'
        }}
      >
        {
          data?.tip.map((item: any) => {
            return (
              <SwiperSlide
                key={item.id}
              >
                <Box>
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      gap: '8px',
                      marginBottom: '12px'
                    }}
                  >
                    <img src={cartoonWoman} />
                    <Typography
                      sx={{
                        fontSize: '18px',
                        fontWeight: 500,
                        lineHeight: '120%',
                      }}
                    >
                      {item?.title}
                    </Typography>
                    <Box>
                      <Typography
                        sx={{
                          background: getColor(data.percentage),
                          marginLeft: '23px',
                          padding: '8px 16px',
                          borderRadius: '40px'
                        }}
                      >{data.percentage}%</Typography>
                    </Box>
                  </Box>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '150%'
                    }}
                  >
                    {item.text}
                  </Typography>
                </Box>
              </SwiperSlide>

            )
          })
        }
      </Swiper>
      <button className='main-slider-next'>
        <ArrowForwardIosRoundedIcon sx={{ fontSize: '14px', color: '#fff' }} />
      </button>
    </BoxStyled>
  )
}

export default CompetencesSlider