import React from 'react'

// Assets
import SuccessPanelImage from '../../assets/home/success-panel.svg'

// Components
import { Box, useMediaQuery } from '@mui/material'
import NavigateRoundedButton from '../button/NavigateRoundedButton'
import InformationIconWithTooltip from '../tooltip/InformationIconWithTooltip'
import { PageSubTitle } from '../title/PageSubTitle'

// Styles
import { useTheme, styled } from '@mui/material/styles'

const Image = styled('img')(() => ({
  marginBottom: '32px'
}))

const SuccessPanelV2 = () => {
  const theme = useTheme()
  const xlDown = useMediaQuery(theme.breakpoints.down('xl'))
  const xlUp = useMediaQuery(theme.breakpoints.up('xl'))
  const matches = useMediaQuery('(min-width:1500px)')
  const imageSize = () => {
    let imageWidth
    if (xlDown) {
      imageWidth = 300
    } else if (matches) {
      imageWidth = 350
    } else if (xlUp) {
      imageWidth = 400
    }
    return imageWidth
  }

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: '40px'
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '24px',
          width: '100%'
        }}
      >
        <PageSubTitle>Painel Sucesso</PageSubTitle>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            gap: '8px'
          }}
        >
          <InformationIconWithTooltip info='Painel de sucesso' />
        </Box>
      </Box>
      <Image width={imageSize()} src={SuccessPanelImage} />
      <NavigateRoundedButton text='Participar' />
    </Box>
  )
}

export default SuccessPanelV2