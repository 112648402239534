import React, { useState } from 'react'

// Compoentens
import { Box, Typography } from '@mui/material'
import { PageSubTitle } from '../../../components/title/PageSubTitle'
import SelectInput from '../../../components/input/SelectInput'
import CompetencesSliderMobile from '../../../components/slider/CompetencesSliderMobile'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper'

// Styles
import { styled, useTheme } from '@mui/material/styles'
import 'swiper/css'

const BoxStyled = styled(Box)(() => ({
  alignItems: 'center',
  borderRadius: '32px',
  display: 'flex',
  gap: '8px',
  justifyContent: 'center',
  position: 'relative',
  width: '100%',
  '& .slider': {
    width: 'fit-content'
  },
  '& .swiper-slide': {
    width: 'fit-content'
  },
}))

export const CompetenceButton = styled('button')(() => ({
  borderRadius: '40px',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '120%',
  padding: '8px 16px',
  width: 'fit-content',
}))

const CompetenceContainerMobile = () => {
  const theme = useTheme()

  const options = [
    { label: 'ENEM', value: 'ENEM' },
    { label: 'Concurso', value: 'concurso' },
    { label: 'ITA', value: 'ita' }
  ]

  const bancaOptions = [
    { label: 'Selecione...', value: 'placeholder' },
    ...options
  ]

  const competences = [
    {
      id: 1,
      title: 'Competence 1',
      percentage: 90,
      tip: [
        {
          id: 11,
          text: 'Para ser avaliada em 200, a redação não pode mais apresentar os problemas descritos em 120 e em 160. Esse nível é atribuído àqueles textos que conseguiram cumprir com êxito o que a Grade da Competência III exige. Portanto, a redação deve evidenciar um projeto de texto estratégico, além de apresentar informações, fatos e opiniões desenvolvidos max caract:361',
          title: 'Rumo a nota 1000'
        },
        {
          id: 12,
          text: 'Para ser avaliada em 200, a redação não pode mais apresentar os problemas descritos em 120 e em 160. Esse nível é atribuído àqueles textos que conseguiram cumprir com êxito o que a Grade da Competência III exige. Portanto, a redação deve evidenciar um projeto de texto estratégico, além de apresentar informações, fatos e opiniões desenvolvidos max caract:361',
          title: 'Rumo ao sucesso'
        },
      ]
    },
    {
      id: 2,
      title: 'Competence 2',
      percentage: 75,
      tip: [
        {
          id: 13,
          text: 'Para ser avaliada em 200, a redação não pode mais apresentar os problemas descritos em 120 e em 160. Esse nível é atribuído àqueles textos que conseguiram cumprir com êxito o que a Grade da Competência III exige. Portanto, a redação deve evidenciar um projeto de texto estratégico, além de apresentar informações, fatos e opiniões desenvolvidos max caract:361',
          title: 'COmo tirar 1000'
        },
      ]
    },
    {
      id: 3,
      title: 'Competence 3',
      percentage: 60,
      tip: [
        {
          id: 14,
          text: 'Para ser avaliada em 200, a redação não pode mais apresentar os problemas descritos em 120 e em 160. Esse nível é atribuído àqueles textos que conseguiram cumprir com êxito o que a Grade da Competência III exige. Portanto, a redação deve evidenciar um projeto de texto estratégico, além de apresentar informações, fatos e opiniões desenvolvidos max caract:361',
          title: 'Melhore sua redação'
        },
      ]
    },
    {
      id: 4,
      title: 'Competence 4',
      percentage: 85,
      tip: [
        {
          id: 15,
          text: 'Para ser avaliada em 200, a redação não pode mais apresentar os problemas descritos em 120 e em 160. Esse nível é atribuído àqueles textos que conseguiram cumprir com êxito o que a Grade da Competência III exige. Portanto, a redação deve evidenciar um projeto de texto estratégico, além de apresentar informações, fatos e opiniões desenvolvidos max caract:361',
          title: 'Seu mil'
        },
        {
          id: 16,
          text: 'Para ser avaliada em 200, a redação não pode mais apresentar os problemas descritos em 120 e em 160. Esse nível é atribuído àqueles textos que conseguiram cumprir com êxito o que a Grade da Competência III exige. Portanto, a redação deve evidenciar um projeto de texto estratégico, além de apresentar informações, fatos e opiniões desenvolvidos max caract:361',
          title: 'Melhore sua redação'
        },
        {
          id: 17,
          text: 'Para ser avaliada em 200, a redação não pode mais apresentar os problemas descritos em 120 e em 160. Esse nível é atribuído àqueles textos que conseguiram cumprir com êxito o que a Grade da Competência III exige. Portanto, a redação deve evidenciar um projeto de texto estratégico, além de apresentar informações, fatos e opiniões desenvolvidos max caract:361',
          title: 'NOta mil'
        },
      ]
    },
    {
      id: 5,
      title: 'Competence 5',
      percentage: 50,
      tip: [
        {
          id: 18,
          text: 'Para ser avaliada em 200, a redação não pode mais apresentar os problemas descritos em 120 e em 160. Esse nível é atribuído àqueles textos que conseguiram cumprir com êxito o que a Grade da Competência III exige. Portanto, a redação deve evidenciar um projeto de texto estratégico, além de apresentar informações, fatos e opiniões desenvolvidos max caract:361',
          title: 'NOta 1000'
        },
      ]
    },
  ]

  // Define o estado inicial com o primeiro item da lista de competences
  const [buttonId, setButtonId] = useState(competences[0].id)
  const [active, setActive] = useState(true)
  const [competence, setCompetence] = useState(competences[0].id)

  const handleSelectCompetence = (itemId: number) => {
    setActive(true)
    setButtonId(itemId)
    setCompetence(itemId)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        paddingBlock: '25px'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
          justifyContent: 'space-between',
          marginBottom: '26px',
          width: '100%'
        }}
      >
        <PageSubTitle>Competências</PageSubTitle>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <Typography>Banca:</Typography>
          <SelectInput
            width='fit-content'
            name='selectSubject'
            border='none'
            onChange={() => null}
            defaultValue='placeholder'
            options={bancaOptions}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '8px',
          marginBottom: '16px'
        }}
      >
        <BoxStyled>
          <Swiper
            slidesPerView={'auto'}
            spaceBetween={8}
            className='slider'
            modules={[Navigation]}
          >
            {
              competences.map((item: any) => {
                return (
                  <SwiperSlide
                    key={item.id}
                  >
                    <CompetenceButton
                      key={item.id}
                      onClick={() => handleSelectCompetence(item.id)}
                      sx={{
                        background: active && (buttonId === item.id)
                          ? theme.palette.primary.light
                          : '#E0E0E066',
                        color: active && (buttonId === item.id)
                          ? '#fff'
                          : '#BEBEBE'
                      }}
                    >
                      {item.title}
                    </CompetenceButton>
                  </SwiperSlide>
                )
              })
            }
          </Swiper>
        </BoxStyled>
      </Box>
      <Box>
        <CompetencesSliderMobile
          data={competences.find((item: any) => item.id === competence)}
        />
      </Box>
    </Box>
  )
}

export default CompetenceContainerMobile
