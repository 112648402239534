import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

// Assets
import ActivitiesImage from '../../assets/activities/activities-mobile-image.png'

// Components
import { Box, Pagination, Typography } from '@mui/material';
import PageCardWithImageAndTooltipMobile from '../../components/card/PageCardWithImageAndTooltipMobile';
import { PageSubTitle } from '../../components/title/PageSubTitle';
import ActivityCardMobile from '../../components/card/ActivityCardMobile';
import SelectInput from '../../components/input/SelectInput';
import { DatePickerField } from '../../components/input/DatePickerInput';
import ActivityConcludedCardMobileLoading from '../../components/loading/ActivityConcludedCardMobileLoading';

// Redux
import { IActivity } from '../../store/activity/types';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../store/hooks';

// Styles
import { useTheme } from '@mui/material/styles'
import styles from './styles/activitiesMobile.module.scss'

// Utils
import { format, formatDistance } from 'date-fns';
import { getStudentActivityListRequest } from '../../store/activity/actions';
import { scrollToTop } from '../../utils/functions';

const ActivitiesMobileContainer = () => {

  const navigate = useNavigate()
  const theme = useTheme()
  const dispatch = useDispatch()

  const [page, setPage] = useState(1)

  // Filters States
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [statusSelected, setStatusSelected] = useState()
  const [startDateFilter, setStartDateFilter] = useState('')

  const { items, isFetching } = useAppSelector(state => state.activity)

  const status = [
    { label: 'Todas', value: 'all' },
    { label: 'Corrigída', value: 'corrected' },
    { label: 'Em Correção', value: 'in_correction' },
    { label: 'Não corrigida', value: 'not_corrected' },
    { label: 'Em atraso permitido', value: 'overdue_enabled' },
    { label: 'Visualizado', value: 'visualized' },
    { label: 'Não Visualizado', value: 'not_visualized' },
  ]

  const statusOptions = [
    { label: 'Selecione...', value: 'placeholder' },
    ...status
  ]
  const options = [
    { label: 'ENEM', value: 'ENEM' },
    { label: 'Concurso', value: 'concurso' },
    { label: 'ITA', value: 'ita' }
  ]

  const bancaOptions = [
    { label: 'Selecione...', value: 'placeholder' },
    ...options
  ]

  const resetLocation = () => {
    const newLocation = {
      ...location,
      state: null
    }
    navigate(newLocation)
  }

  const handleGetActivitiesItems = (params: object) => {
    dispatch(getStudentActivityListRequest(params))
  }

  const handleStartChanges = (startValue: any) => {
    if (startValue) {
      startValue && setStartDateFilter(format(startValue, 'yyyy-MM-dd'))
    }
    const startDateFormatted = startValue ? format(startValue, 'yyyy-MM-dd') : ''
    if (endDate && formatDistance(startValue, endDate)) {
      setEndDate(null)
      handleGetActivitiesItems({
        status: statusSelected,
        per_page: 5,
        end_datetime_after: startDateFormatted,
        page: 1
      })
    } else {
      handleGetActivitiesItems({
        status: statusSelected,
        per_page: 5,
        end_datetime_after: startDateFormatted,
        page: 1
      })
    }
    setPage(1)
    setStartDate(startValue)
    resetLocation()
  }

  const handleEndChanges = (endValue: any) => {
    const endDateFormatted = endValue ? format(endValue, 'yyyy-MM-dd') : ''
    setEndDate(endValue)
    setPage(1)
    handleGetActivitiesItems({
      status: statusSelected,
      end_datetime_before: endDateFormatted,
      page: 1
    })
    resetLocation()
  }

  const handleFilterByStatus = (value: string) => {
    if (value === 'all') {
      handleGetActivitiesItems({
        per_page: 5,
        ...(startDateFilter ? { end_datetime_after: startDateFilter } : {}),

      })
      setPage(1)
    } else {
      handleGetActivitiesItems({
        status: value,
        per_page: 5,
        ...(startDateFilter ? { end_datetime_after: startDateFilter } : {}),
      })
      setPage(1)
    }
  }

  const handleFilterSelect = (event: any) => {
    setStatusSelected(event)
    handleFilterByStatus(event)
  }

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
    dispatch(getStudentActivityListRequest({
      per_page: 5,
      page: value,
      ...(statusSelected === 'all' ? {} : { status: statusSelected }),
      ...(startDateFilter ? { end_datetime_after: startDateFilter } : {}),

    }))
    scrollToTop()
  }

  return (
    <div className={styles.container}>
      <PageCardWithImageAndTooltipMobile
        photo={ActivitiesImage}
        info='Aqui, você gerencia suas redações enviadas, acompanha o progresso, visualiza as notas e recebe orientações para melhorar sua escrita.'
      />
      <div className={styles.title_container}>
      </div>
      <PageSubTitle>Minhas atividades</PageSubTitle>
      <div className={styles.filters}>
        <Box className={styles.filter_container}>
          <label>Status</label>
          <SelectInput
            width='fit-content'
            name='selectSubject'
            border='none'
            onChange={(event: any) => handleFilterSelect(event.target.value)}
            defaultValue='placeholder'
            options={statusOptions}
          />
        </Box>
        <Box className={styles.filter_container}>
          <label>Banca</label>
          <SelectInput
            width='fit-content'
            name='selectSubject'
            border='none'
            onChange={() => null}
            defaultValue='placeholder'
            options={bancaOptions}
          />
        </Box>
        <Box
          sx={{
            display: 'flex'
          }}
        >
          <Box
            className={styles.filter_container}
          >
            <Box>
              <Typography
                color={theme.palette.text.primary}
                variant='subtitle2'
                component='p'
              >
                Iniciam entre
              </Typography>
            </Box>
            <Box display='flex' gap='8px'>
              <DatePickerField
                name='start_date'
                onChange={handleStartChanges}
                startDate={startDate}
                endDate={endDate}
                placeholder='dd/mm/aaaa'
                selectsStart
              />
              <DatePickerField
                name='end_date'
                onChange={handleEndChanges}
                startDate={endDate}
                endDate={endDate}
                minDate={startDate}
                placeholder='dd/mm/aaaa'
              />
            </Box>
          </Box>
        </Box>
      </div>
      {
        isFetching ? (
          <ActivityConcludedCardMobileLoading />
        ) : (
          <div className={styles.cards_container}>
            {
              items.activities.map(
                (item: IActivity) => {
                  return (
                    <ActivityCardMobile
                      key={item.id}
                      name={item.name}
                      banca={item.jury}
                      endDate={item.end_datetime}
                      rewritten={false}
                      status={item.answer.status}
                      score={item.answer.grade}
                      item={item}
                    />
                  )
                }
              )
            }
            <Pagination
              count={items?.pagination?.pages_total}
              size='small'
              color='primary'
              page={page}
              onChange={handleChangePage}
              sx={{
                mt: '8px',
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            />
          </div>
        )
      }
    </div>
  )
}

export default ActivitiesMobileContainer