import React from 'react'

// Components
import { Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import GradeCardMobile from '../card/GradeCardMobile'

import { rows } from '../table/grades/GradesTable'

// Styles
import { styled } from '@mui/material'
import "swiper/css"
import "swiper/css/pagination"
import NoScoresCard from '../card/NoScoresCard'

const BoxStyled = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: '8px',
  justifyContent: 'center',
  marginTop: '22px',
  '&:hover': {
    cursor: 'grab'
  },
  '& .slider': {
    marginInline: 0,
    height: '250px',
    maxWidth: '375px'
  },
  '& .swiper-pagination-bullet-active': {
    background: '#8842FE'
  },
  '& .swiper-pagination-bullet': {
    height: '8px',
    width: '8px'
  },
  '& .swiper-pagination': {
    bottom: '0px !important'
  },
  '& .main-slider-prev, .main-slider-next': {
    width: 22,
    height: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.text.primary,
    borderRadius: '50%',
    outline: 'none',
    border: 'none',
    cursor: 'grab',
  },
  '& .swiper-button-disabled': {
    opacity: 0.4,
  }
}))

function GradesMobileSlider() {
  return (
    <>
      {
        (rows.length > 0) ?
        <BoxStyled>
          <Swiper
            slidesPerView={2}
            spaceBetween={16}
            className='slider'
            modules={[Pagination]}
            navigation={{
              prevEl: '.main-slider-prev',
              nextEl: '.main-slider-next'
            }}
            pagination={{
              clickable: true
            }}
          >
            {
              rows.map((item: any) => {
                return (
                  <SwiperSlide key={item.id}>
                    <GradeCardMobile data={item} />
                  </SwiperSlide>
                )
              })
            }
          </Swiper>
        </BoxStyled> : <NoScoresCard />
      }
    </>
  )
}

export default GradesMobileSlider;
