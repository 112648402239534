import * as React from 'react'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { DefaultOptionType } from '../../utils/types'
import { FieldRenderProps } from 'react-final-form' // eslint-disable-line

interface Props {
  name: string,
  placeholder?: string,
  variant?: 'filled' | 'standard' | 'outlined' | undefined,
  border?: string,
  width?: string,
  label?: string,
  disabled?: boolean
  defaultValue?: any,
  options: DefaultOptionType[]
  onChange: any
  sx?: React.CSSProperties
  rest?: any
  background?: string
}

interface SelectStyledProps {
  border?: string
  width?: string,
  background?: string
}

const SelectStyled = styled(Select)<SelectStyledProps>(({ theme, border, background, width }) => ({
  borderRadius: '12px',
  height: 40,
  fontSize: 14,
  fontWeight: 500,
  lineHeight: '150%',
  border: border || `1px solid ${theme.palette.text.primary}`,
  width: width,
  color: theme.palette.text.primary,
  background: background || '#F2F5FF' ,
  '& fieldset': {
    border: 'none',
  },
  '& .MuiSelect-icon': {
    fontSize: '1rem'
  },
  '& .MuiOutlinedInput-input': {
    fontWeight: 500
  }
}))

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: '400px'
    },
  },
}

export default function SelectInput({
  defaultValue,
  placeholder,
  name,
  label,
  options,
  onChange,
  border,
  width = '444px',
  variant = 'outlined',
  disabled = false,
  sx,
  rest
}: Props) {
  const [value, setValue] = React.useState(defaultValue)

  React.useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  const handleChange = (event: any) => {
    setValue(event.target.value)
    onChange(event)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column'
      }}>
      {label &&
        <Typography
          sx={{
            color: '#9F9F9F',
            mb: 1,
            fontWeight: 500
          }}
          variant='body2'
          component='label'
        >
          {label}
        </Typography>}
      <FormControl fullWidth>
        <SelectStyled
          labelId={name}
          id={name}
          value={value}
          placeholder={placeholder}
          onChange={handleChange}
          variant={variant}
          disabled={disabled}
          sx={sx}
          IconComponent={(props: any) => <KeyboardArrowDownIcon {...props} />}
          border={border}
          width={width}
          MenuProps={MenuProps}
          {...rest}
        >
          {/* {placeholder && <MenuItem sx={{ fontSize: 12 }} value='none' disabled>{placeholder}</MenuItem>} */}
          {
            options.map(option => (<MenuItem sx={{ fontSize: 12 }} key={`option-${option.value}`} value={option.value}>{option.label}</MenuItem>))
          }
        </SelectStyled>
      </FormControl>
    </Box >
  )
}

export const SelectInputField = ({
  defaultValue,
  placeholder,
  label,
  options,
  input: { name, value, onChange, ...restInput },
  change,
  variant='outlined',
  disabled=false,
  sx
}: any) => {

  React.useEffect(() => {
    defaultValue && change(name, defaultValue)
  }, [defaultValue])

  const handleChange = (event: any) => {
    onChange(event.target.value)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column'
      }}>
      {label &&
        <Typography
          sx={{
            color: '#9F9F9F',
            mb: 1,
            fontWeight: 500
          }}
          variant='body2'
          component='label'
        >
          {label}
        </Typography>}
      <FormControl fullWidth>
        <SelectStyled
          labelId={name}
          id={name}
          value={value}
          placeholder={placeholder}
          onChange={handleChange}
          variant={variant}
          disabled={disabled}
          sx={sx}
          IconComponent={(props: any) => <KeyboardArrowDownIcon {...props} />}
          {...restInput}
        >
          {/* {placeholder && <MenuItem sx={{ fontSize: 12 }} value='none' disabled>{placeholder}</MenuItem>} */}
          {
            options.map((option: any) => (
              <MenuItem
                sx={{ fontSize: 12 }}
                key={`option-${option.value}`}
                value={option.value}
              >
                {option.label}
              </MenuItem>))
          }
        </SelectStyled>
      </FormControl>
    </Box >
  )
}
