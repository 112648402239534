import { createAction } from '@reduxjs/toolkit'
import { withPayloadType } from '../../utils/functions'
import { AuthCredentials, SignInCredentials, SignSuccessPayload, UpdateCredentialsSuccess } from './types'

export const getUserDataRequest = createAction('users:GET_USER_DATA_REQUEST', withPayloadType<any>())
export const getUserDataSuccess = createAction('users:GET_USER_DATA_SUCCESS', withPayloadType<any>())
export const getUserDataRejected = createAction('users:GET_USER_DATA_REJECTED')

export const SIGN_IN = createAction('auth:SIGN_IN', withPayloadType<SignInCredentials>())
export const SIGN_IN_SUCCESSFUL = createAction('auth:SIGN_IN_SUCCESSFUL', withPayloadType<SignSuccessPayload>())
export const SIGN_IN_FAILURE = createAction('auth:SIGN_IN_FAILURE', withPayloadType<unknown>())

export const SIGN_OUT_REQUEST = createAction('auth:SIGN_OUT_REQUEST')
export const SIGN_OUT_SUCCESS = createAction('auth:SIGN_OUT_SUCCESS')
export const SIGN_OUT_FAILURE = createAction('auth:SIGN_OUT_FAILURE')

export const GET_COMPANY_INFO = createAction('auth:GET_COMPANY_INFO')

export const updateCredentialsRequest = createAction(
  'auth:UPDATE_CREDENTIALS_REQUEST',
  withPayloadType<AuthCredentials>()
)
export const updateCredentialsSuccess = createAction(
  'auth:UPDATE_CREDENTIALS_SUCCESS',
  withPayloadType<UpdateCredentialsSuccess>()
)
export const updateCredentialsFailure = createAction('auth:UPDATE_CREDENTIALS_FAILURE')
