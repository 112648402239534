
import React from 'react'

// Assets
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

// Components
import {
  Avatar,
  Box,
  Button,
  Divider,
  Menu,
  Typography,
  useMediaQuery
} from '@mui/material'

// Redux
import { useAppSelector } from '../../store/hooks'

// Styles
import { styled, useTheme } from '@mui/material/styles'

const MenuItem = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  gap: '8px',
  display: 'flex',
  alignItems: 'center',
  fontWeight: 500,
  fontSize: '14px',
  color: theme.palette.text.secondary,
  marginInline: 0,
  marginBlock: '8px',
  paddingInline: '8px',
  '&:hover': {
    fontWeight: 700
  },

}))

type IPersonalDataDropDownMenu = {
  background?: string
}

const PersonalDataDropDownMenu: React.FC<IPersonalDataDropDownMenu> = ({ background }) => {

  const theme = useTheme()
  const { user } = useAppSelector((state) => state.auth)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const userName = user?.name?.split(' ')
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const isSm = useMediaQuery('(max-width:1239px)')
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const getIcon = () => {
    let icon
    if (smDown) {
      icon = ''
    } else if (open) {
      icon = <KeyboardArrowUpIcon />
    } else {
      icon = <KeyboardArrowDownIcon />
    }
    return icon
  }

  return (
    <Box
      sx={{
        alignItems: 'center',
        background: background ?? '#fff',
        display: 'flex',
        borderRadius: '32px',
        borderBottomLeftRadius: open ? 0 : '32px',
        borderBottomRightRadius: open ? 0 : '32px'
      }}
    >
      <Button
        id='user-button'
        aria-controls={open ? 'user-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        endIcon={getIcon()}
        sx={{
          maxWidth: '219px',
          padding: '8px 16px',
          '&:hover': {
            background: 'none'
          }
        }}
      >
        <Box
          sx={{ display: 'flex' }}
        >
          <Avatar
            src={user?.photo_url}
            sx={{
              width: 32,
              height: 32,
              marginRight: 1,
            }}
          />
          {
            !smDown && <Box
              sx={{
                alignItems: 'flex-start',
                display: 'flex',
                flexDirection: 'column',
                gap: '2px',
                marginRight: isSm ? '8px' : '16px'
              }}
            >
              <Typography
                sx={{
                  color: 'var(--palette-text-tertiary)',
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '120%',
                  whiteSpace: 'nowrap'
                }}
              >
                {`${userName?.[0]} ${userName?.[userName?.length - 1]}`}
              </Typography>
              <Typography
                sx={{
                  color: theme.palette.text.secondary,
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '120%'
                }}
              >
                1º Ano B
              </Typography>
            </Box>
          }
        </Box>
      </Button >
      <Menu
        id='user-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'user-button',
        }}
        sx={{
          '.MuiList-root': {
            background: background ?? '#fff',
            width: '219px',
            paddingInline: '16px',
          },
          '.MuiPaper-root': {

            borderBottomLeftRadius: open ? '32px' : '',
            borderBottomRightRadius: open ? '32px' : ''
          }
        }}
      >
        {
          smDown && <Box
            sx={{
              alignItems: 'flex-start',
              display: 'flex',
              flexDirection: 'column',
              gap: '2px',
              marginRight: '8px'
            }}
          >
            <Typography
              sx={{
                color: 'var(--palette-text-tertiary)',
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '120%',
                whiteSpace: 'nowrap'
              }}
            >
              {`${userName?.[0]} ${userName?.[userName?.length - 1]}`}
            </Typography>
            <Typography
              sx={{
                color: theme.palette.text.secondary,
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '120%'
              }}
            >
              1º Ano B
            </Typography>
          </Box>
        }
        <Divider />
        <MenuItem>
          <Typography
            sx={{
              color: theme.palette.text.primary,
              background: '#F2F5FF',
              borderRadius: '8px',
              padding: '8px',
              width: '100%',
              fontWeight: 500
            }}
          >
            Turma Inglês
          </Typography>
        </MenuItem>
        <MenuItem>
          <Typography fontWeight={500} padding='8px'>Turma Francês</Typography>
        </MenuItem>
      </Menu>

    </Box >
  )
}

export default PersonalDataDropDownMenu