import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// Assets
import LeftArrowIcon from '../../../assets/activities/left-arrow-icon.svg'
import downloadIcon from '../../../assets/activities/download-icon-purple.svg'
import ActivityIcon from '../../../assets/activities/essay-icon.svg'
import ImageError from '../../../assets/activities/image-error.png'
import EssaySentImage from '../../../assets/activities/essay-sent-image.png'

// Components
import { Box, Button, LinearProgress, Tab, Typography } from '@mui/material'
import GradeWithLabel from '../../../components/text/GradeWithLabel'
import MyActivityCardMobile from '../../../components/card/MyActivityCardMobile'
import InformationIconWithTooltip from '../../../components/tooltip/InformationIconWithTooltip'
import { PageSubTitle } from '../../../components/title/PageSubTitle'
import TabContext from '@mui/lab/TabContext'
import TabPanel from '@mui/lab/TabPanel'
import TabList from '@mui/lab/TabList'

// Redux
import { useDispatch } from 'react-redux'
import { getStudentActivityByIdRequest } from '../../../store/activity/actions'
import { useAppSelector } from '../../../store/hooks'

// Styles
import styles from './style/pendingEssayMobile.module.scss'
import { useTheme } from '@mui/material/styles'

// Utils
import { getActivityId } from '../../../utils/functions'
import EssayUpload from '../../../components/imageUpload/activity/EssayUpload'


const ActivityPendingEssayMobileContainer = () => {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const theme = useTheme()
  const [tabValue, setTabValue] = useState('1')
  const path = location?.pathname
  const activityId = getActivityId(path)
  const { item, isFetching } = useAppSelector(state => state.activity)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const hasSecondSubmission = false

  useEffect(() => {
    activityId && dispatch(getStudentActivityByIdRequest({ id: activityId }))
  }, [activityId])

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue)
  }

  useEffect(() => {

    const imgElement = document.getElementById('alttext-image') as HTMLImageElement
    if (imgElement) {
      imgElement.onerror = () => {
        imgElement.src = ImageError
      }
    }
  }, [item.file_image])

  return (
    <div className={styles.container}>
      <Box alignItems='center' display='flex'>
        <Button className={styles.backButton} onClick={() => navigate(-1)}>
          <img height={50} src={LeftArrowIcon} />
          <span>Voltar</span>
        </Button>
      </Box>
      <div className={styles.label_container}>
        <GradeWithLabel
          gradeFontSize='14px'
          labelFontSize='18px'
          labelFontWeight={500}
          grade={item.answer.grade}
        />

        <a href={item.file_pdf} target='_blank' rel='noreferrer'><img src={downloadIcon} /> Atividade</a>
      </div>
      <div className={styles.card_container}>
        <MyActivityCardMobile isFetching={isFetching} item={item} />
      </div>
      <div className={styles.page_title_container}>
        <PageSubTitle>
          <img src={ActivityIcon} />
          Redação
        </PageSubTitle>
        <InformationIconWithTooltip info='' />
      </div>
      {
        isFetching ? (
          <Box mt='24px'>
            <LinearProgress />
          </Box>
        ) :
          <div className={styles.tab_container}>
            <TabContext value={tabValue}>
              <Box className={styles.tabs_container}
                sx={{
                  borderBottom: '2px solid rgba(224, 224, 224, 0.40)',
                  borderColor: 'divider'
                }}
              >
                <TabList
                  onChange={handleTabChange}
                  sx={{
                    '.Mui-selected': {
                      color: theme.palette.text.primary + '!important',
                      fontWeight: 600,
                      fontSize: '20px'
                    },
                    '.MuiTab-root': {
                      color: theme.palette.text.secondary,
                      fontWeight: 600,
                      fontSize: '20px'
                    },
                    '.MuiTabs-indicator': {
                      background: theme.palette.primary.light,
                    }
                  }}
                >
                  <Tab label='Atividade' value='1' />
                  <Tab label='Envio' value='2' />
                </TabList>
              </Box>
              <TabPanel sx={{ padding: 0, marginTop: '48px' }} value='1'>
                <Box
                  sx={{
                    img: {
                      maxWidth: '370px',
                    },
                  }}
                >
                  <img
                    id='alttext-image'
                    src={item.file_image ?? ImageError}
                    alt='Proposta da redação'
                  />
                </Box>
              </TabPanel>
              <TabPanel sx={{ padding: 0 }} value='2'>
                {
                  item.answer.file ? (
                    <Box sx={{
                      alignItems: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                      mt: '24px',
                      width: '100%',
                    }}
                    >
                      <Typography
                        fontSize='24px'
                        fontWeight={500}
                        color={theme.palette.text.primary}
                      >
                        Atividade já foi enviada.
                      </Typography>
                      <img src={EssaySentImage} />
                    </Box>
                  ) : (
                    <EssayUpload />
                  )
                }
              </TabPanel>
            </TabContext>

          </div>
      }
    </div>
  )
}

export default ActivityPendingEssayMobileContainer