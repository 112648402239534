import * as React from 'react'
import { FieldRenderProps } from 'react-final-form'
import { Box, InputAdornment, TextField, Typography } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

interface SearchInputProps {
  label?: string
  placeholder?: string
  disabled?: boolean
  name?: string
  width?: string
  variant?: 'filled' | 'standard' | 'outlined' | undefined
  backgroundColor?: string
  border?: string
  borderRadius?: string
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  value?: string | null
  className?: string
  inputType?: string
  isKeyDown?: boolean
  icon?: any
}

const SearchInput: React.FC<SearchInputProps> = ({
  label,
  name,
  className,
  placeholder,
  width = '100%',
  variant = 'outlined',
  backgroundColor = '#F2F5FF',
  border = 'none',
  borderRadius = '32px',
  onChange,
  value,
  inputType = 'text',
  isKeyDown,
  disabled,
  icon,
  ...rest
}) => {

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const regex = /^[0-9]\d*$/
    if (!regex.test(event.key) && event.key !== 'Backspace') {
      event.preventDefault()
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column', width: '100%',
        maxWidth: width,
        '& input::placeholder': {
          color: 'var(--palette-text-tertiary)'
        },
        '& input::-webkit-input-placeholder': {
          color: 'var(--palette-text-tertiary)',
          opacity: 1,
        },
        '& input::-moz-placeholder': {
          color: 'var(--palette-text-tertiary)',
          opacity: 1,
        }
      }}
    >
      {label && (
        <Typography
          variant='body2'
          component='label'
          sx={{
            mb: 1,
            fontSize: 14,
            fontWeight: 500
          }}
        >
          {label}
        </Typography>
      )}
      <TextField
        name={name}
        className={className}
        placeholder={placeholder}
        variant={variant}
        onChange={onChange}
        onKeyDown={isKeyDown ? handleKeyPress : undefined}
        value={value}
        inputProps={{ min: 0 }}
        type={inputType}
        disabled={disabled}
        sx={{
          'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
            display: 'none'
          },
          '& .MuiOutlinedInput-root': {
            backgroundColor: backgroundColor,
            borderRadius: borderRadius,
            height: 40,
            fontSize: 14,
            fontWeight: '500 !important',
            width: '100%',
            maxWidth: width,
            '& fieldset': {
              border: border,
            }
          }
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              {<img src={icon} /> ?? <SearchIcon sx={{ color: '#D9D9D9' }} />}
            </InputAdornment>
          ),
        }}
        {...rest}
      />
    </Box>
  )
}

export default SearchInput


type PropsField = FieldRenderProps<string, any>

export const SearchFieldInput: React.FC<PropsField> = ({
  input: { value, name, onChange, ...restInput },
  placeholder,
  label,
  className,
  width = '100%',
  variant = 'outlined',
  border = 'none',
  borderRadius = '32px',
  backgroundColor,
  fontSize = '12px',
  icon
}) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', maxWidth: width }}>
      {label &&
        <Typography
          sx={{
            mb: 1,
            fontSize: 14,
            fontWeight: '500 !important'
          }}
          variant='body2'
          component='label'
        >
          {label}
        </Typography>}
      <TextField
        name={name}
        className={className}
        placeholder={placeholder}
        variant={variant}
        onChange={onChange}
        value={value}
        sx={{
          '& .MuiOutlinedInput-root': {
            backgroundColor: backgroundColor,
            borderRadius: borderRadius,
            height: 40,
            fontSize: fontSize,
            width: '100%',
            maxWidth: width,
            '& fieldset': {
              border: border,
            }
          }
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              {<img src={icon} /> ?? <SearchIcon sx={{ color: '#D9D9D9' }} />}
            </InputAdornment>
          ),
        }}
        {...restInput}
      />
    </Box>
  )
}
