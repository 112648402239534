import React from 'react'

// Assets
import RightBottomCornerShape from '../../assets/home/card/right-bottom-corner-shape.svg'
import RightTopCornerShape from '../../assets/home/card/right-top-corner.svg'
import GradesImage from '../../assets/grades/grades-image.png'

// Components
import { Box, CircularProgress, Tooltip, Typography } from '@mui/material'

// Styles
import { useTheme, styled } from '@mui/material/styles'
import styles from './styles/textAvarage.module.scss'

// Utils
import { useDrawerContext } from '../../contexts/DrawerContext'

type IAverageScoreTabletCardProps = {
  average: number
  isFetching: boolean
}

const SeeAllNavigate = styled('a')(({ theme }) => ({
  background: theme.palette.primary.light,
  borderRadius: '56px',
  color: '#fff',
  cursor: 'pointer',
  display: 'block',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '120%',
  marginTop: '8px',
  padding: '15px 30px',
  width: 'fit-content',
  textDecoration: 'none',
  whiteSpace: 'nowrap'
}))

const AverageScoreTabletCard: React.FC<IAverageScoreTabletCardProps> = ({
  average,
  isFetching
}) => {
  const theme = useTheme()
  const { isDrawerOpen } = useDrawerContext()

  return (
    <Box
      sx={{
        display: 'flex',
        position: 'relative',
        borderRadius: '24px',
        maxWidth: 'fit-content',
        background: '#F2F5FF',
        height: '100%',
        maxHeight: '158px'
      }}
    >
      <Box
        sx={{
          alignItems: isDrawerOpen ? 'flex-end' : '',
          display: 'flex',
          background: '#E6D8FF',
          width: '100%',
          maxWidth: 'fit-content',
          borderTopLeftRadius: '24px',
          borderBottomLeftRadius: '24px',
          paddingTop: '12px',
          paddingInline: isDrawerOpen ? '10px' : '34px',
          justifyContent: 'center',
          'img': {
            height: isDrawerOpen ? '72%' : '100%',
            maxHeight: '146px'
          }
        }}
      >
        <img src={GradesImage} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          paddingLeft: '12px',
          paddingRight: isDrawerOpen ? '40px' : '73px',
          paddingBottom: '7px'
        }}
      >
        {
          isFetching ? (
            <Box
              sx={{
                display: 'flex',
                paddingTop: '12px',
                justifyContent: 'center',
                width: '100%'
              }}
            >
              <CircularProgress />
            </Box>
          ) :
            <>
              <Typography
                sx={{
                  color: 'var(--palette-text-tertiary)',
                  fontSize: '56px',
                  fontWeight: 700,
                  lineHeight: '120%',
                  textAlign: 'center'
                }}
              >
                {average}
              </Typography>
              <Typography
                sx={{
                  color: 'var(--palette-text-tertiary)',
                  fontSize: '16px',
                  fontWeight: 500,
                  lineHeight: '150%',
                  textAlign: 'center'
                }}
              >
                Nota média
              </Typography>
            </>
        }
        <SeeAllNavigate href='/notas'>Ver todas</SeeAllNavigate>
      </Box>
      <Box
        sx={{
          alignItems: 'flex-end',
          backgroundImage: `url(${RightBottomCornerShape})`,
          bottom: 0,
          display: 'flex',
          height: '48px',
          justifyContent: 'flex-end',
          position: 'absolute',
          right: 0,
          width: '50px',
          [theme.breakpoints.down(1240)]: {
            backgroundImage: `url(${RightTopCornerShape})`,
            top: 0,
            position: 'absolute',
            right: 0,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
            width: '45px',
            height: '45px'
          }
        }}
      >
        <Tooltip
          title='Consulte o histórico completo com as notas das suas redações'
        >
          <span className={styles.icon} />
        </Tooltip>
      </Box>
    </Box>
  )
}

export default AverageScoreTabletCard