import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// Assets
import LeftArrowIcon from '../../../assets/activities/left-arrow-icon.svg'
import downloadIcon from '../../../assets/activities/download-icon-purple.svg'
import ActivityIcon from '../../../assets/activities/essay-icon.svg'

// Components
import { Box, Button, Tab } from '@mui/material'
import GradeWithLabel from '../../../components/text/GradeWithLabel'
import MyActivityCardMobile from '../../../components/card/MyActivityCardMobile'
import InformationIconWithTooltip from '../../../components/tooltip/InformationIconWithTooltip'
import { PageSubTitle } from '../../../components/title/PageSubTitle'
import TabContext from '@mui/lab/TabContext'
import TabPanel from '@mui/lab/TabPanel'
import TabList from '@mui/lab/TabList'

// Redux
import { useDispatch } from 'react-redux'
import { getStudentActivityByIdRequest, getStudentActivityReWriteByIdRequest } from '../../../store/activity/actions'
import { useAppSelector } from '../../../store/hooks'
import CompetencesMobile from '../../../components/collapse/activity/CompetencesMobileContainer'
import EssayLabel from '../../../components/label/EssayLabel'

// Styles
import styles from './style/activityConcludedMobile.module.scss'
import { useTheme } from '@mui/material/styles'

// Utils
import { getActivityId } from '../../../utils/functions'
import ChatContainer from '../../../components/chat/ChatContainer'


const ActivityConcludedEssayMobileContainer = () => {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const theme = useTheme()
  const { item, isFetching, rewrite } = useAppSelector(state => state.activity)

  const [tabValue, setTabValue] = useState('1')

  const path = location?.pathname
  const activityId = getActivityId(path)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const hasSecondSubmission = false

  useEffect(() => {

    if (activityId) {
      dispatch(getStudentActivityByIdRequest({ id: activityId }))
    }
  }, [activityId, dispatch])

  useEffect(() => {
    if (item?.has_rewrite_id) {
      dispatch(getStudentActivityReWriteByIdRequest({ id: item.has_rewrite_id }))
    }
  }, [item?.has_rewrite_id, dispatch])

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue)
  }

  return (
    <div className={styles.container}>
      <ChatContainer />
      <Box alignItems='center' display='flex'>
        <Button className={styles.backButton} onClick={() => navigate(-1)}>
          <img height={50} src={LeftArrowIcon} />
          <span>Voltar</span>
        </Button>
      </Box>
      <div className={styles.label_container}>
        <GradeWithLabel
          gradeFontSize='14px'
          labelFontSize='18px'
          labelFontWeight={500}
          grade={tabValue === '1' ? item.answer.grade : rewrite.answer.grade}
        />

        <a href={item.file_pdf} target='_blank' rel='noreferrer'><img src={downloadIcon} /> Atividade</a>
      </div>
      <div className={styles.card_container}>
        <MyActivityCardMobile tabValue={tabValue} isFetching={isFetching} item={item} rewrite={rewrite} />
      </div>
      <div className={styles.page_title_container}>
        <PageSubTitle>
          <img src={ActivityIcon} />
          Redação
        </PageSubTitle>
        <InformationIconWithTooltip info='' />
      </div>
      <div className={styles.tab_container}>
        <TabContext value={tabValue}>
          <Box className={styles.tabs_container}
            sx={{
              borderBottom: '2px solid rgba(224, 224, 224, 0.40)',
              borderColor: 'divider'
            }}
          >
            <TabList
              onChange={handleTabChange}
              sx={{
                '.Mui-selected': {
                  color: theme.palette.text.primary + '!important',
                  fontWeight: 600,
                  fontSize: '20px'
                },
                '.MuiTab-root': {
                  color: theme.palette.text.secondary,
                  fontWeight: 600,
                  fontSize: '20px'
                },
                '.MuiTabs-indicator': {
                  background: theme.palette.primary.light,
                }
              }}
            >
              <Tab label='1º Envio' value='1' />
              {
                item.has_rewrite_id && <Tab label='2º Envio' value='2' />
              }
            </TabList>

          </Box>
          <TabPanel sx={{ padding: 0, marginTop: '48px' }} value='1'>
            <Box sx={{
              'img': {
                maxWidth: '370px'
              }
            }}>
              <img src={item.answer.file} />
            </Box>
            <EssayLabel />
            <div className={styles.competences_container}>
              <CompetencesMobile />
            </div>
          </TabPanel>
          <TabPanel sx={{ padding: 0, marginTop: '48px' }} value='2'>
            <Box sx={{
              'img': {
                maxWidth: '370px'
              }
            }}>
              <img src={rewrite.answer.file} />
            </Box>
            <EssayLabel />
            <div className={styles.competences_container}>
              <CompetencesMobile />
            </div>
          </TabPanel>
        </TabContext>

      </div>
    </div>
  )
}

export default ActivityConcludedEssayMobileContainer