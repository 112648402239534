import httpV2 from '../../utils/httpV2'

// Endpoints
const getStudentActivityListEndpoint = '/student/activity/list/'
const getStudentActivityEndpoint = '/student/activity'


export const getStudentActivityListService = (params: any) => {
  return httpV2.get(getStudentActivityListEndpoint, { params })
}

export const getStudentActivityByIdService = (params: { id: string }) => {
  return httpV2.get(`${getStudentActivityEndpoint}/${params.id}/`)
}

export const postStudentActivityService = (payload: any) => {
  return httpV2.post(`${getStudentActivityEndpoint}/${payload.id}/submit/`, payload.formData)
}

export const postStudentChatService = (payload: {activity_id: number, comment: string}) => {
  return httpV2.post(`${getStudentActivityEndpoint}/${payload.activity_id}/chat/`, { comment: payload.comment })
}