import React from 'react'
import { useLocation } from 'react-router-dom'

// Assets
import MenuIcon from '../../assets/header/menu-icon.svg'

// Components
import { Box } from '@mui/system'
import { IconButton, Typography } from '@mui/material'
import { useDrawerContext } from '../../contexts/DrawerContext'
import Notification from '../notification/Notification'
import PersonalDataDropDownMenu from '../dropDown/PersonalDataDropDownMenu'

// Styles
import { useTheme } from '@mui/material/styles'
import styles from './styles/header.module.scss'

const Header = () => {
  const { toggleDrawerOpen } = useDrawerContext()
  const theme = useTheme()
  const location = useLocation()
  const path = location?.pathname

  const extractPath = (path: string) => {
    const partes = path.split('/')
    return `/${partes[1]}`
  }

  let title = ''
  switch (true) {
    case path === '/':
      title = 'Home'
      break
    case path === '/painel-sucesso':
      title = 'Painel de Sucesso'
      break
    case path === '/notas':
      title = 'Notas'
      break
    case extractPath(path) === '/minhas-atividades':
      title = 'Minhas Atividades'
      break
    default:
      title = 'Verbo'
      break
  }

  return (
    <Box
      sx={{
        alignItems: 'center',
        background: '#fff',
        display: 'flex',
        height: '64px',
        paddingInline: '16px',
        position: 'fixed',
        width: '100%',
        zIndex: 999
      }}
      component='header'
    >
      <IconButton title='Abrir menu' onClick={toggleDrawerOpen}>
        <img src={MenuIcon} />
      </IconButton>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          width: '100%'
        }}
      >
        <Typography
          component='h1'
          sx={{
            color: theme.palette.text.primary,
            fontSize: '18px',
            flex: 1,
            fontWeight: 600,
            lineHeight: '22px',
            textAlign: 'center',
          }}
        >
          {title}
        </Typography>

        <div className={styles.container}>
          <Notification />
          <PersonalDataDropDownMenu />
        </div>
      </Box>
    </Box >
  )
}

export default Header