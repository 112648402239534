import React from 'react'

// Components
import { Skeleton } from '@mui/material'

// Redux
import { IActivity } from '../../store/activity/types'

// Styles
import styles from './styles/myActivityCardMobile.module.scss'

// Utils
import { format } from 'date-fns'
import { truncateText } from '../../utils/functions'

type IMyActivityCardMobileProps = {
  item: IActivity
  isFetching: boolean
  rewrite?: IActivity
  tabValue?: string
}

const MyActivityCardMobile: React.FC<IMyActivityCardMobileProps> = ({
  item,
  isFetching,
  rewrite,
  tabValue
}) => {

  if (isFetching) return <Skeleton height='390px' width='100%' sx={{ borderRadius: '20px' }} variant='rectangular' animation='wave' />

  return (
    <div className={styles.container}>
      <h2>{truncateText(item?.name, 80)}</h2>
      <div className={styles.theme_container}>
        <label>Tema</label>
        <p>{item?.subject}</p>
      </div>
      <div className={styles.classroom_container}>
        <div className={styles.classroom_text_wrapper}>
          <div id={styles.classroom}>Turma</div>
          <p>
            {
              tabValue === '1' ?
                item.answer.group_name
                : rewrite?.answer.group_name
            }
          </p>
        </div>
        <div className={styles.date_wrapper}>
          <label>Início</label>
          <p>
            {
              tabValue === '1' ?
                item?.start_datetime && format(item?.start_datetime, 'dd/MM/yyyy')
                : rewrite?.start_datetime && format(rewrite?.start_datetime, 'dd/MM/yyyy')
            }
          </p>
        </div>
      </div>
      <div className={styles.dates_container}>
        <div className={styles.end_date}>
          <label>Término</label>
          <p>
            {
              tabValue === '1' ?
                item.end_datetime && format(item.end_datetime, 'dd/MM/yyyy')
                : rewrite?.end_datetime && format(rewrite?.end_datetime, 'dd/MM/yyyy')
            }
          </p>
        </div>
        <div className={styles.first_submission}>
          <label>1ª Submissão</label>
          <p>
            {
              item?.answer?.submission_date && format(item?.answer?.submission_date, 'dd/MM/yyyy')
            }
          </p>
        </div>
        <div className={styles.second_submission}>
          <label>2ª Submissão</label>
          <p>
            {rewrite?.answer.submission_date && format(rewrite?.answer.submission_date, 'dd/MM/yyyy')}
          </p>
        </div>
      </div>
    </div>
  )
}

export default MyActivityCardMobile