import React, { useState } from 'react'

// Assets
import { ReactComponent as NotificationIcon } from '../../assets/home/notification-icon.svg'
import CloseCircledIcon from '../../assets/home/card/close-circled-red-icon.svg'

// Components
import NotificationItemCard from '../card/NotificationItemCard'
import {
  Divider,
  Menu,
  styled
} from '@mui/material'

// Style
import styles from './notification.module.scss'

// Utils
import { useTheme } from '@mui/material/styles'
import { truncateText } from '../../utils/functions'

type INotificationProps = {
  background?: string
}

const Button = styled('button')(() => ({}))

const Notification: React.FC<INotificationProps> = ({
  background
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const theme = useTheme()
  return (
    <>
      <Button
        id='notification-button'
        aria-controls={open ? 'notification-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className={styles.button}
        sx={{
          background: background
        }}
      >
        <div className={styles.notification_circle} />
        <NotificationIcon />
      </Button>
      <Menu
        id='notification-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'notification-button',
        }}
        sx={{

          [theme.breakpoints.up(905)]: {
            '.MuiPaper-root': {
              background: 'transparent'
            },
            '.MuiMenu-list': {
              paddingBlock: 0
            }
          }
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className={styles.notification_list_container}>
          <button
            onClick={handleClose}
            className={styles.close_icon}
          >
            <img src={CloseCircledIcon} />
          </button>
          <NotificationItemCard
            text={truncateText('Lorem Ipsum is simply dummy text of the printing and typesetting max caract:80', 80)}
            startDate='00/ 00/ 00'
            endDate='00/ 00/ 00'
          />
          <Divider />
          <NotificationItemCard
            text={truncateText('Lorem Ipsum is simply dummy text of the printing and typesetting max caract:80', 80)}
            startDate='00/ 00/ 00'
            endDate='00/ 00/ 00'
          />
        </div>
      </Menu>
    </>
  )
}

export default Notification