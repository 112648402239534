import React from 'react'

// Assets
import { ReactComponent as LogoutIcon } from '../../../assets/home/logout-icon.svg'

// Redux
import { SIGN_OUT_REQUEST } from '../../../store/auth/actions'
import { useDispatch } from 'react-redux'

// Style
import styles from './logoutButton.module.scss'
import { styled } from '@mui/material'

const Button = styled('button')(() => ({}))

type ILogoutButton = {
  background?: string
}

const LogoutButton: React.FC<ILogoutButton> = ({background}) => {
  const dispatch = useDispatch()
  return (
    <Button
    sx={{background: background}}
      className={styles.logout_icon}
      onClick={() => dispatch(SIGN_OUT_REQUEST())}
    >
      <LogoutIcon />
    </Button>
  )
}

export default LogoutButton