import React, { useState } from 'react'

// Components
import {
  Box,
  Pagination,
  styled,
  Typography,
} from '@mui/material'
import CustomTable, { Columns } from '../customTable/CustomTable';

// Style
import { useTheme } from '@mui/material/styles'
import styles from './gradesTable.module.scss'

// Utils
import { scrollToTop } from '../../../utils/functions';
import NoScoresCard from '../../card/NoScoresCard';

type IGuidedWritingTableProps = {
  rows?: any
}

const TableTextCell = styled(Box)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontFamily: 'Montserrat',
  fontSize: '18px',
  fontWeight: 500,
  lineHeight: '120%'
}))

export const rows = [
  {
    id: 1,
    titulo: 'O Papel da Tecnologia na Educação d...',
    banca: 'ENEM',
    correcao: '05/ 05/ 2024',
    nota: 1000,
    time: '00:00:00',
    rewritten: true
  },
  {
    id: 2,
    titulo: 'A Importância da Preservação Ambie...',
    banca: 'ENEM',
    correcao: '05/ 05/ 2024',
    nota: 800,
    time: '00:00:00',
    rewritten: false
  },
  {
    id: 3,
    titulo: 'Os Impactos da Globalização na Cult...',
    banca: 'ENEM',
    correcao: '05/ 05/ 2024',
    nota: 789,
    time: null,
    rewritten: false
  },
  {
    id: 4,
    titulo: 'A Crise dos Refugiados: Solidariedade...',
    banca: 'ENEM',
    correcao: '05/ 05/ 2024',
    nota: 789,
    time: null,
    rewritten: false
  },
  {
    id: 5,
    titulo: 'Os Desafios da Saúde Mental na Soci...',
    banca: 'ENEM',
    correcao: '05/ 05/ 2024',
    nota: 400,
    time: null,
    rewritten: false
  },
  {
    id: 6,
    titulo: 'Educação Inclusiva: Rumo a uma Soc...',
    banca: 'ENEM',
    correcao: '05/ 05/ 2024',
    nota: 379,
    time: null,
    rewritten: false
  }
]

const GradesTable: React.FC<IGuidedWritingTableProps> = () => {
  const theme = useTheme()

  const getBackgroundColor = (score: number) => {
    let background
    if (score >= 900) {
      background = '#7EEB89'
    } else if (score >= 700 && score < 900) {
      background = theme.palette.text.primary
    } else if (score >= 400 && score < 700) {
      background = '#F6BB8C'
    } else if (score < 400) {
      background = theme.palette.error.main
    }
    return background
  }

  const columns: Columns[] = [
    {
      key: 'title',
      name: 'Título da atividade',
      width: '45%',
      align: 'left',
      render: (row: any) => {
        return <TableTextCell>
          {row.titulo}
        </TableTextCell>
      }
    },
    {
      key: 'banca',
      name: 'Banca',
      width: '10%',
      align: 'left',
      render: (row: any) => {
        return <TableTextCell>
          {row.banca}
        </TableTextCell>
      }
    },
    {
      key: 'correcao',
      name: 'Correção',
      width: '15%',
      align: 'center',
      render: (row: any) => {
        return <TableTextCell>
          {row.correcao ?? '-'}
        </TableTextCell>
      }
    },
    {
      key: 'score',
      name: 'Nota',
      width: '5%',
      align: 'center',
      render: (row: any) => {
        return <TableTextCell
          sx={{
            background: row.nota ? getBackgroundColor(row.nota) : 'transparent',
            borderRadius: '40px',
            color: row.nota ? '#fff' : theme.palette.text.primary,
            padding: '8px 16px',
            textAlign: 'center'
          }}
        >
          {row.nota ?? '-'}
        </TableTextCell>
      }
    },
    {
      key: 'time',
      name: 'Tempo',
      width: '5%',
      align: 'center',
      render: (row: any) => {
        return <TableTextCell>
          {row.time ?? '-'}
        </TableTextCell>
      }
    },
  ]

  const [page, setPage] = useState(1)

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
    // dispatch(action({ param: dummy, page: value }))
    scrollToTop()
  }

  return (
    <Box>
      {
        (rows.length > 0)
          ? <>
            <CustomTable
              columns={columns}
              data={rows}
              customBackground='#E6D8FF'
            />
            <div className={styles.label_container}>
              <div className={styles.rewritten_label} />
              <Typography className={styles.rewritten_text}>
                Reescrita
              </Typography>
            </div>
            <Pagination
              count={20}
              size='small'
              color='primary'
              page={page}
              onChange={handleChangePage}
              sx={{
                mt: '8px',
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            />
          </> : <NoScoresCard />
      }
    </Box>
  )
}

export default GradesTable