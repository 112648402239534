import { all, call, takeLatest, put, delay } from 'redux-saga/effects'
import { DefaultAction } from '../../utils/types'
import { getUserDataService } from './services'
import { AuthCredentials, SignInCredentials } from './types'
import * as actions from './actions'
import { TakeableChannel } from 'redux-saga'
import { AxiosResponse } from 'axios'
import { persistor } from '../configureStore'
import * as services from './services'
import { getVerboUserDataService } from '../authVerbo/services'
import { getVerboUserDataRejected, getVerboUserDataRequest, getVerboUserDataSuccess } from '../authVerbo/actions';
import { toast } from 'react-toastify'

export function* getUserData({ payload }: any) {
  try {
    const response: AxiosResponse<any> = yield call(getUserDataService)
    const AuthResponse: AxiosResponse<any> = yield call(getVerboUserDataService)
    yield put(getVerboUserDataRequest())
    if (AuthResponse.status === 200) {
      yield put(actions.getUserDataSuccess(response?.data))
      yield put(getVerboUserDataSuccess(AuthResponse?.data))

      if (payload.navigate) {
        payload.navigate('/')
      }
    } else {
      toast.error("Erro ao pegar informações.")
      yield delay(2000)
      yield put(getVerboUserDataRejected())
      yield put(actions.SIGN_OUT_REQUEST())
    }
  } catch (error: any) {
    if (error.response) {
      const statusCode = error?.response?.status
      if (statusCode === 401) {
        toast.error("Não autorizado.")
      }
    }
    yield delay(2000)
    yield put(actions.SIGN_OUT_REQUEST())
    yield put(actions.getUserDataRejected())
  }
}

export function* signIn({ payload }: DefaultAction<SignInCredentials>): any {
  const { history, ...rest } = payload
  try {
    const response = yield call(services.signIn, rest)
    const {
      headers,
      data: {
        data: { attributes, id }
      }
    } = response
    yield put(actions.updateCredentialsRequest({ ...headers }))
    if (attributes.profiles.length === 0) {
      yield put(actions.SIGN_OUT_REQUEST())
      history.push('/')
    } else {
      yield put(actions.SIGN_IN_SUCCESSFUL({ ...attributes, id }))
    }
  } catch (e: unknown) {
    yield put(actions.SIGN_IN_FAILURE(e))
    history.push('/')
  }
}

export function* signOut() {
  try {
    yield call(services.signOut)
    yield put({ type: 'app:RESET_STORE' })
    localStorage.clear()
    yield put(actions.SIGN_OUT_SUCCESS())
    window.location.replace('/')
  } catch (error) {
    yield put({ type: 'app:RESET_STORE' })
    yield put(actions.SIGN_OUT_FAILURE())
  } finally {
    persistor.pause()
    persistor.flush().then(() => {
      return persistor.purge()
    })
  }
}

export function* updateCredentials(
  { payload }: DefaultAction<AuthCredentials>
) {
  try {
    const { token } = payload
    yield put(actions.updateCredentialsSuccess({ token }))
  } catch (e) {
    yield put(actions.updateCredentialsFailure())
  }
}

// Watchers

export function* watchSignIn() {
  yield takeLatest(actions.SIGN_IN.type as unknown as TakeableChannel<unknown>, signIn)
}
export function* watchGetUserData() {
  yield takeLatest(actions.getUserDataRequest.type as unknown as TakeableChannel<unknown>, getUserData)
}

export function* watchAuth() {
  yield takeLatest(actions.updateCredentialsRequest.type as unknown as TakeableChannel<unknown>, updateCredentials)
  yield takeLatest(actions.SIGN_OUT_REQUEST, signOut)
}

export default function* authSagas() {
  yield all([
    watchSignIn(),
    watchAuth(),
    watchGetUserData()
  ])
}
