/* eslint-disable @typescript-eslint/no-unused-vars */
import { styled } from '@mui/material/styles'

export const PageMainTitle = styled('h1')(({ theme }) => ({
  alignItems: 'center',
  color: 'var(--palette-text-tertiary)',
  display: 'flex',
  fontFamily: 'Montserrat',
  fontSize: '32px',
  fontWeight: 700,
  gap: '10px',
  lineHeight: '120%',
}))