import { Box } from '@mui/material';
import React from 'react';
import BackButton from '../button/BackButton';
import { useTheme } from '@mui/material/styles'
interface Props {
  text: string
  backTo: string
}

const NavigationTitleWithBackButton: React.FC<Props> = ({
  text,
  backTo
}) => {
  const theme = useTheme()
  const parts = text.split('/').map((part: string) => part.trim())

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        gap: '32px',
        marginTop: '55px',
        marginBottom: '58px'
      }}
    >
      <BackButton navigateTo={backTo} />
      <span>
        {
          parts.map((part: string, index: number) => (
            <React.Fragment key={index}>
              <span
                style={{
                  color: index === parts.length - 1 ? theme.palette.text.primary : '#BEBEBE',
                  fontSize: '18px',
                  fontWeight: 500,
                  marginRight: '4px'
                }}
              >
                {part}
              </span>
              {
                index < parts.length - 1 && <span
                  style={{
                    color: '#BEBEBE',
                    marginRight: '4px'
                  }}
                >/</span>
              }
            </React.Fragment>
          ))
        }
      </span>
    </Box>
  )
}

export default NavigationTitleWithBackButton
