/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react'

// Assets
import CloudIcon from '../../assets/addNewTheme/cloud-icon.svg'
import CloseIcon from '../../assets/addNewTheme/close-icon.svg'

// Components
import { Box, Typography, IconButton, styled, Button, Tooltip } from '@mui/material';

// Style
import { useTheme } from '@mui/material/styles'
import styles from './imageUpload.module.scss'
// Utils
import { bytesToMB } from '../../utils/functions'

type IImageUpload = {
  setImage: any
  maxWidth?: string
  imageData?: any
  isEssay?: boolean
}

export const ContainerUpload = styled(Box)(() => ({
  alignItems: 'center',
  background: '#F2F5FF',
  borderRadius: '20px',
  display: 'flex',
  flexDirection: 'column',
  paddingInline: '16px',
  maxWidth: '743px',
  width: '100%',
  'img': {
    borderRadius: '16px'
  }
}))
export const ContainerWithImage = styled(Box)(() => ({
  alignItems: 'flex-start',
  background: '#F2F5FF',
  borderRadius: '20px',
  display: 'flex',
  flexDirection: 'column',
  paddingInline: '16px',
  width: '100%',
  'img': {
    borderRadius: '16px'
  }
}))

const ImageUpload: React.FC<IImageUpload> = ({
  isEssay,
  setImage,
  maxWidth,
  imageData
}) => {
  const theme = useTheme()
  const [fileImage, setFileImage] = useState(false)

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      setImage(file)
      setFileImage(true)
    }
  }

  const handleRemoveImage = () => {
    setImage(null)
    setFileImage(false)
  }

  return (
    <Box width={'100%'} display='flex' flexDirection='column' >
      {!imageData ? (
        <ContainerUpload
          sx={{
            paddingBlock: '67px',
            maxWidth: maxWidth
          }}
        >
          <input
            accept='image/png, image/jpeg, application/pdf'
            style={{ display: 'none' }}
            id='upload-button'
            type='file'
            onChange={handleImageUpload}
          />
          <label htmlFor='upload-button'>
            <IconButton component='span'>
              <Box sx={{
                alignItems: 'center',
                display: 'flex',
                background: theme.palette.primary.light,
                padding: '15px 12px',
                borderRadius: '50%'
              }}>
                <img src={CloudIcon} />
              </Box>
            </IconButton>
          </label>
          <Typography mt={2} fontSize='18px'>
            Escolha o arquivo para upload
          </Typography>
          <Typography mt={1} fontSize='14px' color='textSecondary'>
            PNG, JPG e PDF / Tamanho máx. 60MB
          </Typography>
        </ContainerUpload>
      ) : (
        <>
          {
            isEssay ? (
              <ContainerWithImage
                sx={{
                  paddingBlock: !fileImage ? '67px' : '16px',
                  position: 'relative',
                }}
              >
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    gap: '15px',
                    width: '100%'
                  }}
                >
                  <img src={URL.createObjectURL(imageData)} alt='Uploaded' style={{ width: '100%' }} />
                </Box>
              </ContainerWithImage>
            ) : (
              <ContainerWithImage
                sx={{
                  paddingBlock: !fileImage ? '67px' : '16px',
                  position: 'relative',
                  maxWidth: maxWidth
                }}
              >
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    gap: '15px'
                  }}
                >
                  <img src={URL.createObjectURL(imageData)} alt='Uploaded' style={{ maxWidth: '183px' }} />
                  <Box>
                    <Typography className={styles.file_name}>
                      {imageData?.name}
                    </Typography>
                    <Typography className={styles.file_size}>
                      {bytesToMB(imageData?.size)} MB
                    </Typography>
                  </Box>
                </Box>
                <Tooltip title='Remover'>
                  <Button
                    sx={{
                      margin: 0,
                      padding: 0,
                      position: 'absolute',
                      top: '16px',
                      right: '16px',
                      minWidth: '18px'
                    }}
                    onClick={handleRemoveImage}
                  >
                    <img src={CloseIcon} />
                  </Button>
                </Tooltip>
              </ContainerWithImage>
            )
          }
        </>
      )}
    </Box>
  )
}

export default ImageUpload