import React, { useState } from 'react'

import Photo from '../../../assets/other/squirrel.jpg'

// Components
import { Avatar, Box, Pagination, styled } from '@mui/material'
import CustomTable, { Columns } from '../customTable/CustomTable';

// Style
import { useTheme } from '@mui/material/styles'

// Utils
import { scrollToTop, stringAvatar } from '../../../utils/functions';

type IGuidedWritingTableProps = {
  rows?: any
}

const TableTextCell = styled(Box)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontFamily: 'Montserrat',
  fontSize: '18px',
  fontWeight: 500,
  lineHeight: '120%'
}))

export const rows = [
  {
    id: 1,
    name: 'Gabriel Carlos da Silva Leite',
    banca: 'ENEM',
    correcao: '05/ 05/ 2024',
    turma: '2º Ano B',
    nota: 1000,
    photo: Photo,
    ranking: 1
  },
  {
    id: 2,
    name: 'Pedro Henrique Souza',
    banca: 'ENEM',
    correcao: '05/ 05/ 2024',
    turma: '2º Ano A',
    nota: 998,
    ranking: 2
  },
  {
    id: 3,
    name: 'Maria Clara Santos',
    banca: 'ENEM',
    correcao: '05/ 05/ 2024',
    turma: '3º Ano B',
    nota: 980,
    ranking: 3
  },
  {
    id: 4,
    name: 'Julia Almeida Pereira',
    banca: 'ENEM',
    correcao: '05/ 05/ 2024',
    turma: '3º Ano B',
    nota: 670,
    ranking: 4
  },
  {
    id: 5,
    name: 'Gabriel Moreira Fernandes',
    banca: 'ENEM',
    correcao: '05/ 05/ 2024',
    turma: '3º Ano A',
    nota: 650,
    ranking: 5
  },
  {
    id: 6,
    name: 'Beatriz Costa Lima',
    banca: 'ENEM',
    correcao: '05/ 05/ 2024',
    turma: '3º Ano B',
    nota: 390,
    ranking: 6
  },
]

const SuccessPanelTable: React.FC<IGuidedWritingTableProps> = () => {
  const theme = useTheme()

  const getBackgroundColor = (score: number) => {
    let background
    if (score >= 900) {
      background = '#7EEB89'
    } else if (score >= 700 && score < 900) {
      background = theme.palette.text.primary
    } else if (score >= 400 && score < 700) {
      background = '#F6BB8C'
    } else if (score < 400) {
      background = theme.palette.error.main
    }
    return background
  }

  const columns: Columns[] = [
    {
      key: 'photo',
      name: '',
      width: '5%',
      align: 'left',
      render: (row: any) => {
        return <Box
          sx={{
            position: 'relative',
          }}
        >
          <Avatar
            alt={row.name}
            variant='circular'
            src={row.photo}
            {...stringAvatar(row.name, 64, 64)}
          />
          <Box
            sx={{
              display: 'flex',
              background: row.ranking <= 3 ? '#7EEB89' : theme.palette.primary.light,
              borderRadius: '50%',
              bottom: '-2px',
              color: row.ranking <= 3 ? theme.palette.text.primary : '#fff',
              height: '32px',
              justifyContent: 'center',
              left: '-16px',
              position: 'absolute',
              padding: '8px 11px',
              width: '32px',
            }}
          >
            {row.ranking}
          </Box>
        </Box>
      }
    },
    {
      key: 'aluno',
      name: 'Alunos',
      width: '45%',
      align: 'left',
      render: (row: any) => {
        return <TableTextCell>
          {row.name}
        </TableTextCell>
      }
    },
    {
      key: 'banca',
      name: 'Banca',
      width: '10%',
      align: 'left',
      render: (row: any) => {
        return <TableTextCell>
          {row.banca}
        </TableTextCell>
      }
    },
    {
      key: 'correction',
      name: 'Correção',
      width: '15%',
      align: 'left',
      render: (row: any) => {
        return <TableTextCell>
          {row.correcao}
        </TableTextCell>
      }
    },
    {
      key: 'classroom',
      name: 'Turma',
      width: '15%',
      align: 'left',
      render: (row: any) => {
        return <TableTextCell>
          {row.turma}
        </TableTextCell>
      }
    },
    {
      key: 'nota',
      name: 'Nota',
      width: '5%',
      align: 'left',
      render: (row: any) => {
        return <TableTextCell
          sx={{
            background: getBackgroundColor(row.nota),
            borderRadius: '40px',
            color: '#fff',
            padding: '8px 16px',
            textAlign: 'center'
          }}
        >
          {row.nota}
        </TableTextCell>
      }
    },

  ]

  const [page, setPage] = useState(1)

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
    // dispatch(action({ param: dummy, page: value }))
    scrollToTop()
  }

  return (
    <Box
      sx={{
        '& tr:nth-of-type(1)': {
          '& > td': {
            background: '#F4FBE6'
          },
        },
        '& tr:nth-of-type(2)': {
          '& > td': {
            background: '#F4FBE6'
          },
        },
        '& tr:nth-of-type(3)': {
          '& > td': {
            background: '#F4FBE6'
          },
        },
        '& td': {
          '&:first-of-type': {
            borderRadius: '0px 0 0 0px !important',
            background: '#fff !important'
          },
          '&:nth-of-type(2)': {
            borderRadius: '20px 0 0 20px !important',
          }
        },
        '& .MuiTableCell-root': {
          border: 'none'
        }
      }}
    >
      <CustomTable
        columns={columns}
        data={rows}
      />
      <Pagination
        count={20}
        size='small'
        color='primary'
        page={page}
        onChange={handleChangePage}
        sx={{
          mt: '8px',
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      />
    </Box>
  )
}

export default SuccessPanelTable