import React from 'react'

// Assets
import TextSentIcon from '../../assets/home/card/textsSent.svg'
import TextCorrectedIcon from '../../assets/home/card/corrected-text-icon.svg'
import TextInCorrectionIcon from '../../assets/home/card/in-correction-text-icon.svg'
import TextPendingIcon from '../../assets/home/card/pending-text-icon.svg'

// Components
import {
  Box,
  Typography
} from '@mui/material'
import InformationTopRight from '../tooltip/InformationTopRight'

type ITextsProductionCardMobileProps = {
  count: number
  status: string
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TextsProductionCardMobile: React.FC<ITextsProductionCardMobileProps> = ({ count, status }) => {

  const getCardTitle = () => {
    switch (status) {
      case 'sent':
        return 'Enviadas'
      case 'corrected':
        return 'Corrigidas'
      case 'in_correction':
        return 'Em correção'
      case 'pending':
        return 'Pendentes'
      default:
        return 'Sem dados'
    }
  }

  const getCardIcon = () => {
    if (status === 'sent') {
      return <img src={TextSentIcon} />
    } else if (status === 'corrected') {
      return <img src={TextCorrectedIcon} />
    } else if (status === 'in_correction') {
      return <img src={TextInCorrectionIcon} />
    } else if (status === 'pending') {
      return <img src={TextPendingIcon} />
    }
  }

  const getCardBackgroundColor = () => {
    switch (status) {
      case 'sent':
        return '#F2F5FF'
      case 'corrected':
        return '#F4FBE6'
      case 'in_correction':
        return '#F2ECFC'
      case 'pending':
        return '#FFF6EE'
      default:
        return '#F2F5FF'
    }
  }

  const getTooltipText = () => {
    switch (status) {
      case 'sent':
        return 'Este é o número de redações que você já enviou para correção. Continue praticando!'
      case 'corrected':
        return 'Este número indica o total de redações que já foram corrigidas.'
      case 'in_correction':
        return 'Este é o número de redações que estão em processo de correção. Fique de olho no retorno!'
      case 'pending':
        return 'Este número indica quantas redações pendentes você ainda precisa enviar.'
      default:
        return 'Solicite informação ao suporte.'
    }
  }

  return (
    <Box
      sx={{
        background: getCardBackgroundColor(),
        height: 'fit-content',        
        width: '140px',
        borderRadius: '24px',
        position: 'relative',
        padding: '35px 10px 15px 20px'
      }}
    >
      <InformationTopRight info={getTooltipText()} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            borderBottom: '2px solid #434343',
            '& img': {
              marginBottom: '16px'
            }

          }}
        >
          {getCardIcon()}
        </Box>
        <Box
          sx={{
            marginTop: '16px'
          }}
        >
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 500,
              lineHeight: '150%',
              whiteSpace: 'nowrap'
            }}
          >
            {getCardTitle()}
          </Typography>
          <Typography
            sx={{
              fontSize: '48px',
              fontWeight: 700,
              lineHeight: '120%'
            }}
          >
            {count ?? '000'}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default TextsProductionCardMobile