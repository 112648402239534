import React from 'react'
import { Skeleton } from '@mui/material'
import { useTheme } from '@mui/material/styles'

const CardLoading = () => {

  const theme = useTheme()
  return (
    <Skeleton
      variant='rectangular'
      animation='wave'
      sx={{
        height: '118px',
        maxWidth: '345px',
        width: '100%',
        borderRadius: '24px',
        [theme.breakpoints.between(905, 1240)]: {
          'img': {
            height: '40px'
          },
          maxWidth: '198px',

        }
      }}
    />
  )
}

export default CardLoading