import React from 'react'
import { Box, Tooltip } from '@mui/material'
import styles from './styles/informationIconWithTootip.module.scss'

type IInformationIconWithTooltipProps = {
  info: string
}

const InformationIconWithTooltip: React.FC<IInformationIconWithTooltipProps> = ({
  info
}) => {
  return (
    <Box display='flex'>
      <Tooltip
        title={info}
      >
        <span className={styles.icon} />
      </Tooltip>
    </Box>
  )
}

export default InformationIconWithTooltip