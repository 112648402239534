import React from 'react'

import NoScoresImage from '../../assets/grades/no-scores-image.png'
import styles from './styles/noScores.module.scss'

const NoScoresCard = () => {
  return (
    <div className={styles.no_scores}>
      <img src={NoScoresImage} />
      <h2>Nenhuma nota disponível no momento.</h2>
      <h3>As suas notas aparecerão aqui assim que forem cadastradas.</h3>
    </div>
  )
}

export default NoScoresCard