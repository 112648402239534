import React from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles'

declare module '@mui/material/styles' {
  interface Components {
    [key: string]: any
  }
  interface Theme {
    status: {
      danger: string
    }
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string
    }
  }
}

export const themeDefault = createTheme({
  typography: {
    fontFamily: 'Montserrat, sans-serif',
    h5: {
      fontWeight: 600,
      fontSize: 18
    }
  },
  palette: {
    primary: {
      main: process.env.REACT_APP_PROJECT_COLOR_PRIMARY || '#3E02A3',
      light: process.env.REACT_APP_PROJECT_COLOR_PRIMARY_VARIANT || '#8842FE'
    },
    secondary: {
      main: process.env.REACT_APP_PROJECT_COLOR_SECONDARY || '#45CFEF',
      light: process.env.REACT_APP_PROJECT_COLOR_SECONDARY_VARIANT || '#90EAFF'
    },
    error: {
      main: process.env.REACT_APP_PROJECT_COLOR_ERROR_PRIMARY || '#EF7B8E',
      light: process.env.REACT_APP_PROJECT_COLOR_ERROR_SECONDARY || '#F9A1A6'
    },
    text: {
      primary: process.env.REACT_APP_PROJECT_COLOR_TEXT_PRIMARY || '#3F3E3E',
      secondary: process.env.REACT_APP_PROJECT_COLOR_TEXT_SECONDARY || '#BEBEBE'
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 906,
      lg: 1241,
      xl: 1440
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: 'none'
        }
      }
    },
    MuiCalendarPicker: {
      styleOverrides: {
        viewTransitionContainer: {
          height: 250,
          overflowY: 'hidden'
        }
      }
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          '& Mui-selected': {
            background: process.env.REACT_APP_PROJECT_COLOR_SECONDARY || '#04AEC9',
          }
        }
      }
    },
    MuiPagination: {
      styleOverrides: {
        ul: {
          '& .MuiPaginationItem-previousNext': {
            backgroundColor: '#d9d9d966'
          }
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: '7px',
          height: 6,
          '& .MuiLinearProgress-bar': {
            borderRadius: '7px'
          }
        }
      }
    }
  }
})

const root = document.documentElement;
root.style.setProperty('--palette-primary-main', themeDefault.palette.primary.main)
root.style.setProperty('--palette-primary-light', themeDefault.palette.primary.light)
root.style.setProperty('--palette-secondary-main', themeDefault.palette.secondary.main)
root.style.setProperty('--palette-secondary-light', themeDefault.palette.secondary.light)
root.style.setProperty('--palette-error-main', themeDefault.palette.error.main)
root.style.setProperty('--palette-error-light', themeDefault.palette.error.light)
root.style.setProperty('--palette-text-primary', themeDefault.palette.text.primary)
root.style.setProperty('--palette-text-secondary', themeDefault.palette.text.secondary)
root.style.setProperty('--palette-text-tertiary', '#434343')
root.style.setProperty('--palette-text-quaternary', '#272727')
root.style.setProperty('--breakpoints-values-xs', themeDefault.breakpoints.values.xs.toString())
root.style.setProperty('--breakpoints-values-sm', themeDefault.breakpoints.values.sm.toString())
root.style.setProperty('--breakpoints-values-md', themeDefault.breakpoints.values.md.toString())
root.style.setProperty('--breakpoints-values-lg', themeDefault.breakpoints.values.lg.toString())
root.style.setProperty('--breakpoints-values-xl', themeDefault.breakpoints.values.xl.toString())

export default function GlobalTheme({ children }: { children: React.ReactNode }) {
  return (
    <ThemeProvider theme={themeDefault}>
      {children}
    </ThemeProvider>
  )
}
