import React, { useState } from 'react'

// Assets
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import TeacherAvatar from '../../../assets/activities/teacher-avatar.png'

// Components
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Typography } from '@mui/material'

// Styles
import styles from './styles/singleCompetenceMobile.module.scss'
import { styled } from '@mui/material/styles'
import { getBackgroundColor } from '../../../utils/functions'

const AccordionTitle = styled(Typography)(() => ({
  fontSize: '18px',
  fontWeight: 600,
  lineHeight: '120%',
  marginBottom: '25px'
}))
const ScoreText = styled(Typography)(() => ({
  fontSize: '24px',
  fontWeight: 600,
  lineHeight: '120%'
}))
const LabelText = styled(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '120%',
  gap: '4px'
}))
const PercentageChip = styled(Typography)(() => ({
  borderRadius: '40px',
  fontWeight: 500,
  lineHeight: '120%',
  padding: '8px 16px',
}))

const SingleCompetenceMobile = () => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false)

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        '.MuiAccordion-root': {
          padding: '29px 16px !important'
        },
        '.MuiAccordion-root:first-of-type': {
          borderTopLeftRadius: '20px !important',
          borderTopRightRadius: '20px !important',
        },
        '.MuiAccordion-root:last-of-type': {
          borderBottomLeftRadius: '20px !important',
          borderBottomRightRadius: '20px !important',
        },
        '.MuiAccordion-root.Mui-expanded': {
          margin: 0
        },
        '.MuiAccordion-root::before': {
          display: 'none'
        },
        '.MuiAccordionDetails-root': {
          padding: 0
        },
        '.Mui-expanded': {
          marginTop: 0
        }
      }}

      className={styles.container}>
      <Accordion
        expanded={isAccordionOpen}
        onChange={() => setIsAccordionOpen((prev) => !prev)}
        sx={{
          background: getBackgroundColor(1).competenceBackground,
          borderRadius: '20px',
          '.MuiAccordionSummary-root': {
            alignItems: 'flex-start',
            paddingInline: 0
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1-content'
          id='panel1-header'
          sx={{
            '.MuiAccordionSummary-content': {
              alignItems: 'flex-start',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              border: 'none',
              margin: 0,
              '> div:first-of-type': {
                margin: '0px !important'
              }
            }
          }}
        >
          <AccordionTitle>Competencia 1</AccordionTitle>
          {
            !isAccordionOpen && <Box className={styles.accordionSummary_container} sx={{

            }}>
              <Box display='flex'>
                <Box className={styles.submission_container}>
                  <Box className={styles.flex_gap_1}>
                    <LabelText>1º Envio:</LabelText>
                    <LabelText className={styles.flex_gap_2}>
                      <ScoreText>{500}</ScoreText> de 200
                    </LabelText>
                  </Box>
                  <PercentageChip
                    bgcolor={getBackgroundColor(1).percentageBackground}
                  >
                    {90}%
                  </PercentageChip>
                </Box>
              </Box>
              <Box display='flex'>
                <Box className={styles.submission_container}>
                  <Box className={styles.flex_gap_1}>
                    <LabelText>2º Envio:</LabelText>
                    <LabelText className={styles.flex_gap_2}>
                      <ScoreText>{500}</ScoreText> de 200
                    </LabelText>
                  </Box>
                  <PercentageChip
                    bgcolor={getBackgroundColor(1).percentageBackground}
                  >
                    {90}%
                  </PercentageChip>
                </Box>
              </Box>
            </Box>
          }
        </AccordionSummary>
        <AccordionDetails>
          <LabelText mb='8px'>1º Envio:</LabelText>
          <Box className={styles.label_container}>
            <Box alignItems='center' display='flex'>
              <Avatar
                src={TeacherAvatar}
                sx={{
                  height: 48,
                  marginRight: 1,
                  width: 48,
                }}
              />
              <LabelText className={styles.flex_gap_2}>
                <ScoreText>{500}</ScoreText> de 200
              </LabelText>
            </Box>
            <PercentageChip
              bgcolor={getBackgroundColor(1).percentageBackground}
            >
              {90}%
            </PercentageChip>
          </Box>
          <Box className={styles.teacher_feedback_container}>
            <Typography className={styles.feedback_text}>
              Trata-se de repertório em que se utilizam informações, fatos, situações e experiências vividas SEM respaldo nas Áreas do Conhecimento (científicas ou culturais). Como não há problemas de adequação ao tipo dissertativo-argumentativo, será a análise do repertório utilizado para desenvolver o tema que definirá o nível de avaliação desse texto. Nesse sentido, o participante utiliza informações que extrapolam as ideias dos textos motivadores, mas que não são fundamentadas pelas Áreas do Conhecimento. Veja os exemplos:
            </Typography>
            <Box
              bgcolor={getBackgroundColor(1).competenceSecondSentBackground}
              className={styles.second_feedback_container}
            >
              <Box className={styles.label_container}>
                <Box alignItems='center' display='flex'>
                  <Avatar
                    src={TeacherAvatar}
                    sx={{
                      height: 48,
                      marginRight: 1,
                      width: 48,
                    }}
                  />
                  <LabelText className={styles.flex_gap_2}>
                    <ScoreText>{500}</ScoreText> de 200
                  </LabelText>
                </Box>
                <PercentageChip
                  bgcolor={getBackgroundColor(1).percentageBackground}
                >
                  {90}%
                </PercentageChip>
              </Box>
              <Typography className={styles.feedback_text}>
                Trata-se de repertório em que se utilizam informações, fatos, situações e experiências vividas SEM respaldo nas Áreas do Conhecimento (científicas ou culturais). Como não há problemas de adequação ao tipo dissertativo-argumentativo, será a análise do repertório utilizado para desenvolver o tema que definirá o nível de avaliação desse texto. Nesse sentido, o participante utiliza informações que extrapolam as ideias dos textos motivadores, mas que não são fundamentadas pelas Áreas do Conhecimento. Veja os exemplos:
              </Typography>
            </Box>
          </Box>

        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default SingleCompetenceMobile
