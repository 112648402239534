import React from 'react'
import { Box, Skeleton } from '@mui/material'

const TableLoading = () => {

  return (

    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '8px'
      }}
    >
      <Skeleton
        variant='rectangular'
        animation='wave'
        sx={{
          height: '70px',
          width: '100%',
          borderRadius: '20px'
        }}
      />
      <Skeleton
        variant='rectangular'
        animation='wave'
        sx={{
          height: '70px',
          width: '100%',
          borderRadius: '20px'
        }}
      />
      <Skeleton
        variant='rectangular'
        animation='wave'
        sx={{
          height: '70px',
          width: '100%',
          borderRadius: '20px'
        }}
      />
      <Skeleton
        variant='rectangular'
        animation='wave'
        sx={{
          height: '70px',
          width: '100%',
          borderRadius: '20px'
        }}
      />
      <Skeleton
        variant='rectangular'
        animation='wave'
        sx={{
          height: '70px',
          width: '100%',
          borderRadius: '20px'
        }}
      />
    </Box>
  )
}

export default TableLoading