import React from 'react'
import HandWriting from '../../assets/loginPage/hand-writing-mobile.png'
import styles from './styles/LoginPageStyleMobile.module.scss'

type INonLoggedMobileContainerProps = {
  url: string
  LogoFull?: string
}

const NonLoggedMobileContainer: React.FC<INonLoggedMobileContainerProps> = ({
  LogoFull,
  url
}) => {
  return (
    <div className={styles.container}>
      <img className={styles.logo} src={LogoFull} alt='Logo' />
      <img className={styles.image} src={HandWriting} alt='Mão escrevendo em um caderno com uma caneta' />
      <h1>
        Bem-vindo ao Verbo
      </h1>
      <p>
        Uma abordagem mais completa na correção de redações com análises detalhadas e relatórios estratégicos que permitem a criação de planos de ação personalizados. É correção orientada para garantir o desenvolvimento contínuo da escrita.
      </p>
      <a className={styles.loginRedirectLink} href={url}>Começar agora</a>

    </div>
  )
}

export default NonLoggedMobileContainer