import React from 'react'
import {
  Box,
  Radio,
  Typography
} from '@mui/material'

type ICompetenceRadioProps = {
  competences: any
  setCompetence: any
  competence: any
}

const CompetenceRadio: React.FC<ICompetenceRadioProps> = ({
  competences,
  setCompetence,
  competence
}) => {

  const getColor = (percentage: number) => {
    if (percentage <= 50) {
      return '#FFF6EE'
    } else if (percentage <= 70) {
      return '#FFF6EE'
    } else if (percentage <= 79.5) {
      return '#DAF0F5'
    } else if (percentage <= 80) {
      return '#F4FBE6'
    } else if (percentage <= 100) {
      return '#F2F5FF'
    } else {
      return '#d9d9d9'
    }
  }

  return (
    <Box
      sx={{
        width: 'fit-content',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
      }}
    >
      {
        competences.map((item: any) => {
          return (
            <Box
              key={item.id}
              sx={{
                alignItems: 'center',
                display: 'flex',
                gap: '4px'
              }}
            >
              <Radio
                checked={competence === item.id}
                onChange={() => setCompetence(item.id)}
                value={item.id}
                name={item.title}
              />

              <Typography
                sx={{
                  whiteSpace: 'nowrap',
                  fontSize: '16px',
                  fontWeight: 500,
                  lineHeight: '120%'
                }}
              >
                {item.title}
              </Typography>
              <Typography
                sx={{
                  background: getColor(item.percentage),
                  marginLeft: '23px',
                  padding: '8px 16px',
                  borderRadius: '40px'
                }}
              >{item.percentage}%</Typography>
            </Box>
          )
        })
      }
    </Box>
  )
}

export default CompetenceRadio