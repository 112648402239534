import { Box, Skeleton } from '@mui/material'
import React from 'react'

const ActivityConcludedCardMobileLoading = () => {
  return (
    <Box display='flex' flexDirection='column' gap='16px' mt='16px'>
      <Skeleton
        variant='rectangular'
        animation='wave'
        sx={{
          height: '189px',
          width: '100%',
          borderRadius: '20px'
        }}
      />
      <Skeleton
        variant='rectangular'
        animation='wave'
        sx={{
          height: '189px',
          width: '100%',
          borderRadius: '20px'
        }}
      />
      <Skeleton
        variant='rectangular'
        animation='wave'
        sx={{
          height: '189px',
          width: '100%',
          borderRadius: '20px'
        }}
      />
    </Box>
  )
}

export default ActivityConcludedCardMobileLoading