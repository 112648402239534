import { AnyAction, combineReducers } from 'redux'
import { all } from 'redux-saga/effects'

// Reducers
import authReducer from './auth/reducer'
import authVerboReducer from './authVerbo/reducer'
import homeStatisticsReducer from './ homeStatistics/reducer'
import activityReducer from './activity/reducer'

// Sagas
import authSagas from './auth/sagas'
import authVerboSagas from './authVerbo/sagas'
import homeStatisticsSagas from './ homeStatistics/sagas'
import activitySagas from './activity/sagas'

export function * rootSaga () {
  yield all([
    authSagas(),
    authVerboSagas(),
    homeStatisticsSagas(),
    activitySagas()
  ])
}

export const appReducer = combineReducers({
  auth: authReducer,
  authVerbo: authVerboReducer,
  homeStatistics: homeStatisticsReducer,
  activity: activityReducer
})

const rootReducer = (state:any, action: AnyAction) => {
  if (action.type === 'app:RESET_STORE') {
    state = undefined
  }
  return appReducer(state, action)
}

export default rootReducer