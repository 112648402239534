import React from 'react'
import { Box, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import ActivityCircledWhite from '../../assets/home/card/activity-circled-white-icon.svg'

type INotificationItemCardProps = {
  text: string
  startDate: string
  endDate: string
}

const NotificationItemCard: React.FC<INotificationItemCardProps> = (
  {
    text,
    startDate,
    endDate
  }
) => {

  const theme = useTheme()
  return (
    <Box
      sx={{
        display: 'flex',
        gap: '8px',
        maxWidth: '420px',
        width: '100%',
        marginBlock: '16px',
        padding: '9px 10px ',
        '&:hover': {
          backgroundColor: '#E6D8FF',
          borderRadius: '16px'
        }
      }}
    >
      <Box><img src={ActivityCircledWhite} /></Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px'
        }}
      >
        <Typography
          sx={{
            color: theme.palette.text.primary,
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '150%',
          }}
        >
          {text}
        </Typography>
        <Typography
          sx={{
            color: theme.palette.primary.light,
            fontSize: '10px',
            fontWeight: 500,
            lineHeight: '120%',
          }}
        >
          De {startDate} até {endDate}
        </Typography>

      </Box>

    </Box>
  )
}

export default NotificationItemCard