import * as React from 'react'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { Box } from '@mui/material'

interface BootstrapDialogProps {
  width?: string
  height?: string
  padding?: string
}

const BootstrapDialog = styled(Dialog)<BootstrapDialogProps>(({ theme, width, height, padding }) => ({
  padding: padding ?? 24,
  '& .MuiDialog-paper': {
    borderRadius: 16,
    maxWidth: width ?? '660px',
    maxHeight: height ?? '490px',
    width: '100%'
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(1)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

export interface DialogTitleProps {
  id: string
  children?: React.ReactNode
  onClose: () => void
  hasCloseButton?: boolean
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, hasCloseButton, ...other } = props

  return (
    <Box sx={{ m: 0, p: 1, display: 'flex', justifyContent: 'flex-end' }}>
      <DialogTitle sx={{ flex: 1, textAlign: 'center' }} {...other}>
        {children}
      </DialogTitle>
      {onClose ? (
        <>
          { hasCloseButton && <IconButton
            aria-label='close'
            onClick={onClose}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>}
        </>
      ) : null}
    </Box>
  )
}

type Props = PropsWithChildren<{
  open: boolean
  handleClose: any
  title?: React.ReactNode
  actions?: React.ReactNode
  sx?: React.CSSProperties
  width?: string
  height?: string
  padding?: string
  closeOnBackdropClick?: boolean
  hasCloseButton?: boolean
}>

export default function DialogModal({
  actions,
  children,
  handleClose,
  open,
  title,
  width,
  height,
  padding,
  closeOnBackdropClick = true,
  hasCloseButton
}: Props) {

  const handleBackdropClick = (event: React.MouseEvent) => {
    if (closeOnBackdropClick) {
      handleClose(event)
    }
  }

  return (
    <BootstrapDialog
      onClose={handleBackdropClick}
      aria-labelledby='modal'
      open={open}
      width={width}
      height={height}
      padding={padding}
    >
      <BootstrapDialogTitle hasCloseButton={hasCloseButton} id='modal' onClose={handleClose}>
        {title}
      </BootstrapDialogTitle>
      <DialogContent sx={{
        scrollbarWidth: 'thin',
        scrollbarColor: '#ccc #f9f9f9',
        '&::-webkit-scrollbar': {
          width: '5px'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f9f9f9'
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#ccc',
          borderRadius: '20px',
        }
      }}>
        {children}
      </DialogContent>
      <DialogActions>
        {actions}
      </DialogActions>
    </BootstrapDialog>
  )
}
