import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

// Assets
import ActivitiesImage from '../../assets/activities/activities-desktop-image.png'
import ActivityIcon from '../../assets/activities/activity-icon.svg'

// Components
import Notification from '../../components/notification/Notification'
import { Box, Typography } from '@mui/material'
import CustomSearchInput from '../../components/input/CustomSearchInput'
import LogoutButton from '../../components/button/logout/LogoutButton'
import PersonalDataDropDownMenu from '../../components/dropDown/PersonalDataDropDownMenu'
import { DatePickerField } from '../../components/input/DatePickerInput'
import { PageMainTitle } from '../../components/title/PageMainTitle'
import { PageSubTitle } from '../../components/title/PageSubTitle'
import SelectInput from '../../components/input/SelectInput'
import ActivitiesTable from '../../components/table/activities/ActivitiesTable'

// Redux
import { getStudentActivityListRequest } from '../../store/activity/actions'
import { useDispatch } from 'react-redux'

// Style
import { useTheme } from '@mui/material/styles'
import styles from './styles/activitiesDesktop.module.scss'

// Utils
import { formatDistance, format } from 'date-fns'

const ActivitiesDesktopContainer = () => {

  const navigate = useNavigate()
  const theme = useTheme()
  const dispatch = useDispatch()

  const [searchName, setSearchName] = useState<string>('')
  const [page, setPage] = useState(1)

  // Filters States
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [statusSelected, setStatusSelected] = useState()
  const [startDateFilter, setStartDateFilter] = useState('')

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setSearchName(value)
  }

  const status = [
    { label: 'Todas', value: 'all' },
    { label: 'Corrigída', value: 'corrected' },
    { label: 'Em Correção', value: 'in_correction' },
    { label: 'Não corrigida', value: 'not_corrected' },
    { label: 'Em atraso permitido', value: 'overdue_enabled' },
    { label: 'Visualizado', value: 'visualized' },
    { label: 'Não Visualizado', value: 'not_visualized' },
  ]

  const statusOptions = [
    { label: 'Selecione...', value: 'placeholder' },
    ...status
  ]
  const options = [
    { label: 'ENEM', value: 'ENEM' },
    { label: 'Concurso', value: 'concurso' },
    { label: 'ITA', value: 'ita' }
  ]

  const bancaOptions = [
    { label: 'Selecione...', value: 'placeholder' },
    ...options
  ]

  const resetLocation = () => {
    const newLocation = {
      ...location,
      state: null
    }
    navigate(newLocation)
  }

  const handleGetActivitiesItems = (params: object) => {
    dispatch(getStudentActivityListRequest(params))
  }

  const handleStartChanges = (startValue: any) => {
    if (startValue) {
      startValue && setStartDateFilter(format(startValue, 'yyyy-MM-dd'))
    }
    const startDateFormatted = startValue ? format(startValue, 'yyyy-MM-dd') : ''
    if (endDate && formatDistance(startValue, endDate)) {
      setEndDate(null)
      handleGetActivitiesItems({
        status: statusSelected,
        per_page: 5,
        end_datetime_after: startDateFormatted,
        page: 1
      })
    } else {
      handleGetActivitiesItems({
        status: statusSelected,
        per_page: 5,
        end_datetime_after: startDateFormatted,
        page: 1
      })
    }
    setPage(1)
    setStartDate(startValue)
    resetLocation()
  }

  const handleEndChanges = (endValue: any) => {
    const endDateFormatted = endValue ? format(endValue, 'yyyy-MM-dd') : ''
    setEndDate(endValue)
    setPage(1)
    handleGetActivitiesItems({
      status: statusSelected,
      end_datetime_before: endDateFormatted,
      page: 1
    })
    resetLocation()
  }

  const handleFilterByStatus = (value: string) => {
    if (value === 'all') {
      handleGetActivitiesItems({
        per_page: 5,
        ...(startDateFilter ? { end_datetime_after: startDateFilter } : {}),

      })
      setPage(1)
    } else {
      handleGetActivitiesItems({
        status: value,
        per_page: 5,
        ...(startDateFilter ? { end_datetime_after: startDateFilter } : {}),
      })
      setPage(1)
    }
  }

  const handleFilterSelect = (event: any) => {
    setStatusSelected(event)
    handleFilterByStatus(event)
  }
  return (
    <div className={styles.container}>
      <div
        className={styles.top_header}
      >
        <CustomSearchInput
          name='search'
          value={searchName}
          onChange={handleSearch}
        />
        <div
          className={styles.notification_container}
        >
          <Box display='flex' gap='8px'>
            <Notification
              background='#F2F5FF'
            />
            <LogoutButton
              background='#F2F5FF'
            />
          </Box>
          <PersonalDataDropDownMenu
            background='#F2F5FF'
          />
        </div>
      </div>
      <div className={styles.title_content}>
        <div className={styles.title_text}>
          <PageMainTitle>
            <img src={ActivityIcon} />
            Atividades
          </PageMainTitle>
          <p className={styles.text}>
            Aqui, você gerencia suas redações enviadas, acompanha o progresso, visualiza as notas e recebe orientações para melhorar sua escrita.
          </p>
        </div>
        <img src={ActivitiesImage} />
      </div>
      <div className={styles.filters_container}>
        <PageSubTitle>Minhas atividades</PageSubTitle>
        <div className={styles.filters}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Typography>Status:</Typography>
            <SelectInput
              width='fit-content'
              name='selectSubject'
              border='none'
              onChange={(event: any) => handleFilterSelect(event.target.value)}
              defaultValue='placeholder'
              options={statusOptions}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Typography>Banca:</Typography>
            <SelectInput
              width='fit-content'
              name='selectSubject'
              border='none'
              onChange={() => null}
              defaultValue='placeholder'
              options={bancaOptions}
            />
          </Box>
          <Box
            sx={{
              display: 'flex'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px'
              }}
            >
              <Box>
                <Typography
                  color={theme.palette.text.primary}
                  variant='subtitle2'
                  component='p'
                >
                  Iniciam entre:
                </Typography>
              </Box>
              <Box>
                <DatePickerField
                  name='start_date'
                  onChange={handleStartChanges}
                  startDate={startDate}
                  endDate={endDate}
                  placeholder='dd/mm/aaaa'
                  selectsStart
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex ',
                alignItems: 'center',
                gap: '8px',
                marginLeft: '8px'
              }}
            >
              <Box>
                <DatePickerField
                  name='end_date'
                  onChange={handleEndChanges}
                  startDate={endDate}
                  endDate={endDate}
                  minDate={startDate}
                  placeholder='dd/mm/aaaa'
                />
              </Box>
            </Box>
          </Box>
        </div>
      </div>
      <ActivitiesTable statusSelected={statusSelected} startDateFilter={startDateFilter} page={page} setPage={setPage} />
    </div>
  )
}

export default ActivitiesDesktopContainer