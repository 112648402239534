import { styled } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import LeftRoundedPinkArrow from '../../assets/addNewTheme/left-rounded-pink-arrow.svg'

type IBackButtonProps = {
  navigateTo: string
}
const Button = styled('button')(({ theme }) => ({
  alignItems: 'center',
  color: theme.palette.primary.light,
  cursor: 'pointer',
  display: 'flex',
  fontSize: '22px',
  fontWeight: 600,
  fontFamily: 'Montserrat',
  gap: '8px',

  ':hover': {
    textDecoration: 'underline'

  }
}))
const BackButton: React.FC<IBackButtonProps> = ({ navigateTo }) => {
  const navigate = useNavigate()
  const handleNavigate = () => {
    navigate(navigateTo)
  }
  return (
    <Button
    onClick={handleNavigate}
    >
      <img src={LeftRoundedPinkArrow} />
      Voltar
    </Button>
  )
}

export default BackButton