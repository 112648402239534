import React from 'react'
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'

type INavigateRoundedButtonProps = {
  text: string
  url?: string,
}

const Button = styled('button')(({ theme }) => ({
  background: theme.palette.primary.main,
  color: '#fff',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '120%',
  padding: '14.5px 16px',
  borderRadius: '56px',
  ':hover': {
    cursor: 'pointer',
    opacity: 0.7,

  }
}))
const NavigateRoundedButton: React.FC<INavigateRoundedButtonProps> = ({ text, url }) => {
  const navigate = useNavigate()
  return (
    <Button
      onClick={() => navigate(url ?? '#')}
    >
      {text}
    </Button>
  )
}

export default NavigateRoundedButton