import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Icon,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { useDrawerContext } from '../../contexts/DrawerContext'


interface IMenuItemLinkProps {
  label: string | React.ReactNode 
  icon: string | React.ReactNode
  to: string
  onClick: (() => void) | undefined
}

const MenuItemLink: React.FC<IMenuItemLinkProps> = ({ to, label, icon, onClick }) => {
  const { isDrawerOpen } = useDrawerContext()
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(to)
    onClick?.()
  }

  return (
    <ListItemButton
      onClick={handleClick}
    >
      <ListItemIcon>
        <Icon
          sx={{ fontSize: isDrawerOpen ? '20px' : '32px' }}
        >
          {icon}
        </Icon>
      </ListItemIcon>
      <ListItemText
        primary={label}
        sx={{
          color: '#fff',
          fontSize: '18px',
          '& > span': {
            fontWeight: 500,
          }
        }}
      />
    </ListItemButton>
  )
}

export default MenuItemLink