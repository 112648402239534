import React from 'react'
import { Box, Divider } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import styles from './styles/gradeCardMobile.module.scss'
type IGradeCardMobileProps = {
  data: any
}

const GradeCardMobile: React.FC<IGradeCardMobileProps> = ({
  data
}) => {

  const theme = useTheme()

  const getBackgroundColor = (score: number) => {
    let background
    let fontColor
    if (score >= 900) {
      background = '#7EEB89'
      fontColor = '#434343'
    } else if (score >= 700 && score < 900) {
      background = theme.palette.text.primary
      fontColor = '#fff'
    } else if (score >= 400 && score < 700) {
      background = '#F6BB8C'
    } else if (score < 400) {
      background = theme.palette.error.main
    }
    return { background, fontColor }
  }

  return (
    <Box
      bgcolor={getBackgroundColor(data.nota)}
      className={styles.card}
    >
      <Box paddingInline='24px'>

      <h2
        style={{
          color: getBackgroundColor(data.nota).fontColor
        }}
        className={styles.banca}
      >
        {data.banca}
      </h2>
      <Divider sx={{ borderColor: getBackgroundColor(data.nota).fontColor + '!important' }} className={styles.dividerStyle} />
      <p
        style={{
          color: getBackgroundColor(data.nota).fontColor
        }}
        className={styles.label}>
        Nota média
      </p>
      <p
        style={{
          color: getBackgroundColor(data.nota).fontColor
        }}
        className={styles.grade}>
        {data.nota ?? '000'}
      </p>
      </Box>
    </Box>
  )
}

export default GradeCardMobile