import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import ArrowRight from '../../assets/card/arrow-right.svg'

// Styles
import { useTheme } from '@mui/material/styles'
import styles from './styles/gradesInfoCard.module.scss'

type IGradesInfoCardProps = {
  title?: string
  banca?: string
  correctionDate?: string
  score: number
  time?: string | null
  rewritten?: boolean
}

const GradesInfoCard: React.FC<IGradesInfoCardProps> = ({
  title,
  banca,
  correctionDate,
  score,
  time,
  rewritten
}) => {

  const theme = useTheme()

  const getBackgroundColor = (score: number) => {
    let background
    let fontColor
    if (score >= 900) {
      background = '#7EEB89'
    } else if (score >= 700 && score < 900) {
      background = theme.palette.text.primary
      fontColor = '#fff'
    } else if (score >= 400 && score < 700) {
      background = '#F6BB8C'
    } else if (score < 400) {
      background = theme.palette.error.main
    }
    return { background, fontColor }
  }
  return (
    <Box
      className={styles.container}
      sx={{
        background: rewritten ? '#E6D8FF' : '#F2F5FF',
      }}
    >
      <div className={styles.title_container}>
        <Typography className={styles.title}>
          {title}
        </Typography>
        <Button sx={{ borderRadius: '50%' }}>
          <img src={ArrowRight} />
        </Button>
      </div>

      <Box className={styles.info_container}>
        <Typography className={styles.label}>
          Banca: <Typography className={styles.value}>
            {banca}
          </Typography>
        </Typography>
        <Typography className={styles.label}>
          Correção:
          <Typography className={styles.value}>
            {correctionDate}
          </Typography>
        </Typography>
      </Box>
      <Box className={styles.info_container}>
        <Typography className={styles.label} >
          Nota:
          <Typography
            bgcolor={getBackgroundColor(score).background}
            className={styles.value}
            color={getBackgroundColor(score).fontColor}
            >
            {score}
          </Typography>
        </Typography>
        <Typography className={styles.label}>
          Tempo:
          <Typography bgcolor='#fff' className={styles.value}>
            {time ?? '-- : -- : --'}
          </Typography>
        </Typography>
      </Box>
    </Box>
  )
}

export default GradesInfoCard