export const AppUrl: { [key: string]: string } = {
    local: 'http://localhost:8000',
    staging: 'https://stag-app.plataformaverbo.com.br',
    production: 'https://app.plataformaverbo.com.br',
}

export const ssoUrl: { [key: string]: any } = {
    local: 'http://localhost:8001/',
    staging: 'https://staging-fractalid.fractaltecnologia.com.br',
    production: 'https://fractalid.fractaltecnologia.com.br'
}
