import { styled } from '@mui/material';

export const RoundedButtonColorLight = styled('button')(({ theme }) => ({
  alignItems: 'center',
  background: theme.palette.primary.light,
  borderRadius: '56px',
  cursor: 'pointer',
  color: '#fff',
  display: 'flex',
  fontSize: '16px',
  fontWeight: 600,
  fontFamily: 'Montserrat',
  gap: '8px',
  height: 'fit-content',
  lineHeight: '120%',
  padding: '15px 60px',
  '&: hover': {
    opacity: 0.7
  },
  '&: active': {
    background: theme.palette.secondary.main
  }
}))

export const RoundedAnchorColorLight = styled('a')(({ theme }) => ({
  alignItems: 'center',
  background: theme.palette.primary.light,
  borderRadius: '56px',
  cursor: 'pointer',
  color: '#fff',
  display: 'flex',
  fontSize: '16px',
  fontWeight: 600,
  fontFamily: 'Montserrat',
  gap: '8px',
  height: 'fit-content',
  lineHeight: '120%',
  padding: '15px 60px',
  textDecoration: 'none',
  '&: hover': {
    opacity: 0.7
  },
  '&: active': {
    background: theme.palette.secondary.main
  }
}))