import React from 'react'

// Context
import { useDrawerContext } from '../../contexts/DrawerContext'

// Components
import Sidebar from '../sidebar/Sidebar'
import { Box, useMediaQuery } from '@mui/system'
import Header from '../header/Header'

// Styles
import { useTheme, styled } from '@mui/material/styles'

const Container = styled(Box)(() => ({
  width: '100%'
}))

const DashboardTemplate = ({ children }: any) => {
  const theme = useTheme()
  const { isDrawerOpen } = useDrawerContext()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const isNotFHD = useMediaQuery('(max-width:1675px) and (min-width: 905px)')

  return (
    <Box sx={{ display: 'flex' }}>
      <Box
        position='fixed'
        height='100vh'
        zIndex={99}
      >
        <Sidebar />
      </Box>
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: isNotFHD ? (isDrawerOpen ? 'calc(100% - 200px)' : 'calc(100% - 81px)') : ('100%'),
          paddingLeft: smDown ? 0 : '20px',
          marginBlock: smDown ? 0 : '16px',
          marginLeft: smDown ? 0 : isDrawerOpen ? '200px' : '81px',
        }}
      >
        {smDown && <Header />}
        <Box flex={1} padding={0} marginTop={smDown ? '64px' : 0}>
          {children}
        </Box>
      </Container>
    </Box>
  )
}

export default DashboardTemplate
