import { all, call, takeLatest, put } from 'redux-saga/effects'
import * as actions from './actions'
import { AxiosResponse } from 'axios'

import * as services from './services'

export function * getVerboUserDataSagas () {
  try {
    const response:AxiosResponse<any> = yield call(services.getVerboUserDataService)
    if (response.status === 200) {
      yield put(actions.getVerboUserDataSuccess(response?.data))
    }
  } catch (error) {
    yield put(actions.getVerboUserDataRejected())
  }
}

// Watchers
export function* watchGetVerboUserData() {
  yield takeLatest(actions.getVerboUserDataRequest.type, getVerboUserDataSagas)
}

export default function* authVerboSagas() {
  yield all([
    watchGetVerboUserData(),
  ])
}
