import { createAction } from '@reduxjs/toolkit'
import { withPayloadType } from '../../utils/functions'

export interface getStudentActivityItemsPayload {
  per_page?: number | null
  page?: number | null
  end_datetime_after?: string | null
  end_datetime_before?: string | null
  sheet_name?: string | null
  jury?: string | null
  status?: string
}
export interface getStudentActivityByIdPayload {
  id: string | number
}
export interface postStudentActivityPayload {
  id: string | null
  file: any
}
export interface postStudentChatCommentPayload {
  activity_id: string | null
  comment: string
}
export interface postStudentChatCommentSuccessPayload {
  comment: string
}

export const getStudentActivityListRequest = createAction('activity:GET_STUDENT_ACTIVITY_LIST_REQUEST', withPayloadType<getStudentActivityItemsPayload>())
export const getStudentActivityListSuccess = createAction('activity:GET_STUDENT_ACTIVITY_LIST_SUCCESS', withPayloadType<any>())
export const getStudentActivityListRejected = createAction('activity:GET_STUDENT_ACTIVITY_LIST_REJECTED', withPayloadType<any>())

export const getCalendarActivitiesListRequest = createAction('activity:GET_CALENDAR_ACTIVITIES_LIST_REQUEST', withPayloadType<getStudentActivityItemsPayload>())
export const getCalendarActivitiesListSuccess = createAction('activity:GET_CALENDAR_ACTIVITIES_LIST_SUCCESS', withPayloadType<any>())
export const getCalendarActivitiesListRejected = createAction('activity:GET_CALENDAR_ACTIVITIES_LIST_REJECTED', withPayloadType<any>())

export const getStudentActivityByIdRequest = createAction('activity:GET_STUDENT_ACTIVITY_BY_ID_REQUEST', withPayloadType<getStudentActivityByIdPayload>())
export const getStudentActivityByIdSuccess = createAction('activity:GET_STUDENT_ACTIVITY_BY_ID_SUCCESS', withPayloadType<any>())
export const getStudentActivityByIdRejected = createAction('activity:GET_STUDENT_ACTIVITY_BY_ID_REJECTED', withPayloadType<any>())

export const getStudentActivityReWriteByIdRequest = createAction('activity:GET_STUDENT_ACTIVITY_REWRITE_BY_ID_REQUEST', withPayloadType<getStudentActivityByIdPayload>())
export const getStudentActivityReWriteByIdSuccess = createAction('activity:GET_STUDENT_ACTIVITY_REWRITE_BY_ID_SUCCESS', withPayloadType<any>())
export const getStudentActivityReWriteByIdRejected = createAction('activity:GET_STUDENT_ACTIVITY_REWRITE_BY_ID_REJECTED', withPayloadType<any>())

export const postStudentActivityRequest = createAction('activity:POST_STUDENT_ACTIVITY_REQUEST', withPayloadType<postStudentActivityPayload>())
export const postStudentActivitySuccess = createAction('activity:POST_STUDENT_ACTIVITY_SUCCESS')
export const postStudentActivityRejected = createAction('activity:POST_STUDENT_ACTIVITY_REJECTED', withPayloadType<any>())

export const postStudentChatCommentRequest = createAction('activity:POST_STUDENT_CHAT_COMMENT_REQUEST', withPayloadType<postStudentChatCommentPayload>())
export const postStudentChatCommentSuccess = createAction('activity:POST_STUDENT_CHAT_COMMENT_SUCCESS', withPayloadType<postStudentChatCommentSuccessPayload>())
export const postStudentChatCommentRejected = createAction('activity:POST_STUDENT_CHAT_COMMENT_REJECTED', withPayloadType<any>())