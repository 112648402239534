import { Box, styled, Typography } from '@mui/material'
import React from 'react'

type IEssayThemeCardProps = {
  text: string
  banca: string
  numberOfEssay: number
  backgroundColor?: string
}
const Label = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '14px',
  lineHeight: '150%',
  fontWeight: 500
}))
const CardValue = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '14px',
  lineHeight: '120%',
  fontWeight: 500,
  padding: '11px 15px',
  background: '#fff',
  borderRadius: '56px',
  textAlign: 'center'
}))
const CardText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '18px',
  fontWeight: 600,
  lineHeight: '120%',
  marginBottom: '38px',
  maxWidth: '250px',
  width: '100%'
}))

const EssayThemeCard: React.FC<IEssayThemeCardProps> = ({
  text,
  banca,
  numberOfEssay,
  backgroundColor
}) => {
  return (
    <Box
      sx={{
        borderRadius: '32px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '24px 16px 16px 16px',
        background: backgroundColor,
        height: '100%',
        maxHeight: '282px',
        maxWidth: '332px',
        width: '100%',
      }}
    >
      <CardText>
        {text}
      </CardText>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Label>Banca</Label>
          <CardValue>
            {banca}
          </CardValue>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Label>Redações</Label>
          <CardValue>
            {numberOfEssay}
          </CardValue>
        </Box>

      </Box>

    </Box>
  )
}

export default EssayThemeCard