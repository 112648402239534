import { all, call, takeLatest, put } from 'redux-saga/effects'
import { AxiosResponse } from 'axios'

import * as services from './services'
import * as actions from './actions'

export function * getHomeStatisticsSagas () {
  try {
    const response:AxiosResponse<any> = yield call(services.getHomeStatisticsService)
    if (response.status === 200) {
      yield put(actions.getHomeStatisticsSuccess(response?.data))
    }
  } catch (error) {
    yield put(actions.getHomeStatisticsRejected(error))
  }
}

// Watchers
export function* watchGetHomeStatisticsData() {
  yield takeLatest(actions.getHomeStatisticsRequest.type, getHomeStatisticsSagas)
}

export default function* homeStatisticsSagas() {
  yield all([
    watchGetHomeStatisticsData(),
  ])
}
