import React, { useEffect } from 'react'
import { useTheme } from '@mui/material/styles'
import { useMediaQuery } from '@mui/system'
import SuccessPanelMobileContainer from '../containers/successPanel/SuccessPanelMobileContainer'
import SuccessPanelDesktopContainer from '../containers/successPanel/SuccessPanelDesktopContainer'


const SuccessPanelPage = () => {
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    document.title = "Painel de Sucesso | Verbo"
  }, [])

  return (
    smDown ? <SuccessPanelMobileContainer /> : <SuccessPanelDesktopContainer />
  )
}

export default SuccessPanelPage