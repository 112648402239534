/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

// Assets
import DownloadIcon from '../../../assets/activities/download-icon.svg'
import EssaySentImage from '../../../assets/activities/essay-sent-image.png'

// Components
import CustomSearchInput from '../../../components/input/CustomSearchInput'
import { Box, LinearProgress, Tab, Typography } from '@mui/material'
import Notification from '../../../components/notification/Notification'
import LogoutButton from '../../../components/button/logout/LogoutButton'
import PersonalDataDropDownMenu from '../../../components/dropDown/PersonalDataDropDownMenu'
import NavigationTitleWithBackButton from '../../../components/navigation/NavigationTitleWithBackButton'
import ActivityTitleWithData from '../../../components/title/ActivityTitleWithData'
import ActivityTitleWithScore from '../../../components/title/ActivityTitleWithScore'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { RoundedButtonNavigate } from '../../../components/button/RoundedButton'
import EssayUpload from '../../../components/imageUpload/activity/EssayUpload'

// Redux
import { IActivity } from '../../../store/activity/types'

// Style
import styles from './style/pedingEssayDesktop.module.scss'
import { useTheme } from '@mui/material/styles'

// Utils
import { format } from 'date-fns'
import { getActivityId } from '../../../utils/functions'
import { useDispatch } from 'react-redux'
import { getStudentActivityByIdRequest } from '../../../store/activity/actions'
import { useAppSelector } from '../../../store/hooks'

const ActivityPendingEssayDesktopContainer = () => {
  const theme = useTheme()
  const location = useLocation()
  const dispatch = useDispatch()
  const activityId: any = getActivityId(location.pathname)
  const { item, isFetching } = useAppSelector(state => state.activity)
  
  useEffect(() => {
    dispatch(getStudentActivityByIdRequest({ id: activityId }))
  }, [])

  const essayTheme = item.subject
  const classroom = 'API return nothing...'
  const startDate = item?.start_datetime && format(item?.start_datetime, 'dd/MM/yyyy')
  const endDate = item?.end_datetime && format(item?.end_datetime, 'dd/MM/yyyy')
  const essayTitle = item.name
  const essayScore = Number(item?.answer?.grade)
  const essayInstruction = item.file_image
  const essayInstructionLink = item.file_pdf || item.file_image
  const [searchName, setSearchName] = useState<string>('')
  const [tabValue, setTabValue] = useState('1')

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setSearchName(value)
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue)
  }

  return (
    <div className={styles.container}>
      <div
        className={styles.top_header}
      >
        <CustomSearchInput
          name='search'
          value={searchName}
          onChange={handleSearch}
        />

        <div
          className={styles.notification_container}
        >
          <Box display='flex' gap='8px'>
            <Notification
              background='#F2F5FF'
            />
            <LogoutButton
              background='#F2F5FF'
            />
          </Box>
          <PersonalDataDropDownMenu
            background='#F2F5FF'
          />
        </div>
      </div>
      <NavigationTitleWithBackButton
        backTo='/atividades'
        text='Atividades / Minhas atividades'
      />
      {

        isFetching ? (<LinearProgress />) : (
          <>
            <ActivityTitleWithScore
              title={essayTitle}
              score={essayScore}
              maxWidth='934px'
            />
            <ActivityTitleWithData
              theme={essayTheme}
              classroom={classroom}
              essayStartDate={startDate}
              essayEndDate={endDate}

            />

            <Box>
              <TabContext value={tabValue}>
                <Box className={styles.tabs_container}
                  sx={{ borderBottom: '2px solid rgba(224, 224, 224, 0.40)', borderColor: 'divider' }}
                >
                  <TabList
                    onChange={handleTabChange}
                    sx={{
                      '.Mui-selected': {
                        color: theme.palette.text.primary + '!important',
                        fontWeight: 600,
                        fontSize: '24px'
                      },
                      '.MuiTab-root': {
                        color: theme.palette.text.secondary,
                        fontWeight: 600,
                        fontSize: '24px'
                      },
                      '.MuiTabs-indicator': {
                        background: theme.palette.primary.light,
                      }
                    }}
                  >
                    <Tab label='Atividade' value='1' />
                    <Tab label='Envio' value='2' />
                  </TabList>
                  {
                    tabValue === '1' &&
                    <RoundedButtonNavigate href={essayInstructionLink} target='_blank' sx={{ paddingInline: '25px' }}>
                      <img src={DownloadIcon} />
                      Baixar atividade
                    </RoundedButtonNavigate>
                  }
                </Box>
                <TabPanel value='1'>
                  <Box>
                    <img
                      width='80%'
                      src={essayInstruction}
                      alt='Instruções para realização da redação'
                    />
                  </Box>
                </TabPanel>
                <TabPanel value='2'>
                  {
                    item.answer.file ? (
                      <Box sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                      }}
                      >
                        <Typography
                          fontSize='24px'
                          fontWeight={500}
                          color={theme.palette.text.primary}
                        >
                          Atividade já foi enviada.
                        </Typography>
                        <img src={EssaySentImage} />
                      </Box>
                    ) : (
                      <EssayUpload />
                    )
                  }
                </TabPanel>
              </TabContext>
            </Box>
          </>
        )

      }
    </div>
  )
}

export default ActivityPendingEssayDesktopContainer