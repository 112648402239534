import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

// Assets
import ActivityIcon from '../../assets/activities/activity-icon.svg'
import TeacherImage from '../../assets/grades/teacher-image.png'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'

// Components
import CustomSearchInput from '../../components/input/CustomSearchInput'
import Notification from '../../components/notification/Notification'
import LogoutButton from '../../components/button/logout/LogoutButton'
import PersonalDataDropDownMenu from '../../components/dropDown/PersonalDataDropDownMenu'
import {
  Box,
  styled,
  Typography,
  // useMediaQuery
} from '@mui/material'
import { PageMainTitle } from '../../components/title/PageMainTitle'
import GradesTable, { rows } from '../../components/table/grades/GradesTable'
import GradeCard from '../../components/card/GradeCard'
import { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { DatePickerField } from '../../components/input/DatePickerInput'
import SelectInput from '../../components/input/SelectInput'
import { PageSubTitle } from '../../components/title/PageSubTitle'

// Styles
import styles from './styles/gradesDesktop.module.scss'
import 'swiper/css'
import { useTheme } from '@mui/material/styles'

// Utils
import { format, formatDistance } from 'date-fns'

const BoxStyled = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: '8px',
  justifyContent: 'center',

  '& .swiper-slide': {
    width: '200px !important'
  },
  '& .slider': {
    marginInline: 0,
    width: '100%',
    maxWidth: 1440,
    [theme.breakpoints.down('lg')]: {
      maxWidth: '868px',
    },
    [theme.breakpoints.down(1100)]: {
      maxWidth: '750px',
    },
    [theme.breakpoints.down(970)]: {
      maxWidth: '570px',
    }

  },

  '& .main-slider-prev, .main-slider-next': {
    width: 22,
    height: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.text.primary,
    borderRadius: '50%',
    outline: 'none',
    border: 'none',
    cursor: 'pointer',

  },

  '& .swiper-button-disabled': {
    opacity: 0.4,
  }
}))
const GradesDesktopContainer = () => {

  const navigate = useNavigate()
  const theme = useTheme()
  const [searchName, setSearchName] = useState<string>('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [page, setPage] = useState(1)
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setSearchName(value)
  }

  const options = [
    { label: 'ENEM', value: 'ENEM' },
    { label: 'Concurso', value: 'concurso' },
    { label: 'ITA', value: 'ita' }
  ]

  const bancaOptions = [
    { label: 'Selecione...', value: 'placeholder' },
    ...options
  ]

  const resetLocation = () => {
    const newLocation = {
      ...location,
      state: null
    }
    navigate(newLocation)
  }

  const handleStartChanges = (startValue: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const startDateFormatted = startValue ? format(startValue, 'yyyy-MM-dd') : ''
    if (endDate && formatDistance(startValue, endDate)) {
      setEndDate(null)

    } else {
      return null
    }
    setPage(1)
    setStartDate(startValue)
    resetLocation()
  }

  const handleEndChanges = (endValue: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const endDateFormatted = endValue ? format(endValue, 'yyyy-MM-dd') : ''
    setEndDate(endValue)
    setPage(1)
    resetLocation()
  }

  return (
    <div className={styles.container}>
      <div
        className={styles.top_header}
      >
        <CustomSearchInput
          name='search'
          value={searchName}
          onChange={handleSearch}
        />

        <div
          className={styles.notification_container}
        >
          <Box display='flex' gap='8px'>
            <Notification
              background='#F2F5FF'
            />
            <LogoutButton
              background='#F2F5FF'
            />
          </Box>
          <PersonalDataDropDownMenu
            background='#F2F5FF'
          />
        </div>
      </div>
      <div className={styles.top_content}>
        <div className={styles.title_content}>
          <PageMainTitle>
            <img src={ActivityIcon} />
            Notas
          </PageMainTitle>
          <p>
            Acompanhe todas as suas notas em atividades anteriores. Monitore seu progresso e melhore seu desempenho!
          </p>
        </div>
        <div className={styles.image_container}>
          <img src={TeacherImage} alt='Professora na frente de um quadro com provas ao lado.' />
        </div>
      </div>

      {
        (rows.length > 0) &&
        <BoxStyled>
          <button className='main-slider-prev'>
            <ArrowBackIosNewRoundedIcon sx={{ fontSize: '14px', color: '#fff' }} />
          </button>
          <Swiper
            slidesPerView={1}
            spaceBetween={0}
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 16,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
            }}
            className='slider'
            modules={[Navigation]}
            navigation={{
              prevEl: '.main-slider-prev',
              nextEl: '.main-slider-next'
            }}
          >

            {rows.map((item: any) => {
              return (
                <SwiperSlide key={item.id}>
                  <GradeCard data={item} />
                </SwiperSlide>
              )
            })}
          </Swiper>
          <button className='main-slider-next'>
            <ArrowForwardIosRoundedIcon sx={{ fontSize: '14px', color: '#fff' }} />
          </button>
        </BoxStyled>
      }
      {(rows.length > 0) &&
        <div className={styles.filters_container}>
          <PageSubTitle>Minhas notas</PageSubTitle>
          <div className={styles.filters}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <Typography>Banca:</Typography>
              <SelectInput
                width='fit-content'
                name='selectSubject'
                border='none'
                onChange={() => null}
                defaultValue='placeholder'
                options={bancaOptions}
              />
            </Box>
            <Box
              sx={{
                display: 'flex'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px'
                }}
              >
                <Box>
                  <Typography
                    color={theme.palette.text.primary}
                    variant='subtitle2'
                    component='p'
                  >
                    Iniciam entre:
                  </Typography>
                </Box>
                <Box>
                  <DatePickerField
                    name='start_date'
                    onChange={handleStartChanges}
                    startDate={startDate}
                    endDate={endDate}
                    placeholder='dd/mm/aaaa'
                    selectsStart
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex ',
                  alignItems: 'center',
                  gap: '8px',
                  marginLeft: '8px'
                }}
              >
                <Box>
                  <DatePickerField
                    name='end_date'
                    onChange={handleEndChanges}
                    startDate={endDate}
                    endDate={endDate}
                    minDate={startDate}
                    placeholder='dd/mm/aaaa'
                  />
                </Box>
              </Box>
            </Box>
          </div>
        </div>
      }
      <GradesTable />
    </div>
  )
}

export default GradesDesktopContainer