import { createReducer } from '@reduxjs/toolkit'
import { StateType } from './types'
import * as actions from './actions'

const initialState: StateType = {
  statistics: {
    average_grade: 0,
    count_corrected: 0,
    count_submitted: 0,
    count_pending: 0,
    count_in_correction: 0,
  },
  isFetching: false,
  isError: false,
  errorMessage: ''
}

const homeStatisticsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.getHomeStatisticsRequest, (state: StateType): StateType => ({
      ...state,
      isFetching: true,
      isError: false,
      errorMessage: ''
    }))
    .addCase(actions.getHomeStatisticsSuccess, (state: StateType, action: { payload: any }): StateType => ({
      ...state,
      isFetching: false,
      isError: false,
      errorMessage: '',
      statistics: action.payload,
    }))
    .addCase(actions.getHomeStatisticsRejected, (state: StateType, action: { payload: any }): StateType => ({
      ...state,
      isFetching: false,
      isError: true,
      errorMessage: action.payload
    }))

})

export default homeStatisticsReducer
