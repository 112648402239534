import React, { useEffect } from 'react'

// Components
import ActivitiesMobileContainer from '../containers/activities/ActivitiesMobileContainer'
import ActivitiesDesktopContainer from '../containers/activities/ActivitiesDesktopContainer'

// Redux
import { useDispatch } from 'react-redux'

// Styles
import { useTheme } from '@mui/material/styles'
import { useMediaQuery } from '@mui/system'
import { getStudentActivityListRequest } from '../store/activity/actions'

const ActivitiesPage = () => {
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const dispatch = useDispatch()

  useEffect(() => {
    document.title = "Atividades | Verbo"
    dispatch(getStudentActivityListRequest({ per_page: 5 }))
  }, [])

  return (
    smDown ? <ActivitiesMobileContainer /> : <ActivitiesDesktopContainer />
  )
}

export default ActivitiesPage