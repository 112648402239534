import axios from 'axios';

const { REACT_APP_SERVER_ENV } = process.env;

const currentApi = REACT_APP_SERVER_ENV;
interface Hosts {
	[key: string]: string;
}

const backendHosts: Hosts = {
	production: 'https://datagateway.fractaltecnologia.com.br',
	staging: 'https://explore.plataformaverbo.com.br',
	homolog: 'https://explore.plataformaverbo.com.br',
	local: 'http://localhost:3000',
};

export const currentHost = currentApi !== undefined ? backendHosts[currentApi] : backendHosts['.local']

const apiVersion = 'v2'

const instance = axios.create({
	baseURL: `${currentHost}/api/${apiVersion}`,
	headers: {
		'Content-Type': 'application/json'
	}
})


export default instance;
