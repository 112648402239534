import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

import VideoIcon from '../../assets/activities/video-icon.svg'
import CommentIcon from '../../assets/activities/comment-icon.svg'

export const Circle = styled(Box)(() => ({
  height: '12px',
  width: '12px',
  borderRadius: '50%'
}))

export const CompetenceContainer = styled(Box)(({theme}) => ({
  alignItems: 'center',
  display: 'flex',
  gap: '4px',
  whiteSpace: 'nowrap',
  width: '100%',
  [theme.breakpoints.down(905)]: {
    'p': {
      fontSize: '10px'
    }
  }
}))
const EssayLabel = () => {
  return (
    <Box mt='16px'>
      <Grid container rowSpacing='16px' >
        <Grid item xs={3}>
          <CompetenceContainer>
            <Circle bgcolor='#F6BB8C' />
            <Typography>Comp 1</Typography>
          </CompetenceContainer>
        </Grid>
        <Grid item xs={3}>
          <CompetenceContainer>
            <Circle bgcolor='#E6D8FF' />
            <Typography>Comp 2</Typography>
          </CompetenceContainer>
        </Grid>
        <Grid item xs={3}>
          <CompetenceContainer>
            <Circle bgcolor='#BCE7F1' />
            <Typography>Comp 3</Typography>
          </CompetenceContainer>
        </Grid>
        <Grid item xs={3}>
          <CompetenceContainer>
            <Circle bgcolor='#F9ECC9' />
            <Typography>Comp 4</Typography>
          </CompetenceContainer>
        </Grid>
        <Grid item xs={3}>
          <CompetenceContainer>
            <Circle bgcolor='#E3F4C0' />
            <Typography>Comp 5</Typography>
          </CompetenceContainer>
        </Grid>
        <Grid item xs={3}>
          <CompetenceContainer>
            <img src={VideoIcon} />
            <Typography>Vídeo</Typography>
          </CompetenceContainer>
        </Grid>
        <Grid item xs={3}>
          <CompetenceContainer>
            <img width={12} src={CommentIcon} />
            <Typography>Comentário do professor</Typography>
          </CompetenceContainer>
        </Grid>
      </Grid>
    </Box>
  )
}

export default EssayLabel