/* eslint-disable @typescript-eslint/no-unused-vars */
import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const PageSubTitle = styled(Typography)(({ theme }) => ({
  alignItems: 'center',
  color: 'var(--palette-text-tertiary)',
  display: 'flex',
  fontFamily: 'Montserrat',
  fontSize: '24px',
  fontWeight: 600,
  gap: '8px',
  lineHeight: '120%',
}))