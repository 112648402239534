import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'

import 'react-toastify/dist/ReactToastify.css'
import Routes from './routes/Routes'
import { DrawerProvider } from './contexts/DrawerContext'
import GlobalTheme from './index.styles'
import { ToastContainer } from 'react-toastify'

const supportsHistory = 'pushState' in window.history

const App = () => (
  <GlobalTheme>
    <Router forceRefresh={!supportsHistory} keyLength={12}>
      <DrawerProvider>
        <Routes />
      </DrawerProvider>
    </Router>
    <ToastContainer />
  </GlobalTheme>
)


export default App;
