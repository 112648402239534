import React from 'react'

import { Pagination } from "swiper"

import { Swiper, SwiperSlide } from "swiper/react"

// Components
import TextsProductionCardMobile from '../card/TextsProductionCardMobile'
import TextAverageScoreCardMobile from '../card/TextAverageScoreCardMobile'

// Styles
import { styled } from '@mui/material'
import "swiper/css"
import "swiper/css/pagination"

// Redux
import { useAppSelector } from '../../store/hooks'

const BoxStyled = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: '8px',
  justifyContent: 'center',
  marginTop: '22px',
  '&:hover': {
    cursor: 'grab'
  },

  '& .slider': {
    marginInline: 0,
    height: '250px',
    maxWidth: '375px'
  },

  '& .swiper-pagination-bullet-active': {
    background: '#8842FE'
  },
  '& .swiper-pagination-bullet': {
    height: '15px',
    width: '15px'
  },
  '& .swiper-pagination': {
    bottom: '0px !important'
  },
  '& .main-slider-prev, .main-slider-next': {
    alignItems: 'center',
    background: theme.palette.text.primary,
    border: 'none',
    borderRadius: '50%',
    cursor: 'grab',
    display: 'flex',
    height: 20,
    justifyContent: 'center',
    outline: 'none',
    width: 22,
  },
  '& .swiper-button-disabled': {
    opacity: 0.4,
  }
}))

function HomePageSliderMobile() {

  const { statistics } = useAppSelector((state) => state.homeStatistics)

  return (
    <BoxStyled>
      <Swiper
        slidesPerView={2}
        spaceBetween={0}
        className='slider'
        modules={[Pagination]}
        navigation={{
          prevEl: '.main-slider-prev',
          nextEl: '.main-slider-next'
        }}
        pagination={{
          clickable: true,
        }}
      >
        <SwiperSlide >
          <TextAverageScoreCardMobile
            average={statistics.average_grade}
          />
        </SwiperSlide>
        <SwiperSlide >
          <TextsProductionCardMobile
            status={'sent'}
            count={statistics.count_submitted}
          />
        </SwiperSlide>
        <SwiperSlide >
          <TextsProductionCardMobile
            status={'pending'}
            count={statistics.count_pending}
          />
        </SwiperSlide>
        <SwiperSlide >
          <TextsProductionCardMobile
            status={'corrected'}
            count={statistics.count_corrected}
          />
        </SwiperSlide>
        <SwiperSlide >
          <TextsProductionCardMobile
            status={'in_correction'}
            count={statistics.count_in_correction}
          />
        </SwiperSlide>
      </Swiper>
    </BoxStyled>
  )
}

export default HomePageSliderMobile;
