import React from 'react'

// Assets
import reportIcon from '../../../assets/activities/report-icon.svg'

// Components
import { PageSubTitle } from '../../title/PageSubTitle'

// Styles
import styles from './styles/competenceCollpseMobile.module.scss'
import SingleCompetenceMobile from './SingleCompetenceMobile'

const CompetencesMobile = () => {
  return (
    <div className={styles.container}>
      <PageSubTitle>
        <img src={reportIcon} />
        Relatório de correção
      </PageSubTitle>
      <h3 className={styles.sub_tittle}>Notas por critério</h3>
      <SingleCompetenceMobile />
    </div>
  )
}

export default CompetencesMobile