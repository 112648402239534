import React from 'react'

// Assets
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'

// Components
import DatePicker, { CalendarContainer } from 'react-datepicker'
import {
  Box,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material'

// Styles
import { styled, useTheme } from '@mui/material/styles'
import 'react-datepicker/dist/react-datepicker.css'

// Utils
import ptLocale from 'date-fns/locale/pt-BR'
import { isValid, toDate } from 'date-fns'

const DatePickerContainerStyled = styled(Box)(({ theme }) => ({
  width: '100%',
  '& .datePicker': {
    width: '100%',
  },
  '& .react-datepicker-wrapper': {
    display: 'block'
  },
  '& .react-datepicker__header': {
    background: 'none',
    border: 'none !important',
    color: `${theme.palette.text.primary} !important`,
    '& .react-datepicker__current-month, .react-datepicker__header.react-datepicker-year-header': {
      color: `${theme.palette.text.primary} !important`,
      fontSize: 14,
      fontWeight: 400,
      textTransform: 'capitalize',
      padding: '8px 10px'
    },
    '& .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name': {
      color: `${theme.palette.text.primary} !important`,
      fontSize: 10,
      textTransform: 'capitalize'
    },
    '& .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before': {
      borderWidth: '1px 1px 0 0'
    }
  },
  '& .react-datepicker__tab-loop .react-datepicker-popper': {
    zIndex: 3,
  },
  '& .react-datepicker-year-header': {
    paddingTop: '1rem'
  },
  '& .react-datepicker__month, .react-datepicker__year': {
    '& .react-datepicker__week, .react-datepicker__year-wrapper': {
      '& .react-datepicker__day--disabled, .react-datepicker__day--in-range': {
        backgroundColor: theme.palette.secondary.main,
        borderRadius: '50%',
        color: '#FFFFFF !important',
        filter: 'opacity(0.6)'
      },
      '& .react-datepicker__day--selected, .react-datepicker__day--keyboard-selected, .react-datepicker__day--selecting-range-start, .react-datepicker__year-text--selected': {
        borderRadius: '50%',
        backgroundColor: theme.palette.primary.light,
        color: '#FFFFFF !important'
      },
      '& .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name': {
        color: `${theme.palette.text.primary}`,
        fontSize: 12
      }
    }
  },
  '.MuiOutlinedInput-input::-webkit-input-placeholder':{
    opacity: '1'
  },
}))

const DatePickerStyled = styled('div')(({ theme }) => ({
  padding: 0,
  background: 'none',
  color: '#fff',
  '& .react-datepicker-wrapper': {
    marginTop: '8px'
  },
  '& .react-datepicker': {
    border: '1px solid #D9D9D9',
    borderRadius: 8,
    padding: '0.5rem',
    '& .react-datepicker__navigation': {
      background: 'rgba(217, 217, 217, 0.4)',
      borderRadius: '50%',
      height: 20,
      margin: '-2px 8px',
      top: 18,
      width: 20,
      '& .react-datepicker__navigation-icon': {
        fontSize: '10px !important',
        '&:before': {
          borderColor: theme.palette.text.primary,
          borderWidth: '1px 1px 0 0',
          height: 6,
          top: 4,
          width: 6
        }
      },
      '& .react-datepicker__navigation-icon--previous': {
        '&:before': {
          right: -2
        }
      },
      '& .react-datepicker__navigation-icon--next': {
        '&:before': {
          left: -2
        }
      }
    },
    '& .react-datepicker__day--today, .react-datepicker__month-text--today, .react-datepicker__quarter-text--today, .react-datepicker__year-text--today': {
      border: `1px solid ${theme.palette.primary.light} !important`,
      color: `${theme.palette.primary.light} !important`,
      borderRadius: '50%',
      fontWeight: 400
    },
    '& .react-datepicker__year-text--keyboard-selected': {
      background: 'none',
    }
  },
}))

const TextFieldStyled = styled(TextField)(({ disabled, theme }) => ({
  width: '100%',
  '& .MuiOutlinedInput-root': {
    borderRadius: 8,
    height: 40,
    fontSize: '14px',
    fontWeight: 500,
    color: theme.palette.text.primary,
    width: '100%',
    maxWidth: '155px',
    backgroundColor: disabled ? 'rgba(102, 102, 102, 0.08)' : '#F2F5FF',
    '& fieldset': {
      border: 'none'
    },
    '& .MuiSvgIcon-root': {
      fontSize: '1rem'
    },
  },
}))

type CalendarPopupProps = {
  className?: string | undefined
  children?: React.ReactNode | undefined
}
const CalendarPopup = ({ className, children }: CalendarPopupProps) => {
  return (
    <DatePickerStyled>
      <CalendarContainer className={className}>
        <div style={{ position: 'relative' }}>{children}</div>
      </CalendarContainer>
    </DatePickerStyled>
  )
}

const DatePickerInput = ({
  input: { onChange, value, ...restInput },
  label,
  meta: { touched, error },
  placeholder,
  dateFormat = 'dd/MM/yyyy',
  className,
  changeDate,
  marginBottom = '8px',
  readOnly,
  disabled,
  ...rest
}: any) => {
  const theme = useTheme()
  const handleChange = (newValue: any) => {
    if (isValid(newValue)) {
      onChange(newValue)
      if (typeof changeDate === 'function') {
        changeDate(newValue)
      }
    } else {
      onChange(null)
    }
  }

  return (
    <DatePickerContainerStyled sx={{ width: rest.width }}>
      {label &&
        <Typography
          sx={{
            color: theme.palette.text.primary,
            display: 'block',
            marginBottom: marginBottom,
            fontSize: 14,
            fontWeight: 500
          }}
          variant='body2'
          component='label'
        >
          {label}
        </Typography>}
      <DatePicker
        {...rest}
        {...restInput}
        className={className}
        locale={ptLocale}
        calendarContainer={CalendarPopup}
        peekNextMonth={false}
        dateFormat={dateFormat}
        placeholderText={placeholder}
        customInput={<TextFieldStyled
          InputProps={{
            readOnly: readOnly,
            startAdornment: (
              <InputAdornment position='start'>

              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position='end'>
                <KeyboardArrowDownRoundedIcon />
              </InputAdornment>
            )
          }}
        />}
        selected={value && isValid(value) ? toDate(value) : null}
        onChange={handleChange}
        value={value}
        disabled={disabled}
      />
      <Typography
        align='left'
        component='p'
        variant='caption'
        sx={{
          color: touched && error ? '#F69E9E' : '#D9D9D9'
        }}
      >
        {touched && error && error}
      </Typography>
    </DatePickerContainerStyled>
  )
}

export const DatePickerField = (props: any) => {
  const { input, onChange, placeholder, ...rest } = props
  return (
    <DatePickerContainerStyled>
      <DatePicker
        {...rest}
        locale={ptLocale}
        calendarContainer={CalendarPopup}
        peekNextMonth={false}
        dateFormat='dd/MM/yyyy'
        placeholderText={placeholder}
        customInput={<TextFieldStyled
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>

              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position='end'>
                <KeyboardArrowDownRoundedIcon />
              </InputAdornment>
            )
          }}
        />}
        selected={rest.startDate}
        onChange={onChange}
      />
    </DatePickerContainerStyled>
  )
}

export default DatePickerInput
