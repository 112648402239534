import { Box, Skeleton } from '@mui/material'
import React from 'react'

const ActivityCardMobileLoading = () => {
  return (
    <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '8px'
    }}
  >
    <Skeleton
      variant='rectangular'
      animation='wave'
      sx={{
        height: '197px',
        width: '100%',
        borderRadius: '20px'
      }}
    />
    <Skeleton
      variant='rectangular'
      animation='wave'
      sx={{
        height: '197px',
        width: '100%',
        borderRadius: '20px'
      }}
    />
    <Skeleton
      variant='rectangular'
      animation='wave'
      sx={{
        height: '197px',
        width: '100%',
        borderRadius: '20px'
      }}
    />
    <Skeleton
      variant='rectangular'
      animation='wave'
      sx={{
        height: '197px',
        width: '100%',
        borderRadius: '20px'
      }}
    />
    <Skeleton
      variant='rectangular'
      animation='wave'
      sx={{
        height: '197px',
        width: '100%',
        borderRadius: '20px'
      }}
    />
  </Box>
  )
}

export default ActivityCardMobileLoading