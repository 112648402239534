import React, { useState } from 'react'

// Assets
import ActivityIcon from '../../assets/activities/activity-icon.svg'

// Components
import GradesMobileSlider from '../../components/slider/GradesMobileSlider'
import { PageSubTitle } from '../../components/title/PageSubTitle'
import { Box, Typography } from '@mui/material'
import { DatePickerField } from '../../components/input/DatePickerInput'
import SelectInput from '../../components/input/SelectInput'

// Styles
import styles from './styles/gradesMobile.module.scss'
import { useTheme } from '@mui/material/styles'
import { format, formatDistance } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import { rows } from '../../components/table/grades/GradesTable'
import GradesInfoCard from '../../components/card/GradesInfoCard'
const GradesMobileContainer = () => {

  const theme = useTheme()
  const navigate = useNavigate()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [page, setPage] = useState(1)
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)


  const options = [
    { label: 'ENEM', value: 'ENEM' },
    { label: 'Concurso', value: 'concurso' },
    { label: 'ITA', value: 'ita' }
  ]

  const bancaOptions = [
    { label: 'Selecione...', value: 'placeholder' },
    ...options
  ]

  const resetLocation = () => {
    const newLocation = {
      ...location,
      state: null
    }
    navigate(newLocation)
  }

  const handleStartChanges = (startValue: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const startDateFormatted = startValue ? format(startValue, 'yyyy-MM-dd') : ''
    if (endDate && formatDistance(startValue, endDate)) {
      setEndDate(null)

    } else {
      return null
    }
    setPage(1)
    setStartDate(startValue)
    resetLocation()
  }

  const handleEndChanges = (endValue: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const endDateFormatted = endValue ? format(endValue, 'yyyy-MM-dd') : ''
    setEndDate(endValue)
    setPage(1)
    resetLocation()
  }


  return (
    <div className={styles.container}>
      <div className={styles.title_container}>
        <PageSubTitle>
          <img src={ActivityIcon} />
          Notas
        </PageSubTitle>
        <p className={styles.text}>
          Acompanhe todas as suas notas em atividades anteriores. Monitore seu progresso e melhore seu desempenho!
        </p>
      </div>
      <div className={styles.slider_container}>
        <GradesMobileSlider />
      </div>
      <div className={styles.cards_container}>
        <PageSubTitle>
          Minhas notas
        </PageSubTitle>
        {(rows.length > 0) &&
          <div className={styles.filters}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <Typography>Banca:</Typography>
              <SelectInput
                width='fit-content'
                name='selectSubject'
                border='none'
                onChange={() => null}
                defaultValue='placeholder'
                options={bancaOptions}
              />
            </Box>
            <Box
              sx={{
                display: 'flex'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px'
                }}
              >
                <Box>
                  <Typography
                    color={theme.palette.text.primary}
                    variant='subtitle2'
                    component='p'
                  >
                    Iniciam entre:
                  </Typography>
                </Box>
                <Box>
                  <DatePickerField
                    name='start_date'
                    onChange={handleStartChanges}
                    startDate={startDate}
                    endDate={endDate}
                    placeholder='dd/mm/aaaa'
                    selectsStart
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex ',
                  alignItems: 'center',
                  gap: '8px',
                  marginLeft: '8px'
                }}
              >
                <Box>
                  <DatePickerField
                    name='end_date'
                    onChange={handleEndChanges}
                    startDate={endDate}
                    endDate={endDate}
                    minDate={startDate}
                    placeholder='dd/mm/aaaa'
                  />
                </Box>
              </Box>
            </Box>
          </div>
        }
        <div className={styles.cards}>
          {
            rows.map((item: any) => {
              return (
                <GradesInfoCard
                  key={item.id}
                  title={item.titulo}
                  time={item.time}
                  score={item.nota}
                  correctionDate={item.correcao}
                  banca={item.banca}
                  rewritten={item.rewritten}
                />
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

export default GradesMobileContainer