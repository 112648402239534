import React from 'react'

// Assets
import EssaySentImage from '../../assets/activities/essay-sent-image.png'

// Components
import DialogModal from './DialogModal'
import { Box, CircularProgress, styled, Typography, useMediaQuery } from '@mui/material'
import { PageSubTitle } from '../title/PageSubTitle'

// Redux
import { useAppSelector } from '../../store/hooks'

// Styles
import { useTheme } from '@mui/material/styles'

const Image = styled('img')(({ theme }) => ({
  marginBottom: '40px',
  [theme.breakpoints.down(905)]: {
    maxWidth: '370px'
  }
}))

type IEssaySubmittedConfirmationModalProps = {
  hasCloseButton: boolean
  open: boolean
  handleClose: () => void
  closeOnBackdropClick: boolean
}

const EssaySubmittedConfirmationModal: React.FC<IEssaySubmittedConfirmationModalProps> = ({
  hasCloseButton,
  open,
  handleClose,
  closeOnBackdropClick
}) => {
  const theme = useTheme()

  const { isSaving } = useAppSelector(state => state.activity)
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <DialogModal
      hasCloseButton={hasCloseButton}
      open={open}
      handleClose={handleClose}
      closeOnBackdropClick={closeOnBackdropClick}
      width='566px'
      height='546px'
    >
      {isSaving ? (
        <Box
          alignItems='center'
          display='flex'
          flexDirection='column'
          gap='24px'
          justifyContent='center'
          width='100%'
        >
          <Typography sx={{ fontSize: '24px', fontWeight: 500 }}>Enviando...</Typography>
          <CircularProgress />
        </Box>
      ) : <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          padding: smDown ? '0px 12px' : '33px 70px 56px 70px'
        }}
      >
        <Image src={EssaySentImage} />
        <PageSubTitle mb='12px'>Redação enviada</PageSubTitle>
        <Typography
          sx={{
            textAlign: 'center',
            maxWidth: '422px',
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '150%'
          }}
        >
          Aguarde a correção do seu texto pela equipe de corretores especializados.
        </Typography>
      </Box>}
    </DialogModal>
  )
}

export default EssaySubmittedConfirmationModal