
import React from 'react'


const GuidedWritingMobileContainer = () => {
  return (
    <div>GuidedWritingMobileContainer</div>
  )
}

export default GuidedWritingMobileContainer