import React from 'react'

// Components
import { Avatar, Box, Button, Typography } from '@mui/material'
import ArrowRight from '../../assets/card/arrow-right.svg'

// Styles
import styles from './styles/studentInfoSuccessPanelCard.module.scss'
import { stringAvatar } from '../../utils/functions'

type IStudentInfoSuccessPanelCardProps = {
  name: string
  classroom: string
  score: number
  index: number
  photo: any
}

const StudentInfoSuccessPanelCard: React.FC<IStudentInfoSuccessPanelCardProps> = ({
  name,
  classroom,
  score,
  index,
  photo
}) => {

  const isTopThree = index <= 2


  return (
    <Box
      className={styles.container}
      sx={{
        background: isTopThree ? '#F4FBE6' : '#F2F5FF',
        position: 'relative'
      }}
    >
      <Typography
        sx={{
          display: 'flex',
          lineHeight: '120%',
          position: 'absolute',
          right: -10,
          top: -8,
          height: '32px',
          width: '32px',
          fontSize: '14px',
          fontWeight: 500,
          padding: '8px 12px',
          borderRadius: '50%',
          background: isTopThree ? '#7EEB89' : '#8842FE',
          color: isTopThree ? '#434343' : '#fff'
        }}
      >
        {index + 1}
      </Typography>
      <Box className={styles.card_text_container}>
        <Box className={styles.photo_container}>
          <Avatar
            alt={name}
            variant='circular'
            src={photo}
            {...stringAvatar(name, 40, 40)}
          />
          <p className={styles.name}>{name}</p>
        </Box>
        <Button>
          <img src={ArrowRight} />
        </Button>
      </Box>
      <Box className={styles.card_text_container}>
        <p className={styles.classroom}>
          {classroom}
        </p>
        <Box className={styles.bottom_container}>
          <Typography className={styles.score}
            sx={{
              alignItems: 'center',
              display: 'flex',
              gap: '8px'
            }}
          >
            Nota:
            <Typography
              className={styles.score_value}
              sx={{
                background: isTopThree ? '#7EEB89' : '#434343',
                color: isTopThree ? '#434343' : '#fff'
              }}
            >
              {score}
            </Typography>
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default StudentInfoSuccessPanelCard