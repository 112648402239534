import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

// Components
import NonLoggedMobileContainer from '../containers/nonLogged/NonLoggedMobileContainer'
import NonLoggedDesktopContainer from '../containers/nonLogged/NonLoggedDesktopContainer'

// Utils
import { ssoUrl, AppUrl } from '../utils/constants'
import { encodeQueryData } from '../utils/functions'

// Redux
import { useAppSelector } from '../store/hooks'

// Styles
import { useTheme } from '@mui/material/styles'
import { useMediaQuery } from '@mui/system'

const NonLoggedPage = () => {

  useEffect(() => {
    document.title = "Login | Verbo"
  }, [])

  const {
    REACT_APP_SERVER_ENV = 'local',
    REACT_APP_API_ENV = REACT_APP_SERVER_ENV,
    REACT_APP_SSO_ENV = REACT_APP_SERVER_ENV,
    REACT_APP_PROJECT_ID,
    REACT_APP_URL_STAGING,
    REACT_APP_PROJECT_LOGO_FULL_PURPLE
  } = process.env

  const LogoFullPurple = REACT_APP_PROJECT_LOGO_FULL_PURPLE

  const urlRedirect = `${REACT_APP_URL_STAGING || AppUrl[REACT_APP_API_ENV]}/login`

  const data = {
    app_id: REACT_APP_PROJECT_ID,
    url_redirect: urlRedirect
  }

  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const urlString = encodeQueryData(data)
  const url = `${ssoUrl[REACT_APP_SSO_ENV]}?${urlString}`
  const navigate = useNavigate()

  const { isLogged } = useAppSelector(state => state.authVerbo)

  useEffect(() => {
    if (isLogged) {
      navigate('/')
    }
  }, [])

  return (
    smDown
      ? <NonLoggedMobileContainer
        LogoFull={LogoFullPurple}
        url={url}
      />
      :
      <NonLoggedDesktopContainer
        LogoFull={LogoFullPurple}
        url={url}
      />
  )
}

export default NonLoggedPage