import React, { useEffect } from 'react'
import { useMediaQuery } from '@mui/system'
import { useTheme } from '@mui/material/styles'
import AddNewThemeMobileContainer from '../containers/addNewTheme/AddNewThemeMobileContainer'
import AddNewThemeDesktopContainer from '../containers/addNewTheme/AddNewThemeDesktopContainer'

const AddNewTheme = () => {
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  useEffect(() => {
    document.title = "Adicionar tema | Verbo"
  }, [])
  return (
    smDown ? <AddNewThemeMobileContainer /> : <AddNewThemeDesktopContainer />
  )
}

export default AddNewTheme