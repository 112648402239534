import React, { useEffect } from 'react'
import ActivityPendingEssayDesktopContainer from '../containers/activities/activityPendingEssay/ActivityPendingEssayDesktopContainer'
import ActivityPendingEssayMobileContainer from '../containers/activities/activityPendingEssay/ActivityPendingEssayMobileContainer'
import { useMediaQuery } from '@mui/system'
import { useTheme } from '@mui/material/styles'
const ActivityPendingEssayPage = () => {
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    document.title = "Minhas atividades | Verbo"
    
  }, [])

  return (
    
    smDown ? <ActivityPendingEssayMobileContainer/> : <ActivityPendingEssayDesktopContainer/>
    
  )
}

export default ActivityPendingEssayPage