import React, { useState } from 'react'

// Assets
import { ReactComponent as HomeIcon } from '../../assets/home/home-icon.svg'

// Components
import { Box, useMediaQuery } from '@mui/material'
import { PageTitle } from '../../components/title/PageTitle'
import CalendarWithEvents from '../../components/calendar/CalendarWithEvents'
import TextsProductionCard from '../../components/card/TextsProductionCard'
import { PageSubTitle } from '../../components/title/PageSubTitle'
import TextAverageScoreCard from '../../components/card/TextAverageScoreCard'
import PersonalDataDropDownMenu from '../../components/dropDown/PersonalDataDropDownMenu'
import ChartContainer from '../../components/charts/ChartContainer'
import Notification from '../../components/notification/Notification'
import LogoutButton from '../../components/button/logout/LogoutButton'
import SuccessPanel from '../../components/card/SuccessPanel'
import SuccessPanelV2 from '../../components/card/SuccessPanelV2'
import CompetenceContainerV2 from './competence/CompetenceContainerV2'
import CustomSearchInput from '../../components/input/CustomSearchInput'
import AverageScoreTabletCard from '../../components/card/AverageScoreTabletCard'
import CompetenceContainer from './competence/CompetenceContainer'

// Styles
import { useTheme } from '@mui/material/styles'
import styles from './styles/homeDesktop.module.scss'

// Redux
import { useAppSelector } from '../../store/hooks'

const HomeDesktopContainer = () => {

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const theme = useTheme()
  const [searchName, setSearchName] = useState<string>('')
  const isSm = useMediaQuery('(max-width:1239px)')

  const { statistics, isFetching } = useAppSelector((state) => state.homeStatistics)

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setSearchName(value)
  }

  return (
    <div className={styles.container}>
      <div className={styles.colOne}>
        <div className={styles.colOne_tablet}>
          <div className={styles.titleSearchContainer}>
            <PageTitle>
              <HomeIcon /> Homepage
            </PageTitle>
            <CustomSearchInput
              name='search'
              value={searchName}
              onChange={handleSearch}
            />
          </div>
          <div className={styles.cards}>
            {isSm ? <AverageScoreTabletCard average={statistics.average_grade} isFetching={isFetching} /> : <TextAverageScoreCard average={statistics.average_grade} isFetching={isFetching} />}
            <Box width='100%'>
              <PageSubTitle>Produções textuais</PageSubTitle>
              <div className={styles.miniCards}>
                <Box className={styles.dualCardContainer}>
                  <TextsProductionCard status={'sent'} count={statistics.count_submitted} />
                  <TextsProductionCard status={'corrected'} count={statistics.count_corrected} />
                </Box>
                <Box className={styles.dualCardContainer}>
                  <TextsProductionCard status={'in_correction'} count={statistics.count_in_correction} />
                  <TextsProductionCard status={'pending'} count={statistics.count_pending} />
                </Box>
              </div>
            </Box>
          </div>
          <ChartContainer />
          {isSm ? <CompetenceContainerV2 /> : <CompetenceContainer />}

        </div>
      </div>
      <div className={styles.colTwo}>
        <div className={styles.colTwo_tablet}>
          <div className={styles.notification_container}>
            <div className={styles.icons_container}>
              <Notification background='#fff' />
              <LogoutButton background='#fff' />
            </div>
            <PersonalDataDropDownMenu background='#fff' />
          </div>
          <CalendarWithEvents />
          {
            isSm ? <SuccessPanelV2 /> : <SuccessPanel />
          }

        </div>
      </div>

    </div>
  )
}

export default HomeDesktopContainer