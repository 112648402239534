import React from 'react'
import { Box } from '@mui/material'
import { useTheme } from '@mui/material/styles'

type IActivityCounterProps = {
  count: number
}

const ActivityCounter: React.FC<IActivityCounterProps> = ({ count }) => {

  const theme = useTheme()

  const counter = (amount: number) => {
    if (amount > 0) {
      return '+' + count
    } else {
      return count
    }
  }
  return (
    <Box
      sx={{
        background: '#C9FF57',
        borderRadius: '8px',
        color: theme.palette.primary.main,
        fontSize: '10px',
        fontWeight: 600,
        lineHeight: '120%',
        maxHeight: '18px',
        padding: '4px 7px',
        width: 'fit-content'
      }}
    >
      {counter(count)}
    </Box>
  )
}

export default ActivityCounter