import React from 'react'
import { Box, Typography } from '@mui/material'
import CalendarIcon from '../../assets/activities/calendar-icon.svg'

type IRoundedDateWithIconProps = {
  date: string
  isEndDate?: boolean
}

const RoundedDateWithIcon:React.FC<IRoundedDateWithIconProps> = ({date, isEndDate}) => {
  return (
    <Box
    sx={{
      alignItems: 'center',
      background: isEndDate ? '#FFDDE3' : '#F2F5FF',
      borderRadius: '56px',
      display: 'flex',
      gap: '4px',
      padding: '11px 15px',
    }}
    >
      <img src={CalendarIcon} />
      <Typography>{date}</Typography>
    </Box>
  )
}

export default RoundedDateWithIcon