import React from 'react'
import { Box, Divider } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import styles from './styles/gradeCard.module.scss'
type IGradeCardProps = {
  data: any
}

const GradeCard: React.FC<IGradeCardProps> = ({ data }) => {

  const theme = useTheme()

  const getBackgroundColor = (score: number) => {
    let background
    let fontColor
    if (score >= 900) {
      background = '#7EEB89'
    } else if (score >= 700 && score < 900) {
      background = theme.palette.text.primary
      fontColor = '#fff'
    } else if (score >= 400 && score < 700) {
      background = '#F6BB8C'
    } else if (score < 400) {
      background = theme.palette.error.main
    }
    return { background, fontColor }
  }

  const getRandomNumber = () => {
    return Math.floor(Math.random() * 1001);
  }

  return (
    <Box
      bgcolor={getBackgroundColor(data.nota)}
      className={styles.card}
    >
      <h2
        style={{
          color: getBackgroundColor(data.nota ?? getRandomNumber()).fontColor
        }}
        className={styles.banca}
      >
        {data.banca ?? '--'}
      </h2>
      <Divider
        sx={{
          borderColor: getBackgroundColor(data.nota ?? getRandomNumber).fontColor + '!important'
        }}
        className={styles.divider}
      />
      <p
        style={{
          color: getBackgroundColor(data.nota ?? getRandomNumber()).fontColor
        }}
        className={styles.label}>
        Nota média
      </p>
      <p
        style={{
          color: getBackgroundColor(data.nota ?? getRandomNumber()).fontColor
        }}
        className={styles.grade}>
        {data.nota ?? '000'}
      </p>
    </Box>
  )
}

export default GradeCard