import React from 'react'

// Assets
import ClockIcon from '../../assets/activities/white-clock-icon.svg'
import EssayIcon from '../../assets/activities/essay-icon.svg'

import QuestionMarkIcon from '../../assets/activities/question-mark-circled-icon.svg'
import LargeEssayShape from '../../assets/activities/larger-essay-shape-top-right-.svg'
import SmallEssayShape from '../../assets/activities/small-essay-shape-top-right-.svg'

// Components
import { Box, Tooltip, Typography } from '@mui/material'
import { PageSubTitle } from '../title/PageSubTitle'

// Style
import { useTheme } from '@mui/material/styles'
import EssayLabel from '../label/EssayLabel'

type IEssayCardProps = {
  essay: string
  timeSpent?: string
  isSecondSubmission?: boolean
}

const EssayCard: React.FC<IEssayCardProps> = ({
  essay,
  timeSpent,
  isSecondSubmission
}) => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        background: '#F2F5FF',
        borderRadius: '24px',
        height: 'fit-content',
        position: 'relative',
        padding: '28px 16px 40px 16px',
        width: '100%',
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          gap: '8px',
          position: 'absolute',
          top: 0,
          right: 0,
          backgroundImage: timeSpent ? `url(${LargeEssayShape})` : `url(${SmallEssayShape})`,
          width: timeSpent ? '285px' : '160px',
          height: '68px'

        }}
      >
        <Box 
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          position: 'absolute',
          right: '8px',
          top: 0
        }}
        >
          {timeSpent && <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              background: theme.palette.text.primary,
              padding: '6px 15px',
              borderRadius: '56px'
            }}
          >
            <img src={ClockIcon} />
            <Typography color='#fff' fontSize='14px' fontWeight={500}>{timeSpent}</Typography>
          </Box>}
          <Typography
            sx={{
              color: '#fff',
              fontWeight: 500,
              fontSize: '14px',
              background: isSecondSubmission ? '#EF7B8E' : theme.palette.text.primary,
              padding: '6px 15px',
              borderRadius: '56px'
            }}
          >
            {isSecondSubmission ? '2º Envio' : '1º Envio'}
          </Typography>
          <Box ml='4px'>
            <Tooltip title='Ajuda'>
              <img src={QuestionMarkIcon} />
            </Tooltip>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          gap: '8px'
        }}
      >
        <img src={EssayIcon} />
        <PageSubTitle>Redação</PageSubTitle>
      </Box>

      <Box
        sx={{
          mt: '28px',
          '& > img': {
            width: '100%'
          }
        }}
      >
        <img src={essay} />
      </Box>
      <EssayLabel/>
    </Box>
  )
}

export default EssayCard